import {call, put, takeEvery} from 'redux-saga/effects';
import {UPDATE_PHONE_TYPES} from '../../constants/types';

import {updatePhoneSuccess, updatePhoneError} from '../../actions/phone';
import { authHeader } from '../../helpers/authHeader';

export function* updatePhone(action) {
  console.log(action)
  const param = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify(action.params.phone)
  };
  try{
    const response = yield call(fetch, `${process.env.REACT_APP_API_URL}/user/phone/${action.params.id}`, param);
    const data = yield call(response.json.bind(response));
    if (response.status === 200) {

      yield put(updatePhoneSuccess(data));
    } else if (response.status === 400) {
      yield put(updatePhoneError(data));
    } else if (response.status === 401){
      yield put(updatePhoneError(data));
    } else if (response.status === 500){
      yield put(updatePhoneError(data));
    }
  } catch (error) {
    const data = {code: 500, userMessage: 'Internal Server Error'};
    yield put(updatePhoneError(data));
  }
}

export function* watchUpdatePhoneRequest() {

  yield takeEvery(UPDATE_PHONE_TYPES.REQUEST, updatePhone);
}
