import nanoid from 'nanoid';
import moment from 'moment';
moment.locale('ru');

const size = 5;

export const dateFormatSort = (xs) => moment(xs).format("YYYYMMDD");

function randomMounth(start, end) {
    let date = new Date(+start + Math.random() * (end - start));
    return dateFormatSort(date);
}

function getRandomArbitary(min, max)
{
    return Math.random() * (max - min) + min;
}

//Math.random() * 50000,

const newFinancialReport = () => {
    return {
        id: nanoid(size),
        month: randomMounth(new Date(2015, 0, 1), new Date()),
        consumption: getRandomArbitary(0, 20000).toFixed(2),
        coming: getRandomArbitary(0, 70000).toFixed(2),
        accountsReceivable: getRandomArbitary(0, 10000).toFixed(2),
        turnover: getRandomArbitary(0, 50000).toFixed(2),
    }
};

const financialReportData = () => {
    const arr = [];
    for(let i = 0; i <= 30; i++){
        arr.push(newFinancialReport());
    }
    return arr;
};

export { financialReportData }