import {call, put, takeEvery} from 'redux-saga/effects';
import {FETCH_PAYOUTS_TYPES} from 'constants/types';

import {fetchPayoutsResult, fetchPayoutsError} from 'actions/payout';
import { authHeader } from '../../helpers/authHeader';
import { dateIsNull } from '../../helpers/helpers';
import moment from 'moment';
moment.locale('ru');

function isMultiSort(sort) {
    if(sort){
        const {sortName, sortOrder} = sort;
        let sortied = sortName.map((name,i) => {
            return {
                "property": name,
                "order": sortOrder[i]
            }
        });
        return sortied;
    }
}

function isFilter(filter){
    if(filter){
        const { startDate, endDate, status, type } = filter;
        const result = {
            status,
            type,
            date: startDate !== null && endDate !== null ? [dateIsNull(startDate), dateIsNull(endDate)] : undefined
        }
        return result;
    }
}

export function* getPayoutsList(action) {
    const param = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({
            pagination: action.params.pagination,
            sort: isMultiSort(action.params.sort),
            filter: isFilter(action.params.filter),
            //fields: action.params.fields
        })
    };
    try{
        const response = yield call(fetch, `${process.env.REACT_APP_API_URL}/finance/expense/list`, param);
        const data = yield call(response.json.bind(response));
        if (response.status === 200) {
            yield put(fetchPayoutsResult(data));
        } else {
            yield put(fetchPayoutsError(data));
        }
    } catch (error) {
        const data = {code: 500, userMessage: 'Internal Server Error'};
        yield put(fetchPayoutsError(data));
    }
}

export function* watchLoadPayoutsRequest() {

    yield takeEvery(FETCH_PAYOUTS_TYPES.REQUEST, getPayoutsList);
}
