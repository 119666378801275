import nanoid from 'nanoid';
import moment from 'moment';
moment.locale('ru');

const size = 5;

const newDeposit = () => {
  const statusChance = Math.random();
  return {
    id: nanoid(size),
    client: statusChance > 0.66
        ? 'Инстрленд'
        : statusChance > 0.33
            ? 'OldLuis'
            : 'LaowaiCareer.com',
    amount: Math.random() * 50000,
    date: moment(),
    account:  statusChance > 0.66
        ? 'Мирошников'
        : statusChance > 0.33
            ? 'ООО "Цифровая палочка"'
            : 'Мкртчян (рубли)',
  }
};

const depositData = () => {
  const arr = [];
  for(let i = 0; i <= 30; i++){
    arr.push(newDeposit());
  }
  return arr;
};

export { depositData }
