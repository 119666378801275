import {activeColumns, hiddenColumns} from './LeadSourceTableData';

const initialValue = {
    activeColumns: activeColumns,
    hiddenColumns: hiddenColumns
};

const settingLeadSource = (state=initialValue, action) => {
    switch (action.type) {
        case 'CHANGE_COLUMNS_LEAD_SOURCE':
            return { ...state, activeColumns: action.activeColumns, hiddenColumns: action.hiddenColumns};
        case 'CHANGE_COLUMN_ACTIVE_LEAD_SOURCE':
            return { ...state, activeColumns: action.activeColumns};
        case 'CHANGE_COLUMN_HIDDEN_LEAD_SOURCE':
            return { ...state, hiddenColumns: action.hiddenColumns};
        default:
            return state;
    }
}
export default settingLeadSource;
