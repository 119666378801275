import {call, put, takeEvery} from 'redux-saga/effects';
import {ADD_PHONE_TYPES} from 'constants/types';

import {addPhoneSuccess, addPhoneError} from 'actions/phone';
import { authHeader } from '../../helpers/authHeader';

export function* addPhone(action) {
  const param = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(action.params)
  };
  try{
    const response = yield call(fetch, `${process.env.REACT_APP_API_URL}/user/phone`, param);
    const data = yield call(response.json.bind(response));
    if (response.status === 200) {
      yield put(addPhoneSuccess(data));
    } else if (response.status === 400) {
      yield put(addPhoneError(data));
    } else if (response.status === 401){
      yield put(addPhoneError(data));
    } else if (response.status === 404){
      yield (addPhoneError(data));
    }
  } catch (error) {
    const data = {code: 500, userMessage: 'Internal Server Error'};
    yield put(addPhoneError(data));
  }
}

export function* watchAddPhoneRequest() {
  yield takeEvery(ADD_PHONE_TYPES.REQUEST, addPhone);
}

