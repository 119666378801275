import {call, put, takeEvery} from 'redux-saga/effects';
import {UPDATE_SPECIALIZATION_TYPES} from '../../constants/types';

import {updateSpecializationSuccess, updateSpecializationError} from '../../actions/specialization';
import { authHeader } from '../../helpers/authHeader';

export function* updateSpecialization(action) {
    const param = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(action.params.specialization)
    };
    try{
        const response = yield call(fetch, `${process.env.REACT_APP_API_URL}/user/specialization/${action.params.id}`, param);
        const data = yield call(response.json.bind(response));
        if (response.status === 200) {
            yield put(updateSpecializationSuccess(data));
        } else if (response.status === 400) {
            yield put(updateSpecializationError(data));
        } else if (response.status === 401){
            yield put(updateSpecializationError(data));
        } else if (response.status === 500){
            yield put(updateSpecializationError(data));
        }
    } catch (error) {
        const data = {code: 500, userMessage: 'Internal Server Error'};
        yield put(updateSpecializationError(data));
    }
}

export function* watchUpdateSpecializationRequest() {

    yield takeEvery(UPDATE_SPECIALIZATION_TYPES.REQUEST, updateSpecialization);
}
