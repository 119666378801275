import {
    FETCH_WELCOME_LETTER_TYPES,
    SEND_WELCOME_LETTER_TYPES,
    UPDATE_WELCOME_LETTER_TYPES
} from '../../constants/welcomеletter/welcomeletter';

const initialState = {
    letter: null,
    letterSendAt: null,
    userId: null,
    loading: null,
    error: null
};

const welcomeletter = (state=initialState, action) => {
    switch (action.type) {
        case FETCH_WELCOME_LETTER_TYPES.REQUEST:
            return {
                ...state,
                loading: true
            };
        case FETCH_WELCOME_LETTER_TYPES.RESULT:
            return {
                ...state,
                loading: false,
                letter: action.data
            };
        case FETCH_WELCOME_LETTER_TYPES.ERROR:
            return  {
                ...state,
                loading: false,
                error: action.error
            };
        case SEND_WELCOME_LETTER_TYPES.REQUEST:
            return {
                ...state,
                loading: true
            };
        case SEND_WELCOME_LETTER_TYPES.SUCCESS:
            return {
                ...state,
                loading: false,
                letterSendAt: action.data.time,
                userId: action.data.userId
            };
        case SEND_WELCOME_LETTER_TYPES.ERROR:
            return  {
                ...state,
                loading: false,
                error: action.error
            };
        case UPDATE_WELCOME_LETTER_TYPES.REQUEST:
            return {
                ...state,
                error: null,
                loading: false
            };
        case UPDATE_WELCOME_LETTER_TYPES.SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                letter: action.data,
            };
        case UPDATE_WELCOME_LETTER_TYPES.ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        default:
            return state;
    }
}
export default welcomeletter;
