import {FETCH_ACCOUNTS_TYPES} from '../constants/types';

export function fetchAccountsRequest(params){
    return {
        type: FETCH_ACCOUNTS_TYPES.REQUEST,
        params
    }
}

export function fetchAccountsResult(data){
    return {
        type: FETCH_ACCOUNTS_TYPES.RESULT,
        data
    }
}

export function fetchAccountsError(error) {
    return{
        type: FETCH_ACCOUNTS_TYPES.ERROR,
        error
    }
}