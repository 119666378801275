import {FETCH_PAYSHEET_TYPES} from '../constants/types';

const initialState = {
    paysheet: null,
    loading: null,
    error: null
};

const paysheet = (state=initialState, action) => {
    switch (action.type) {
        case FETCH_PAYSHEET_TYPES.REQUEST:
            return {
                ...state,
                loading: true
            };
        case FETCH_PAYSHEET_TYPES.RESULT:
            return {
                ...state,
                loading: false,
                paysheet: action.data
            };
        case FETCH_PAYSHEET_TYPES.ERROR:
            return {
                ...state,
                error: action.error
            };
        default:
            return state;
    }
};
export default paysheet;