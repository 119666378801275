import { applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';

import sessionMiddleware from './sessionMiddleware';

export const sagaMiddleware = createSagaMiddleware();

const middlewares = applyMiddleware(
    sagaMiddleware,
    sessionMiddleware,
);

export default middlewares;
