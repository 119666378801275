export const activeColumns = [
    {
        id: 1,
        dataField: "name",
        title: "Название отдела"
    },
    {
        id: 2,
        dataField: "active",
        title: "Активен"
    },
    {
        id: 3,
        dataField: "internalResources",
        title: "Внутренние ресурсы"
    },
    {
        id: 4,
        dataField: "productionDepartment",
        title: "Производственный отдел"
    },
    {
        id: 5,
        dataField: "manual",
        title: "Руководство"
    },
    {
        id: 6,
        dataField: "isCommon",
        title: "Общие ресурсы"
    },
];
export const hiddenColumns = [
    {
        id: 7,
        dataField: "id",
        title: "ID"
    }
];
