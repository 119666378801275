export const activeColumns = [
  {
    id: 1,
    dataField: "fromAccount",
    title: "Со счета"
  },
  {
    id: 2,
    dataField: "toAccount",
    title: "На счет"
  },
  {
    id: 3,
    dataField: "writeOffAmount",
    title: "Сумма списания"
  },
  {
    id: 4,
    dataField: "accrualAmount",
    title: "Сумма начисления"
  },
  {
    id: 5,
    dataField: "comment",
    title: "Комментарий"
  },
  {
    id: 6,
    dataField: "date",
    title: "Дата"
  }
];
export const hiddenColumns = [
  {
    id: 7,
    dataField: "id",
    title: "ID"
  }
];
