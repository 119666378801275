const COLUMNS = [
    {
        id: 1,
        dataField: "name",
        title: "Название события"
    },
    {
        id: 2,
        dataField: "type",
        title: "Тип события"
    },
    {
        id: 3,
        dataField: "start",
        title: "Начало события"
    },
    {
        id: 4,
        dataField: "end",
        title: "Окончание события"
    },
    {
        id: 5,
        dataField: "user",
        title: "Пользователь"
    },
    {
        id: 6,
        dataField: "status",
        title: "Статус"
    },
    {
        id: 7,
        dataField: "actions",
        title: "Действия"
    },
    {
        id: 8,
        dataField: "id",
        title: "ID"
    }
];

const DEFAULT_ACTIVE = ['name', 'type', 'start', 'end', 'user', 'status', 'actions'];

const STORAGE_VARIABLE = 'EVENT_MANAGEMENT_TABLE_SETTINGS';

const formatColumns = (columns, active) => {
    const result = active.reduce((result, dataField) => {
        const column = columns.find(c => c.dataField === dataField);
        return column ? { ...result, activeColumns: [...result.activeColumns, column] } : result;
    }, {
        activeColumns: [],
        hiddenColumns: [],
    });

    result.hiddenColumns = columns.filter(c => active.indexOf(c.dataField) === -1);

    return result;
};

const getStorageSettings = (columns, defaultActive) => {
    try {
        const storedActive = JSON.parse(window.localStorage.getItem(STORAGE_VARIABLE));

        if (!Array.isArray(storedActive) || storedActive.length === 0 || storedActive.findIndex(k => typeof k !== 'string') !== -1) {
            throw storedActive;
        }
        return formatColumns(columns, storedActive);
    } catch (e) {
        return formatColumns(columns, defaultActive);
    }
};

export const setStorageActive = active => {
    try {
        if (!Array.isArray(active) || active.findIndex(k => typeof k !== 'string') !== -1) {
            throw active;
        }

        window.localStorage.setItem(STORAGE_VARIABLE, JSON.stringify(active));

        return true;
    } catch (e) {
        return false;
    }
};

export const setStorageHidden = hidden => {
    try {
        if (!Array.isArray(hidden) || hidden.findIndex(k => typeof k !== 'string') !== -1) {
            throw hidden;
        }

        window.localStorage.setItem(
            STORAGE_VARIABLE,
            JSON.stringify(COLUMNS)
                .filter(({ dataField }) => hidden.indexOf(dataField) === -1)
                .map(({ dataField }) => dataField),
        );

        return true;
    } catch (e) {
        return false;
    }
};

export const {
    activeColumns,
    hiddenColumns,
} = getStorageSettings(COLUMNS, DEFAULT_ACTIVE);
