import {call, put, takeEvery} from 'redux-saga/effects';
import {FETCH_ROLE_TYPES} from 'constants/types';
import { authHeader } from '../../helpers/authHeader';

import {fetchRoleResult, fetchRoleError} from '../../actions/role';

export function* getRoleList(action) {
  const param = {
    method: 'GET',
    headers: authHeader(),
  };

  try{
    const response = yield call(fetch, `${process.env.REACT_APP_API_URL}/security/role/list`, param);
    const data = yield call(response.json.bind(response));
    if (response.status === 200) {
      yield put(fetchRoleResult(data));
    } else if (response.status === 400) {
      yield put(fetchRoleError(data));
    } else if(response.status === 401) {
      yield put(fetchRoleError(data));
    } else if(response.status === 404){
      yield put(fetchRoleError(data));
    }
  } catch (error) {
    const data = {code: 500, userMessage: 'Internal Server Error'};
    yield put(fetchRoleError(data));
  }
}

export function* watchLoadRoleRequest() {

  yield takeEvery(FETCH_ROLE_TYPES.REQUEST, getRoleList);
}
