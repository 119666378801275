import {call, put, takeEvery} from 'redux-saga/effects';
import {UPDATE_INCOME_TYPES} from '../../constants/types';

import {updateIncomeSuccess, updateIncomeError} from '../../actions/income';
import { authHeader } from '../../helpers/authHeader';

export function* updateIncome(action) {
    console.log(action)
    const param = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(action.params.income)
    };
    try{
        const response = yield call(fetch, `${process.env.REACT_APP_API_URL}/finance/income/${action.params.id}`, param);
        const data = yield call(response.json.bind(response));
        if (response.status === 200) {
            //console.log("update DATA", data);
            yield put(updateIncomeSuccess(data));
        } else {
            yield put(updateIncomeError(data));
        }
    } catch (error) {
        const data = {code: 500, userMessage: 'Internal Server Error'};
        yield put(updateIncomeError(data));
    }
}

export function* watchUpdateIncomeRequest() {
    yield takeEvery(UPDATE_INCOME_TYPES.REQUEST, updateIncome);
}
