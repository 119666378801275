import { FETCH_PROFILE_TYPES } from '../constants/types';

export function fetchProfileRequest(params){
    return {
        type: FETCH_PROFILE_TYPES.REQUEST,
        params
    }
}

export function fetchProfileResult(data){
    return {
        type: FETCH_PROFILE_TYPES.RESULT,
        data
    }
}

export function fetchProfileError(error) {
    return{
        type: FETCH_PROFILE_TYPES.ERROR,
        error
    }
}