import store from 'store';

export function authHeader() {
    // return authorization header with jwt token
    const state = store.getState();
    const user = state && state.auth && state.auth.user;
    //console.log("user.token", user.token)
    if (user && user.token) {
        return { 'Authorization': 'Bearer ' + user.token };
    } else {
        return {};
    }
}
