import {
    FETCH_CALENDAR_TYPES,
    ADD_CALENDAR_TYPES,
    UPDATE_CALENDAR_TYPES,
    DELETE_CALENDAR_TYPES,
    VIEW_CALENDAR_TYPES,
} from '../constants/types';

const initialState = {
    eventList: [],
    error: null,
    loading: null,
    pagination: {},
}

function calendar(state = initialState, action) {
    switch (action.type) {
        case VIEW_CALENDAR_TYPES.REQUEST:
        case FETCH_CALENDAR_TYPES.REQUEST:
        case ADD_CALENDAR_TYPES.REQUEST:
        case UPDATE_CALENDAR_TYPES.REQUEST:
        case DELETE_CALENDAR_TYPES.REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case FETCH_CALENDAR_TYPES.SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                eventList: action.data.list,
                pagination: action.data.pagination,
            };
        case VIEW_CALENDAR_TYPES.SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                eventList: [action.data],
            };
        case UPDATE_CALENDAR_TYPES.SUCCESS:
            return {
                ...state,
                eventList: state.eventList.find(event => event.id === action.data.id)
                    ? state.eventList.map(event => event.id === action.data.id ? action.data : event)
                    : [...state.eventList, action.data],
                loading: false,
                error: null
            };
        case ADD_CALENDAR_TYPES.SUCCESS:
            return {
                ...state,
                eventList: [...state.eventList, action.data],
                loading: false,
                error: null
            };
        case DELETE_CALENDAR_TYPES.SUCCESS:
            return {
                ...state,
                eventList: state.eventList.filter(event => event.id !== action.data.id),
                loading: false,
                error: null
            };
        case VIEW_CALENDAR_TYPES.ERROR:
        case FETCH_CALENDAR_TYPES.ERROR:
        case ADD_CALENDAR_TYPES.ERROR:
        case UPDATE_CALENDAR_TYPES.ERROR:
        case DELETE_CALENDAR_TYPES.ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        default:
            return state;
    }
}

export default calendar;