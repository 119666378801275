import {
    FETCH_MASQUERADE_AUTH_TYPES,
    FETCH_MASQUERADE_ROLLBACK_TYPES,
    FETCH_MASQUERADE_STATUS_TYPES
} from '../../constants/masquerade/masquerade';

export function fetchMasqueradeAuthRequest(params){
    return {
        type: FETCH_MASQUERADE_AUTH_TYPES.REQUEST,
        params
    }
}

export function fetchMasqueradeAuthSuccess(data){
    return {
        type: FETCH_MASQUERADE_AUTH_TYPES.SUCCESS,
        data
    }
}

export function fetchMasqueradeAuthError(error) {
    return{
        type: FETCH_MASQUERADE_AUTH_TYPES.ERROR,
        error
    }
}

export function fetchMasqueradeRollbackRequest(params){
    return {
        type: FETCH_MASQUERADE_ROLLBACK_TYPES.REQUEST,
        params
    }
}

export function fetchMasqueradeRollbackSuccess(data){
    return {
        type: FETCH_MASQUERADE_ROLLBACK_TYPES.SUCCESS,
        data
    }
}

export function fetchMasqueradeRollbackError(error) {
    return{
        type: FETCH_MASQUERADE_ROLLBACK_TYPES.ERROR,
        error
    }
}

export function fetchMasqueradeStatusRequest(params){
    return {
        type: FETCH_MASQUERADE_STATUS_TYPES.REQUEST,
        params
    }
}

export function fetchMasqueradeStatusSuccess(data){
    return {
        type: FETCH_MASQUERADE_STATUS_TYPES.SUCCESS,
        data
    }
}

export function fetchMasqueradeStatusError(error) {
    return{
        type: FETCH_MASQUERADE_STATUS_TYPES.ERROR,
        error
    }
}