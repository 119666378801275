import {call, put, takeEvery} from 'redux-saga/effects';
import {FETCH_EMPLOYEES_TYPES} from 'constants/types';

import { fetchEmployeesResult, fetchEmployeesError } from 'actions/employee';
import { authHeader } from '../../helpers/authHeader';
import { isAscFioSort } from '../../helpers/sort'


const arg = (page, params) => {
    if(params !== undefined){
        return {
            pagination: {page: page, limit: 10},
            filter: {
                ...params.filter,
                isActive: true,
            }
        }
    }
}

export function* fetchEmployee(params) {
    const param = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({
            pagination: params.pagination,
            sort: isAscFioSort(),
            filter: params.filter,
            fields: ["id", "firstName", "lastName", "patronymic"]
        })
    };
    try{
        const response = yield call(fetch, `${process.env.REACT_APP_API_URL}/user/list`, param);
        const data = yield call(response.json.bind(response));
        if (response.status === 200) {
            return data
        } else {
            yield put(fetchEmployeesError(data));
        }
    } catch (error) {
        const data = {code: 500, userMessage: 'Internal Server Error'};
        yield put(fetchEmployeesError(data));
    }
}

export function* getEmployeeList(action) {
    try{
        const data = yield call(fetchEmployee, arg(1, action.params));
        let {
            result: employee,
            pagination: { count: countPage },
        } = data;
        if (countPage > employee.length) {
            /*
            let pages = Array.from(Array(pageCountProject).keys());
            pages.splice(0, 2);
            const [ ...results] = yield all(pages.map(page => call(fetchEmployee, arg(page, action.params))));
            employee = results.reduce((res, v) => [ ...res, ...v.result ], employee);
             */
            yield put(fetchEmployeesResult(employee));
        } else {
            yield put(fetchEmployeesResult(employee));
        }
    } catch (error) {
        const data = {code: 500, userMessage: 'Internal Server Error'};
        yield put(fetchEmployeesError(data));
    }
}

export function* watchLoadEmployeesRequest() {

    yield takeEvery(FETCH_EMPLOYEES_TYPES.REQUEST, getEmployeeList);
}
