export const fioSortAsc = [
    {
        "property": "lastName",
        "order": "asc"
    },
    {
        "property": "firstName",
        "order": "asc"
    },
    {
        "property": "patronymic",
        "order": "asc"
    }
];

export const fioSortDesc = [
    {
        "property": "lastName",
        "order": "desc"
    },
    {
        "property": "firstName",
        "order": "desc"
    },
    {
        "property": "patronymic",
        "order": "desc"
    }
];

export function isSort(sort) {
    if(sort){
        const {property, order} = sort;
        if(property === "fio" && order === "asc"){
            return fioSortAsc;
        } else if(property === "fio" && order === "desc"){
            return fioSortDesc;
        } else if(property && order){
            return [{
                "property": property,
                "order": order
            }]
        } else {
            return null;
        }
    } else {return null;}
}