export const FETCH_USERS_TYPES = {
  REQUEST: 'FETCH_USERS_REQUEST',
  RESULT: 'FETCH_USERS_RESULT',
  ERROR: 'FETCH_USERS_ERROR',
};
export const FETCH_USER_TYPES = {
  REQUEST: 'FETCH_USER_REQUEST',
  RESULT: 'FETCH_USER_RESULT',
  ERROR: 'FETCH_USER_ERROR',
};

export const UPDATE_USER_TYPES = {
  REQUEST: 'UPDATE_USER_REQUEST',
  SUCCESS: 'UPDATE_USER_SUCCESS',
  ERROR: 'UPDATE_USER_ERROR'
};

export const ADD_USER_TYPES = {
  REQUEST: 'ADD_USER_REQUEST',
  SUCCESS: 'ADD_USER_SUCCESS',
  ERROR: 'ADD_USER_ERROR'
};

export const CLEAR_NEW_USER = 'USERS/CLEAR_NEW_USER';

export const CLEAR_USER_ERROR = 'CLEAR_USER_ERROR';

export const CLEAR_USER_PASSWORD = 'CLEAR_USER_PASSWORD';

export const FETCH_REFERRAL_USER_TYPES = {
  REQUEST: 'FETCH_REFERRAL_USER_REQUEST',
  RESULT: 'FETCH_REFERRAL_USER_RESULT',
  ERROR: 'FETCH_REFERRAL_USER_ERROR'
};

export const FETCH_SPECIALIZATIONS_TYPES = {
  REQUEST: 'FETCH_SPECIALIZATIONS_REQUEST',
  RESULT: 'FETCH_SPECIALIZATIONS_RESULT',
  ERROR: 'FETCH_SPECIALIZATIONS_ERROR'
};

export const FETCH_ROLE_TYPES = {
  REQUEST: 'FETCH_ROLE_REQUEST',
  RESULT: 'FETCH_ROLE_RESULT',
  ERROR: 'FETCH_ROLE_ERROR'
};

export const ADD_PHONE_TYPES = {
  REQUEST: 'ADD_PHONE_REQUEST',
  SUCCESS: 'ADD_PHONE_SUCCESS',
  ERROR: 'ADD_PHONE_ERROR'
};

export const DELETE_PHONE_TYPES = {
  REQUEST: 'DELETE_PHONE_REQUEST',
  SUCCESS: 'DELETE_PHONE_SUCCESS',
  ERROR: 'DELETE_PHONE_ERROR'
};

export const UPDATE_PHONE_TYPES = {
  REQUEST: 'UPDATE_PHONE_REQUEST',
  SUCCESS: 'UPDATE_PHONE_SUCCESS',
  ERROR: 'UPDATE_PHONE_ERROR'
};

export const ADD_SALARY_TYPES = {
  REQUEST: 'ADD_SALARY_REQUEST',
  SUCCESS: 'ADD_SALARY_SUCCESS',
  ERROR: 'ADD_SALARY_ERROR'
};

export const UPDATE_SALARY_TYPES = {
  REQUEST: 'UPDATE_SALARY_REQUEST',
  SUCCESS: 'UPDATE_SALARY_SUCCESS',
  ERROR: 'UPDATE_SALARY_ERROR'
};

export const DELETE_SALARY_TYPES = {
  REQUEST: 'DELETE_SALARY_REQUEST',
  SUCCESS: 'DELETE_SALARY_SUCCESS',
  ERROR: 'DELETE_SALARY_ERROR'
};

export const FETCH_SPECIALIZATION_LEVEL_TYPES = {
  REQUEST: 'FETCH_SPECIALIZATION_LEVEL_REQUEST',
  RESULT: 'FETCH_SPECIALIZATION_LEVEL_RESULT',
  ERROR: 'FETCH_SPECIALIZATION_LEVEL_ERROR'
};

export const ADD_SPECIALIZATION_TYPES = {
  REQUEST: 'ADD_SPECIALIZATION_REQUEST',
  SUCCESS: 'ADD_SPECIALIZATION_SUCCESS',
  ERROR: 'ADD_SPECIALIZATION_ERROR'
};

export const UPDATE_SPECIALIZATION_TYPES = {
  REQUEST: 'UPDATE_SPECIALIZATION_REQUEST',
  SUCCESS: 'UPDATE_SPECIALIZATION_SUCCESS',
  ERROR: 'UPDATE_SPECIALIZATION_ERROR'
};

export const DELETE_SPECIALIZATION_TYPES = {
  REQUEST: 'DELETE_SPECIALIZATION_REQUEST',
  SUCCESS: 'DELETE_SPECIALIZATION_SUCCESS',
  ERROR: 'DELETE_SPECIALIZATION_ERROR'
};

export const FETCH_PAYSHEET_TYPES = {
  REQUEST: 'FETCH_PAYSHEET_REQUEST',
  RESULT: 'FETCH_PAYSHEET_RESULT',
  ERROR: 'FETCH_PAYSHEET_ERROR'
};

export const FETCH_DEPARTMENTS_TYPES = {
  REQUEST: 'FETCH_DEPARTMENTS_REQUEST',
  RESULT: 'FETCH_DEPARTMENTS_RESULT',
  ERROR: 'FETCH_DEPARTMENTS_ERROR'
};

export const FETCH_DEPARTMENT_TYPES = {
  REQUEST: 'FETCH_DEPARTMENT_REQUEST',
  RESULT: 'FETCH_DEPARTMENT_RESULT',
  ERROR: 'FETCH_DEPARTMENT_ERROR'
};

export const ADD_USER_DEPARTMENT_TYPES = {
  REQUEST: 'ADD_USER_DEPARTMENT_REQUEST',
  SUCCESS: 'ADD_USER_DEPARTMENT_SUCCESS',
  ERROR: 'ADD_USER_DEPARTMENT_ERROR'
};

export const UPDATE_USER_DEPARTMENT_TYPES = {
  REQUEST: 'UPDATE_USER_DEPARTMENT_REQUEST',
  SUCCESS: 'UPDATE_USER_DEPARTMENT_SUCCESS',
  ERROR: 'UPDATE_USER_DEPARTMENT_ERROR'
};

export const DELETE_USER_DEPARTMENT_TYPES = {
  REQUEST: 'DELETE_USER_DEPARTMENT_REQUEST',
  SUCCESS: 'DELETE_USER_DEPARTMENT_SUCCESS',
  ERROR: 'DELETE_USER_DEPARTMENT_ERROR'
};

export const ADD_DEPARTMENT_TYPES = {
  REQUEST: 'ADD_DEPARTMENT_REQUEST',
  SUCCESS: 'ADD_DEPARTMENT_SUCCESS',
  ERROR: 'ADD_DEPARTMENT_ERROR'
};

export const UPDATE_DEPARTMENT_TYPES = {
  REQUEST: 'UPDATE_DEPARTMENT_REQUEST',
  SUCCESS: 'UPDATE_DEPARTMENT_SUCCESS',
  ERROR: 'UPDATE_DEPARTMENT_ERROR'
};

export const DELETE_DEPARTMENT_TYPES = {
  REQUEST: 'DELETE_DEPARTMENT_REQUEST',
  SUCCESS: 'DELETE_DEPARTMENT_SUCCESS',
  ERROR: 'DELETE_DEPARTMENT_ERROR'
};

export const FETCH_INCOMES_TYPES = {
  REQUEST: 'FETCH_INCOMES_REQUEST',
  RESULT: 'FETCH_INCOMES_RESULT',
  ERROR: 'FETCH_INCOMES_ERROR'
};

export const FETCH_INCOME_TYPES = {
  REQUEST: 'FETCH_INCOME_REQUEST',
  RESULT: 'FETCH_INCOME_RESULT',
  ERROR: 'FETCH_INCOME_ERROR',
};

export const UPDATE_INCOME_TYPES = {
  REQUEST: 'UPDATE_INCOME_REQUEST',
  SUCCESS: 'UPDATE_INCOME_SUCCESS',
  ERROR: 'UPDATE_INCOME_ERROR'
};

export const ADD_INCOME_TYPES = {
  REQUEST: 'ADD_INCOME_REQUEST',
  SUCCESS: 'ADD_INCOME_SUCCESS',
  ERROR: 'ADD_INCOME_ERROR'
};

export const DELETE_INCOME_TYPES = {
  REQUEST: 'DELETE_INCOME_REQUEST',
  SUCCESS: 'DELETE_INCOME_SUCCESS',
  ERROR: 'DELETE_INCOME_ERROR'
};

export const FETCH_PAYOUTS_TYPES = {
  REQUEST: 'FETCH_PAYOUTS_REQUEST',
  RESULT: 'FETCH_PAYOUTS_RESULT',
  ERROR: 'FETCH_PAYOUTS_ERROR'
};

export const FETCH_PAYOUT_TYPES = {
  REQUEST: 'FETCH_PAYOUT_REQUEST',
  RESULT: 'FETCH_PAYOUT_RESULT',
  ERROR: 'FETCH_PAYOUT_ERROR'
};

export const UPDATE_PAYOUT_TYPES = {
  REQUEST: 'UPDATE_PAYOUT_REQUEST',
  SUCCESS: 'UPDATE_PAYOUT_SUCCESS',
  ERROR: 'UPDATE_PAYOUT_ERROR'
};

export const ADD_PAYOUT_TYPES = {
  REQUEST: 'ADD_PAYOUT_REQUEST',
  SUCCESS: 'ADD_PAYOUT_SUCCESS',
  ERROR: 'ADD_PAYOUT_ERROR'
};

export const DELETE_PAYOUT_TYPES = {
  REQUEST: 'DELETE_PAYOUT_REQUEST',
  SUCCESS: 'DELETE_PAYOUT_SUCCESS',
  ERROR: 'DELETE_PAYOUT_ERROR'
};

export const FETCH_PROJECTS_TYPES = {
  REQUEST: 'FETCH_PROJECTS_REQUEST',
  RESULT: 'FETCH_PROJECTS_RESULT',
  ERROR: 'FETCH_PROJECTS_ERROR'
};

export const FETCH_PROJECT_TYPES = {
  REQUEST: 'FETCH_PROJECT_REQUEST',
  RESULT: 'FETCH_PROJECT_RESULT',
  ERROR: 'FETCH_PROJECT_ERROR',
};

export const FETCH_ACCOUNTS_TYPES = {
  REQUEST: 'FETCH_ACCOUNTS_REQUEST',
  RESULT: 'FETCH_ACCOUNTS_RESULT',
  ERROR: 'FETCH_ACCOUNTS_ERROR'
}

export const FETCH_ENTITIES_TYPES = {
  REQUEST: 'FETCH_ENTITIES_REQUEST',
  RESULT: 'FETCH_ENTITIES_RESULT',
  ERROR: 'FETCH_ENTITIES_ERROR'
};

export const FETCH_ENTITY_TYPES = {
  REQUEST: 'FETCH_ENTITY_REQUEST',
  RESULT: 'FETCH_ENTITY_RESULT',
  ERROR: 'FETCH_ENTITY_ERROR',
};

export const UPDATE_ENTITY_TYPES = {
  REQUEST: 'UPDATE_ENTITY_REQUEST',
  SUCCESS: 'UPDATE_ENTITY_SUCCESS',
  ERROR: 'UPDATE_ENTITY_ERROR'
};

export const ADD_ENTITY_TYPES = {
  REQUEST: 'ADD_ENTITY_REQUEST',
  SUCCESS: 'ADD_ENTITY_SUCCESS',
  ERROR: 'ADD_ENTITY_ERROR'
};

export const CLEAR_PAYOUT_ERROR = 'CLEAR_PAYOUT_ERROR';

export const CLEAR_ENTITY_ERROR = 'CLEAR_ENTITY_ERROR';

export const CLEAR_INCOME_ERROR = 'CLEAR_INCOME_ERROR';

export const CLEAR_DEPARTMENT_ERROR = 'CLEAR_DEPARTMENT_ERROR';

export const FETCH_EMPLOYEES_TYPES = {
  REQUEST: 'FETCH_EMPLOYEES_REQUEST',
  RESULT: 'FETCH_EMPLOYEES_RESULT',
  ERROR: 'FETCH_EMPLOYEES_ERROR'
};

export const FETCH_CLIENTS_TYPES = {
  REQUEST: 'FETCH_CLIENTS_REQUEST',
  RESULT: 'FETCH_CLIENTS_RESULT',
  ERROR: 'FETCH_CLIENTS_ERROR'
};

export const FETCH_MANAGERS_TYPES = {
  REQUEST: 'FETCH_MANAGERS_REQUEST',
  RESULT: 'FETCH_MANAGERS_RESULT',
  ERROR: 'FETCH_MANAGERS_ERROR'
};

export const FETCH_LEADS_TYPES = {
  REQUEST: 'FETCH_LEADS_REQUEST',
  RESULT: 'FETCH_LEADS_RESULT',
  ERROR: 'FETCH_LEADS_ERROR'
};

export const FETCH_LEAD_TYPES = {
  REQUEST: 'FETCH_LEAD_REQUEST',
  RESULT: 'FETCH_LEAD_RESULT',
  ERROR: 'FETCH_LEAD_ERROR',
};

export const UPDATE_LEAD_TYPES = {
  REQUEST: 'UPDATE_LEAD_REQUEST',
  SUCCESS: 'UPDATE_LEAD_SUCCESS',
  ERROR: 'UPDATE_LEAD_ERROR'
};

export const ADD_LEAD_TYPES = {
  REQUEST: 'ADD_LEAD_REQUEST',
  SUCCESS: 'ADD_LEAD_SUCCESS',
  ERROR: 'ADD_LEAD_ERROR'
};

export const CLEAR_LEAD_ERROR = 'CLEAR_LEAD_ERROR';

export const FETCH_REDMINE_USERS_TYPES = {
  REQUEST: 'FETCH_REDMINE_USERS_REQUEST',
  RESULT: 'FETCH_REDMINE_USERS_RESULT',
  ERROR: 'FETCH_REDMINE_USERS_ERROR'
};

export const FETCH_LOGIN_TYPES = {
  REQUEST: 'FETCH_LOGIN_REQUEST',
  SUCCESS: 'FETCH_LOGIN_SUCCESS',
  ERROR: 'FETCH_LOGIN_ERROR'
};

export const FETCH_LOGOUT_TYPES = {
  REQUEST: 'FETCH_LOGOUT_REQUEST',
  SUCCESS: 'FETCH_LOGOUT_SUCCESS',
  ERROR: 'FETCH_LOGOUT_ERROR'
};

export const CLEAR_LOGIN_ERROR = 'CLEAR_LOGIN_ERROR';

export const FETCH_EVENTS_TYPES = {
  REQUEST: 'FETCH_EVENTS_REQUEST',
  RESULT: 'FETCH_EVENTS_RESULT',
  ERROR: 'FETCH_EVENTS_ERROR'
};

export const FETCH_EVENT_TYPES = {
  REQUEST: 'FETCH_EVENT_REQUEST',
  RESULT: 'FETCH_EVENT_RESULT',
  ERROR: 'FETCH_EVENT_ERROR'
};

export const ADD_EVENT_TYPES = {
  REQUEST: 'ADD_EVENT_REQUEST',
  SUCCESS: 'ADD_EVENT_SUCCESS',
  ERROR: 'ADD_EVENT_ERROR'
};

export const UPDATE_EVENT_TYPES = {
  REQUEST: 'UPDATE_EVENT_REQUEST',
  SUCCESS: 'UPDATE_EVENT_SUCCESS',
  ERROR: 'UPDATE_EVENT_ERROR'
};

export const DELETE_EVENT_TYPES = {
  REQUEST: 'DELETE_EVENT_REQUEST',
  SUCCESS: 'DELETE_EVENT_SUCCESS',
  ERROR: 'DELETE_EVENT_ERROR'
};

export const CLEAR_EVENT_ERROR = 'CLEAR_EVENT_ERROR';

export const FETCH_CALENDAR_TYPES = {
  REQUEST: 'FETCH_CALENDAR_REQUEST',
  SUCCESS: 'FETCH_CALENDAR_SUCCESS',
  ERROR: 'FETCH_CALENDAR_ERROR'
};

export const ADD_CALENDAR_TYPES = {
  REQUEST: 'ADD_CALENDAR_REQUEST',
  SUCCESS: 'ADD_CALENDAR_SUCCESS',
  ERROR: 'ADD_CALENDAR_ERROR'
};

export const UPDATE_CALENDAR_TYPES = {
  REQUEST: 'UPDATE_CALENDAR_REQUEST',
  SUCCESS: 'UPDATE_CALENDAR_SUCCESS',
  ERROR: 'UPDATE_CALENDAR_ERROR'
};

export const VIEW_CALENDAR_TYPES = {
  REQUEST: 'VIEW_CALENDAR_REQUEST',
  SUCCESS: 'VIEW_CALENDAR_SUCCESS',
  ERROR: 'VIEW_CALENDAR_ERROR',
};

export const DELETE_CALENDAR_TYPES = {
  REQUEST: 'DELETE_CALENDAR_REQUEST',
  SUCCESS: 'DELETE_CALENDAR_SUCCESS',
  ERROR: 'DELETE_CALENDAR_ERROR'
};

export const FETCH_PROFILE_TYPES = {
  REQUEST: 'FETCH_PROFILE_REQUEST',
  RESULT: 'FETCH_PROFILE_RESULT',
  ERROR: 'FETCH_PROFILE_ERROR'
};

export const FETCH_DASHBOARD_EMPLOYEE_TYPES = {
  REQUEST: 'FETCH_DASHBOARD_EMPLOYEE_REQUEST',
  RESULT: 'FETCH_DASHBOARD_EMPLOYEE_RESULT',
  ERROR: 'FETCH_DASHBOARD_EMPLOYEE_ERROR'
};

export const FETCH_DASHBOARD_USERS_TYPES = {
  REQUEST: 'FETCH_DASHBOARD_USERS_REQUEST',
  RESULT: 'FETCH_DASHBOARD_USERS_RESULT',
  ERROR: 'FETCH_DASHBOARD_USERS_ERROR'
};

export const FETCH_DASHBOARD_COMPANY_TYPES = {
  REQUEST: 'FETCH_DASHBOARD_COMPANY_REQUEST',
  RESULT: 'FETCH_DASHBOARD_COMPANY_RESULT',
  ERROR: 'FETCH_DASHBOARD_COMPANY_ERROR'
};

export const FETCH_DASHBOARD_PROFIT_TYPES = {
  REQUEST: 'FETCH_DASHBOARD_PROFIT_REQUEST',
  RESULT: 'FETCH_DASHBOARD_PROFIT_RESULT',
  ERROR: 'FETCH_DASHBOARD_PROFIT_ERROR'
};

export const FETCH_DASHBOARD_USER_PROFIT_TYPES = {
  REQUEST: 'FETCH_DASHBOARD_USER_PROFIT_REQUEST',
  RESULT: 'FETCH_DASHBOARD_USER_PROFIT_RESULT',
  ERROR: 'FETCH_DASHBOARD_USER_PROFIT_ERROR'
};

export const FETCH_DASHBOARD_PROJECTS_TYPES = {
  REQUEST: 'FETCH_DASHBOARD_PROJECTS_REQUEST',
  RESULT: 'FETCH_DASHBOARD_PROJECTS_RESULT',
  ERROR: 'FETCH_DASHBOARD_PROJECTS_ERROR'
};

export const FETCH_DASHBOARD_PROJECTS_MANAGERS_TYPES = {
  REQUEST: 'FETCH_DASHBOARD_PROJECTS_MANAGERS_REQUEST',
  RESULT: 'FETCH_DASHBOARD_PROJECTS_MANAGERS_RESULT',
  ERROR: 'FETCH_DASHBOARD_PROJECTS_MANAGERS_ERROR'
};

export const FETCH_DASHBOARD_DEPARTMENTS_TYPES = {
  REQUEST: 'FETCH_DASHBOARD_DEPARTMENTS_REQUEST',
  RESULT: 'FETCH_DASHBOARD_DEPARTMENTS_RESULT',
  ERROR: 'FETCH_DASHBOARD_DEPARTMENTS_ERROR'
};

export const FETCH_DASHBOARD_PAYROLL_TYPES = {
  REQUEST: 'FETCH_DASHBOARD_DEPARTMENTS_PAYROLL_REQUEST',
  RESULT: 'FETCH_DASHBOARD_DEPARTMENTS_PAYROLL_RESULT',
  ERROR: 'FETCH_DASHBOARD_DEPARTMENTS_PAYROLL_ERROR'
};

export const SET_DASHBOARD_PAYROLL_TYPES = {
  REQUEST: 'SET_DASHBOARD_DEPARTMENTS_PAYROLL_REQUEST',
  RESULT: 'SET_DASHBOARD_DEPARTMENTS_PAYROLL_SUCCESS',
  ERROR: 'SET_DASHBOARD_DEPARTMENTS_PAYROLL_ERROR'
};
