import { call, put, takeEvery } from 'redux-saga/effects';
import { FETCH_DASHBOARD_USER_PROFIT_TYPES } from 'constants/types';
//import { API_URL } from 'utils';
import { fetchDashboardUserProfitResult, fetchDashboardUserProfitError } from 'actions/dashboard';
import { authHeader } from '../../helpers/authHeader';

export function* getDashboardUserProfit(action) {
    const param = {
        method: 'GET',
        headers: authHeader(),
    };
    try{
        const response = yield call(
            fetch,
            `${process.env.REACT_APP_API_URL}/dashboard/user/${action.params.id}/profit/${action.params.year}/${action.params.month}`,
            param);
        const data = yield call(response.json.bind(response));
        if (response.status === 200) {
            yield put(fetchDashboardUserProfitResult(data));
        } else {
            yield put(fetchDashboardUserProfitError(data));
        }
    } catch (error) {
        const data = {code: 500, userMessage: 'Internal Server Error'};
        yield put(fetchDashboardUserProfitError(data));
    }
}

export function* watchLoadDashboardUserProfitRequest() {
    yield takeEvery(FETCH_DASHBOARD_USER_PROFIT_TYPES.REQUEST, getDashboardUserProfit);
}