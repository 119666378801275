export const activeColumns = [
  {
    id: 1,
    dataField: "type",
    title: "Тип"
  },
  {
    id: 2,
    dataField: "comment",
    title: "Примечание"
  },
  {
    id: 3,
    dataField: "project",
    title: "Проект"
  },
  {
    id: 4,
    dataField: "user",
    title: "Сотрудник"
  },
  {
    id: 5,
    dataField: "date",
    title: "Дата"
  },
  {
    id: 6,
    dataField: "amount",
    title: "Сумма"
  },
  {
    id: 7,
    dataField: "legalEntity",
    title: "Счет"
  },
  {
    id: 8,
    dataField: "year",
    title: "Год"
  },
  {
    id: 9,
    dataField: "month",
    title: "Месяц"
  },
  {
    id: 10,
    dataField: "status",
    title: "Статус"
  },
];
export const hiddenColumns = [
  {
    id: 11,
    dataField: "id",
    title: "ID"
  }
];
