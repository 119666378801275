import { payoutData } from './payoutData';
import { FETCH_PAYOUTS_TYPES } from '../constants/types';

const initialState = payoutData() || {
  loading: null,
  error: null,
  payout: null
};

const payouts = (state=initialState, action) => {
  switch (action.type) {
    case 'ADD_PAYOUT':
      return [action.data, ...state];
    case 'UPDATE_PAYOUT':
      return state.map(p => {
        if(p.id === action.data.id){
          p = action.data
        }
        return p;
      });
    case 'DELETE_PAYOUT':
      return state.filter(p => p.id !== action.data);
    case FETCH_PAYOUTS_TYPES.REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_PAYOUTS_TYPES.RESULT:
      return {
        ...state,
        payout: action.data,
        loading: false,
        error: null,
      };
    case FETCH_PAYOUTS_TYPES.ERROR:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    default:
      return state;
  }
}
export default payouts;
