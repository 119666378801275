import {call, put, takeEvery} from 'redux-saga/effects';
import {ADD_DEPARTMENT_TYPES} from 'constants/types';

import {addDepartmentSuccess, addDepartmentError} from 'actions/department';
import { authHeader } from '../../helpers/authHeader';

export function* addDepartment(action) {
    const param = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(action.params)
    };
    try{
        const response = yield call(fetch, `${process.env.REACT_APP_API_URL}/department`, param);
        const data = yield call(response.json.bind(response));
        if (response.status === 200) {
            window.history.back();
            yield put(addDepartmentSuccess(data));
        } else {
            yield put(addDepartmentError(data));
        }
    } catch (error) {
        const data = {code: 500, userMessage: 'Internal Server Error'};
        yield put(addDepartmentError(data));
    }
}

export function* watchAddDepartmentRequest() {
    yield takeEvery(ADD_DEPARTMENT_TYPES.REQUEST, addDepartment);
}