import {dateFormat} from "./helpers";
import moment from "moment";

const lastNameAsc = {
    "property": "lastName",
    "order": "asc"
};

const firstNameAsc = {
    "property": "firstName",
    "order": "asc"
};

const patronymicAsc =  {
    "property": "patronymic",
    "order": "asc"
};

const lastNameDesc = {
    "property": "lastName",
    "order": "desc"
};

const firstNameDesc = {
    "property": "firstName",
    "order": "desc"
};

const patronymicDesc =  {
    "property": "patronymic",
    "order": "desc"
};

export function isMultiSort(sort) {
    if(sort){
        const {sortName, sortOrder} = sort;
        let sortied = sortName.map((name,i) => {
            return {
                "property": name,
                "order": sortOrder[i]
            }
        });
        const isCheckFioAsc = sortied.some(s => s.property === "user" && s.order === "asc");
        const isCheckFioDesc = sortied.some(s => s.property === "user" && s.order === "desc");
        if(isCheckFioAsc){
            let index = sortied.findIndex(s => s.property === "user" && s.order === "asc");
            sortied.splice(index, 1, lastNameAsc, firstNameAsc, patronymicAsc);
            return sortied;
        } else if(isCheckFioDesc){
            let index = sortied.findIndex(s => s.property === "user" && s.order === "desc");
            sortied.splice(index, 1, lastNameDesc, firstNameDesc, patronymicDesc);
            return sortied;
        }
        else {
            return sortied;
        }
    }
}

export function isAscFioSort(){
    return [lastNameAsc, firstNameAsc, patronymicAsc]
}

export const argSort = (page, id) => {
    return {
        pagination: { page: page, limit: 10},
        sort: [
            {
                "property": "start",
                "order": "asc"
            }
        ],
        filter: {
            date: dateFormat(moment()),
            "user.id": id
        }
    }
}

export function removeDuplicateSorted(sorted){
    return [...sorted.reduce((acc, item) => {
        acc.set(item.property, item);
        return acc;
    }, new Map()).values()]
}
