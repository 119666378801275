import {call, put, takeEvery} from 'redux-saga/effects';
import {DELETE_SPECIALIZATION_TYPES} from 'constants/types';

import {deleteSpecializationSuccess, deleteSpecializationError} from 'actions/specialization';
import { authHeader } from '../../helpers/authHeader';

export function* deleteSpecialization(action) {
    const param = {
        method: 'DELETE',
        headers: authHeader()
    };
    try{
        const response = yield call(fetch, `${process.env.REACT_APP_API_URL}/user/specialization/${action.params.id}`, param);
        const data = yield call(response.json.bind(response));
        if (response.status === 200) {
            yield put(deleteSpecializationSuccess({...data, id: action.params.id}));
        } else if (response.status === 400) {
            yield put(deleteSpecializationError(data));
        } else if (response.status === 401){
            yield put(deleteSpecializationError(data));
        } else if (response.status === 404){
            yield (deleteSpecializationError(data));
        }
    } catch (error) {
        const data = {code: 500, userMessage: 'Internal Server Error'};
        yield put(deleteSpecializationError(data));
    }
}

export function* watchDeleteSpecializationRequest() {
    yield takeEvery(DELETE_SPECIALIZATION_TYPES.REQUEST, deleteSpecialization);
}
