import { transfersData } from './transfersData';

const initialState = transfersData();

const transfer = (state=initialState, action) => {
  switch (action.type) {
    case 'ADD_TRANSFER':
      return [action.data, ...state];
    case 'UPDATE_TRANSFER':
      return state.map(t => {
        if(t.id === action.data.id){
          t = action.data
        }
        return t;
      });
    case 'DELETE_TRANSFER':
      return state.filter(t => t.id !== action.data);
    default:
      return state;
  }
}
export default transfer;
