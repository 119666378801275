export const FETCH_USER_CERTIFICATIONS_TYPES = {
    REQUEST: 'FETCH_USER_CERTIFICATIONS_REQUEST',
    RESULT: 'FETCH_USER_CERTIFICATIONS_RESULT',
    ERROR: 'FETCH_USER_CERTIFICATIONS_ERROR'
};

export const FETCH_USER_CERTIFICATION_TYPES = {
    REQUEST: 'FETCH_USER_CERTIFICATION_REQUEST',
    RESULT: 'FETCH_USER_CERTIFICATION_RESULT',
    ERROR: 'FETCH_USER_CERTIFICATION_ERROR'
};

export const ADD_USER_CERTIFICATION_TYPES = {
    REQUEST: 'ADD_USER_CERTIFICATION_REQUEST',
    SUCCESS: 'ADD_USER_CERTIFICATION_SUCCESS',
    ERROR: 'ADD_USER_CERTIFICATION_ERROR'
};

export const UPDATE_USER_CERTIFICATION_TYPES = {
    REQUEST: 'UPDATE_USER_CERTIFICATION_REQUEST',
    SUCCESS: 'UPDATE_USER_CERTIFICATION_SUCCESS',
    ERROR: 'UPDATE_USER_CERTIFICATION_ERROR'
};

export const DELETE_USER_CERTIFICATION_TYPES = {
    REQUEST: 'DELETE_USER_CERTIFICATION_REQUEST',
    SUCCESS: 'DELETE_USER_CERTIFICATION_SUCCESS',
    ERROR: 'DELETE_USER_CERTIFICATION_ERROR'
};
