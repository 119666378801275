export const addUserInfoField = (state, fieldName, data) => ({
    ...state,
    user: {
      ...state.user,
      [fieldName]: [...state.user[fieldName], data],
    }
});

export const updateUserInfoField = (state, fieldName, data) => {
    const item = data;
    const newItems = state.user[fieldName].map(stateItem => stateItem.id === item.id ? {
        ...stateItem,
        ...item,
        } : stateItem);
    return {
        ...state,
        user: {
            ...state.user,
            [fieldName]: newItems,
        }
    }
};

export const deleteUserInfoField = (state, fieldName, data) => {
    const { id } = data;
    const newItems = state.user[fieldName].filter(item => item.id !== id);
    return {
      ...state,
      user: {
        ...state.user,
        [fieldName]: newItems,
      }
    }
}
