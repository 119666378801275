import { call, put, takeEvery } from 'redux-saga/effects';
import { UPDATE_USER_CERTIFICATION_TYPES } from '../../constants/certifications/certificationsUser';

import { updateUserCertificationsSuccess, updateUserCertificationsError } from 'actions/certification';
import { authHeader } from 'helpers/authHeader';

export function* updateUserCertification(action) {
    const param = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(action.params.certification)
    };
    try{
        const response = yield call(fetch, `${process.env.REACT_APP_API_URL}/user/certification/${action.params.id}`, param);
        const data = yield call(response.json.bind(response));
        if (response.status === 200) {
            yield put(updateUserCertificationsSuccess(data));
        } else {

        }
    } catch (error) {
        const data = {code: 500, userMessage: 'Internal Server Error'};
        yield put(updateUserCertificationsError(data));
    }
}

export function* watchUpdateUserCertificationRequest() {

    yield takeEvery(UPDATE_USER_CERTIFICATION_TYPES.REQUEST, updateUserCertification);
}
