import nanoid from 'nanoid';
import moment from 'moment';
moment.locale('ru');

const size = 5;

const newPayout = () => {
  const statusChance = Math.random();
  return {
    id: nanoid(size),
    type: statusChance > 0.66
        ? 'Прочее'
        : statusChance > 0.33
            ? 'Офисные расходы'
            : 'Хостинг и инструменты',
    note: statusChance > 0.66
        ? 'Оплата по счету № 02/д-1 ' +
        'от 02.12.2019г. за юр. услуги по подготовке и подаче искового заявления о взыскании' +
        ' с ООО "СКИЛЛВИЖН" задолженности, Без НДС'
        : statusChance > 0.33
            ? 'Хабстаф'
            : 'Почта',
    project: statusChance > 0.66
        ? 'Академия бристоль (₽)'
        : statusChance > 0.33
            ?  'ИП Титов (₽)'
            : 'teplomatica (₽)',
    employee: statusChance > 0.66
        ? 'Иванов Иван Иванович'
        : statusChance > 0.33
            ? 'Петров Петр Петрович'
            : 'Алексеев Алексей Алексеевич',
    date: moment(),
    amount: Math.random() * 50000,
    account:  statusChance > 0.66
        ? 'Мирошников'
        : statusChance > 0.33
            ? 'ООО "Цифровая палочка"'
            : 'Бормотов',
    year: statusChance > 0.66
        ? '2019'
        : statusChance > 0.33
            ? '2018'
            : '2017',
    month: statusChance > 0.66
        ? 'Сентябрь'
        : statusChance > 0.33
            ? 'Октябрь'
            : 'Ноябрь',
    status: statusChance > 0.66
        ? 'Оплачено'
        : statusChance > 0.33
            ? 'Частино оплачено'
            : 'Неоплачено',
  }
};

const payoutData = () => {
  const arr = [];
  for(let i = 0; i <= 30; i++){
    arr.push(newPayout());
  }
  return arr;
};

export { payoutData }

