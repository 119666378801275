export const activeColumns = [
    {
        id: 1,
        dataField: "title",
        title: "Наименование"
    },
    {
        id: 2,
        dataField: "client",
        title: "Клиент"
    },
    {
        id: 3,
        dataField: "manager",
        title: "Менеджер"
    },
    {
        id: 4,
        dataField: "receiveDate",
        title: "Дата поступления"
    },
    {
        id: 5,
        dataField: "sendKpDate",
        title: "Дата отправки КП"
    },
    {
        id: 6,
        dataField: "status",
        title: "Статус"
    }
];
export const hiddenColumns = [
    {
        id: 7,
        dataField: "id",
        title: "ID"
    }
];
