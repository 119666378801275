export const activeColumns = [
    {
        id: 1,
        dataField: "name",
        title: "Наименование"
    },
];
export const hiddenColumns = [
    {
        id: 2,
        dataField: "id",
        title: "ID"
    }
];
