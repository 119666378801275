import {FETCH_PAYSHEET_TYPES} from "../constants/types";

export function fetchPaysheetRequest(params){
    return {
        type: FETCH_PAYSHEET_TYPES.REQUEST,
        params
    }
}

export function fetchPaysheetResult(data){
    return {
        type: FETCH_PAYSHEET_TYPES.RESULT,
        data
    }
}

export function fetchPaysheetError(error) {
    return{
        type: FETCH_PAYSHEET_TYPES.ERROR,
        error
    }
}