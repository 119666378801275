import {depositData} from './depositData';

const initialValue = depositData();

const deposit = (state=initialValue, action) => {
  switch (action.type) {
    case 'ADD_DEPOSIT':
      return [action.data, ...state];
    default:
      return state;
  }
}
export default deposit;
