import {
  FETCH_SPECIALIZATIONS_TYPES,
  ADD_SPECIALIZATION_TYPES,
  UPDATE_SPECIALIZATION_TYPES,
  DELETE_SPECIALIZATION_TYPES
} from '../constants/types';

export function fetchSpecializationsRequest(params){
  return {
    type: FETCH_SPECIALIZATIONS_TYPES.REQUEST,
    params
  }
}

export function fetchSpecializationsResult(data){
  return {
    type: FETCH_SPECIALIZATIONS_TYPES.RESULT,
    data
  }
}

export function fetchSpecializationsError(error) {
  return {
    type: FETCH_SPECIALIZATIONS_TYPES.ERROR,
    error
  }
}

export function addSpecializationRequest(params) {
  return {
    type: ADD_SPECIALIZATION_TYPES.REQUEST,
    params
  }
}

export function addSpecializationSuccess(data) {
  return {
    type: ADD_SPECIALIZATION_TYPES.SUCCESS,
    data
  }
}

export function addSpecializationError(error) {
  return {
    type: ADD_SPECIALIZATION_TYPES.ERROR,
    error
  }
}

export function updateSpecializationRequest(params) {
  return {
    type: UPDATE_SPECIALIZATION_TYPES.REQUEST,
    params
  }
}

export function updateSpecializationSuccess(data) {
  return {
    type: UPDATE_SPECIALIZATION_TYPES.SUCCESS,
    data
  }
}

export function updateSpecializationError(error) {
  return {
    type: UPDATE_SPECIALIZATION_TYPES.ERROR,
    error
  }
}

export function deleteSpecializationRequest(params) {
  return {
    type: DELETE_SPECIALIZATION_TYPES.REQUEST,
    params
  }
}

export function deleteSpecializationSuccess(data) {
  return {
    type: DELETE_SPECIALIZATION_TYPES.SUCCESS,
    data
  }
}

export function deleteSpecializationError(error) {
  return {
    type: DELETE_SPECIALIZATION_TYPES.ERROR,
    error
  }
}


