import {call, put, takeEvery} from 'redux-saga/effects';
import {FETCH_DEPARTMENTS_TYPES} from 'constants/types';

import {fetchDepartmentsResult, fetchDepartmentsError} from '../../actions/department';
import { authHeader } from '../../helpers/authHeader';

function isMultiSort(sort) {
    if(sort){
        const {sortName, sortOrder} = sort;
        let sortied = sortName.map((name,i) => {
            return {
                "property": name,
                "order": sortOrder[i]
            }
        });
        return sortied;
    }
}

function isFilter(obj) {
    let result = {};
    if(obj){
        Object.keys(obj).filter(key => obj[key] !== '' ? result[key] = obj[key] : '');
    }
    return result;
}

export function* getDepartmentList(action) {
    const param = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({
            pagination: action.params.pagination,
            sort: isMultiSort(action.params.sort),
            filter: isFilter(action.params.filter)
        })
    };
    try{
        const response = yield call(fetch, `${process.env.REACT_APP_API_URL}/department/list`, param);
        const data = yield call(response.json.bind(response));
        if (response.status === 200) {
            yield put(fetchDepartmentsResult(data, action.params));
        } else {
            yield put(fetchDepartmentsError(data));
        }
    } catch (error) {
        const data = {code: 500, userMessage: 'Internal Server Error'};
        yield put(fetchDepartmentsError(data));
    }
}

export function* watchLoadDepartmentsRequest() {
    yield takeEvery(FETCH_DEPARTMENTS_TYPES.REQUEST, getDepartmentList);
}
