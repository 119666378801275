export const activeColumns = [
    {
        id: 1,
        dataField: "owner",
        title: "Владелец"
    },
    {
        id: 2,
        dataField: "name",
        title: "Название"
    },
    {
        id: 3,
        dataField: "status",
        title: "Статус"
    },
    {
        id: 4,
        dataField: "currency",
        title: "Валюта"
    },
];
export const hiddenColumns = [
    {
        id: 5,
        dataField: "id",
        title: "ID"
    }
];
