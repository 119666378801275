import {
  FETCH_SPECIALIZATIONS_TYPES,
  ADD_SPECIALIZATION_TYPES,
  UPDATE_SPECIALIZATION_TYPES,
  DELETE_SPECIALIZATION_TYPES
} from '../constants/types';

const initialState = {
  specializations: null,
  loading: null,
  error: null
};

const specialization = (state=initialState, action) => {
  switch (action.type) {
    case FETCH_SPECIALIZATIONS_TYPES.REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_SPECIALIZATIONS_TYPES.RESULT:
      return {
        ...state,
        specializations: action.data,
        loading: false
      };
    case FETCH_SPECIALIZATIONS_TYPES.ERROR:
      return {
        ...state,
        error: action.error
      };
    case ADD_SPECIALIZATION_TYPES.REQUEST:
      return {
        ...state,
        loading: true
      };
    case ADD_SPECIALIZATION_TYPES.SUCCESS:
      return {
        ...state,
        loading: false
      };
    case ADD_SPECIALIZATION_TYPES.ERROR:
      return {
        ...state,
        error: action.error
      };
    case UPDATE_SPECIALIZATION_TYPES.REQUEST:
      return {
        ...state,
        loading: true
      };
    case UPDATE_SPECIALIZATION_TYPES.SUCCESS:
      return {
        ...state,
        loading: false
      };
    case UPDATE_SPECIALIZATION_TYPES.ERROR:
      return {
        ...state,
        error: action.error
      };
    case DELETE_SPECIALIZATION_TYPES.REQUEST:
      return {
        ...state,
        loading: true
      };
    case DELETE_SPECIALIZATION_TYPES.SUCCESS:
      return {
        ...state,
        loading: false
      };
    case DELETE_SPECIALIZATION_TYPES.ERROR:
      return {
        ...state,
        error: action.error
      };
    default:
      return state;
  }
}
export default specialization;
