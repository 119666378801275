import {call, put, takeEvery} from 'redux-saga/effects';
import {FETCH_EVENT_TYPES} from 'constants/types';

import {fetchEventResult, fetchEventError} from 'actions/event';
import {authHeader} from "../../helpers/authHeader";

export function* getEvent(action) {
    const param = {
        method: 'GET',
        headers: authHeader(),
    };
    try{
        const response = yield call(fetch, `${process.env.REACT_APP_API_URL}/user/${action.params}`, param);
        const data = yield call(response.json.bind(response));
        if (response.status === 200) {
            yield put(fetchEventResult(data));
        } else {
            yield put(fetchEventError(data));
        }
    } catch (error) {
        const data = {code: 500, userMessage: 'Internal Server Error'};
        yield put(fetchEventError(data));
    }
}

export function* watchLoadEventRequest() {
    yield takeEvery(FETCH_EVENT_TYPES.REQUEST, getEvent);
}
