import { call, put, takeEvery } from 'redux-saga/effects';
import { UPDATE_WELCOME_LETTER_TYPES } from 'constants/welcomеletter/welcomeletter';

import { updateWelcomeLetterSuccess, updateWelcomeLetterError } from 'actions/welcomeletter/welcomeletter';
import { authHeader } from 'helpers/authHeader';

export function* updateWelcomeLetter(action) {
    const param = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(action.params.letter)
    };
    try{
        const response = yield call(fetch, `${process.env.REACT_APP_API_URL}/user/settings/welcome-letter`, param);
        const data = yield call(response.json.bind(response));
        if (response.status === 200) {
            yield put(updateWelcomeLetterSuccess(data));
        } else {
            yield put(updateWelcomeLetterError(data));
        }
    } catch (error) {
        const data = {code: 500, userMessage: 'Internal Server Error'};
        yield put(updateWelcomeLetterError(data));
    }
}

export function* watchUpdateWelcomeLetterErrorRequest() {
    yield takeEvery(UPDATE_WELCOME_LETTER_TYPES.REQUEST, updateWelcomeLetter);
}
