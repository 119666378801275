import { FETCH_CLIENTS_TYPES } from '../constants/types';

export function fetchClientsRequest(params){
    return {
        type: FETCH_CLIENTS_TYPES.REQUEST,
        params
    }
}

export function fetchClientsResult(data){
    return {
        type: FETCH_CLIENTS_TYPES.RESULT,
        data
    }
}

export function fetchClientsError(error) {
    return{
        type: FETCH_CLIENTS_TYPES.ERROR,
        error
    }
}