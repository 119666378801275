import {
    FETCH_USER_CERTIFICATIONS_TYPES,
    FETCH_USER_CERTIFICATION_TYPES,
    ADD_USER_CERTIFICATION_TYPES,
    UPDATE_USER_CERTIFICATION_TYPES,
    DELETE_USER_CERTIFICATION_TYPES
} from '../constants/certifications/certificationsUser';

export function fetchUserCertificationsRequest(params){
    return {
        type: FETCH_USER_CERTIFICATIONS_TYPES.REQUEST,
        params
    }
}

export function fetchUserCertificationsResult(data){
    return {
        type: FETCH_USER_CERTIFICATIONS_TYPES.RESULT,
        data
    }
}

export function fetchUserCertificationsError(error) {
    return{
        type: FETCH_USER_CERTIFICATIONS_TYPES.ERROR,
        error
    }
}

export function fetchUserCertificationRequest(params){
    return {
        type: FETCH_USER_CERTIFICATION_TYPES.REQUEST,
        params
    }
}

export function fetchUserCertificationResult(data){
    return {
        type: FETCH_USER_CERTIFICATION_TYPES.RESULT,
        data
    }
}

export function fetchUserCertificationError(error) {
    return{
        type: FETCH_USER_CERTIFICATION_TYPES.ERROR,
        error
    }
}

export function addUserCertificationsRequest(params){
    return {
        type: ADD_USER_CERTIFICATION_TYPES.REQUEST,
        params
    }
}

export function addUserCertificationsSuccess(data){
    return {
        type: ADD_USER_CERTIFICATION_TYPES.SUCCESS,
        data
    }
}

export function addUserCertificationsError(error) {
    return{
        type: ADD_USER_CERTIFICATION_TYPES.ERROR,
        error
    }
}

export function updateUserCertificationsRequest(params) {
    return {
        type: UPDATE_USER_CERTIFICATION_TYPES.REQUEST,
        params
    }
}

export function updateUserCertificationsSuccess(data) {
    return {
        type: UPDATE_USER_CERTIFICATION_TYPES.SUCCESS,
        data
    }
}

export function updateUserCertificationsError(error) {
    return {
        type: UPDATE_USER_CERTIFICATION_TYPES.ERROR,
        error
    }
}

export function deleteUserCertificationsRequest(params) {
    return {
        type: DELETE_USER_CERTIFICATION_TYPES.REQUEST,
        params
    }
}

export function deleteUserCertificationsSuccess(data) {
    return {
        type: DELETE_USER_CERTIFICATION_TYPES.SUCCESS,
        data
    }
}

export function deleteUserCertificationsError(error) {
    return {
        type: DELETE_USER_CERTIFICATION_TYPES.ERROR,
        error
    }
}