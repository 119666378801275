import {call, put, takeEvery} from 'redux-saga/effects';
import {ADD_USER_DEPARTMENT_TYPES} from 'constants/types';

import {addUserDepartmentSuccess, addUserDepartmentError} from 'actions/department';
import { authHeader } from '../../helpers/authHeader';

export function* addUserDepartment(action) {
    const param = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(action.params)
    };
    try{
        const response = yield call(fetch, `${process.env.REACT_APP_API_URL}/user/department`, param);
        const data = yield call(response.json.bind(response));
        if (response.status === 200) {
            yield put(addUserDepartmentSuccess(data));
        } else {
            yield put(addUserDepartmentError(data));
        }
    } catch (error) {
        const data = {code: 500, userMessage: 'Internal Server Error'};
        yield put(addUserDepartmentError(data));
    }
}

export function* watchAddUserDepartmentRequest() {
    yield takeEvery(ADD_USER_DEPARTMENT_TYPES.REQUEST, addUserDepartment);
}