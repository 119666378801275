import { call, put, takeEvery } from 'redux-saga/effects';
import { FETCH_DASHBOARD_PROFIT_TYPES } from 'constants/types';
//import { API_URL } from 'utils';
import { fetchDashboardProfitResult, fetchDashboardProfitError } from 'actions/dashboard';
import { authHeader } from '../../helpers/authHeader';

export function* getDashboardProfit(action) {
    const param = {
        method: 'GET',
        headers: authHeader(),
    };
    try{
        const response = yield call(fetch,
            `${process.env.REACT_APP_API_URL}/dashboard/profit/${action.params.year}/${action.params.month}`,
            param);
        const data = yield call(response.json.bind(response));
        if (response.status === 200) {
            yield put(fetchDashboardProfitResult(data));
        } else {
            yield put(fetchDashboardProfitError(data));
        }
    } catch (error) {
        const data = {code: 500, userMessage: 'Internal Server Error'};
        yield put(fetchDashboardProfitError(data));
    }
}

export function* watchLoadDashboardProfitRequest() {
    yield takeEvery(FETCH_DASHBOARD_PROFIT_TYPES.REQUEST, getDashboardProfit);
}