import {ADD_PHONE_TYPES, DELETE_PHONE_TYPES, UPDATE_PHONE_TYPES} from '../constants/types';

export function addPhoneRequest(params){
  return {
    type: ADD_PHONE_TYPES.REQUEST,
    params
  }
}

export function addPhoneSuccess(data) {
  return {
    type: ADD_PHONE_TYPES.SUCCESS,
    data
  }
}

export function addPhoneError(error) {
  return {
    type: ADD_PHONE_TYPES.ERROR,
    error
  }
}

export function deletePhoneRequest(params){
  return {
    type: DELETE_PHONE_TYPES.REQUEST,
    params
  }
}

export function deletePhoneSuccess(data) {
  return {
    type: DELETE_PHONE_TYPES.SUCCESS,
    data
  }
}

export function deletePhoneError(error) {
  return {
    type: DELETE_PHONE_TYPES.ERROR,
    error
  }
}

export function updatePhoneRequest(params){
  return {
    type: UPDATE_PHONE_TYPES.REQUEST,
    params
  }
}

export function updatePhoneSuccess(data){
  return {
    type: UPDATE_PHONE_TYPES.SUCCESS,
    data
  }
}

export function updatePhoneError(error) {
  return {
    type: UPDATE_PHONE_TYPES.ERROR,
    error
  }
}


