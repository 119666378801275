import {call, put, takeLatest, all} from 'redux-saga/effects';
import {UPDATE_USER_TYPES} from '../../constants/types';

import {updateUserSuccess, updateUserError, clearUserError} from '../../actions/user';
import {updatePhoneRequest, addPhoneRequest, deletePhoneRequest} from '../../actions/phone';
import {addSalaryRequest, updateSalaryRequest, deleteSalaryRequest} from '../../actions/salary';
import {
  addSpecializationRequest,
  updateSpecializationRequest,
  deleteSpecializationRequest} from '../../actions/specialization';
import {
  addUserDepartmentRequest,
  updateUserDepartmentRequest,
  deleteUserDepartmentRequest} from '../../actions/department';
import {
  addUserCertificationsRequest,
  updateUserCertificationsRequest,
  deleteUserCertificationsRequest
} from '../../actions/certification';
import { authHeader } from '../../helpers/authHeader';
// import { fetchCertification } from 'sagas/certification/getCertification';

export function* updateUser(action) {
  yield put(clearUserError());
  const { paymentDetails, ...userInfo } = action.params.users;
  const param = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify(userInfo)
  };

  try {
    const response = yield call(fetch, `${process.env.REACT_APP_API_URL}/user/${action.params.id}`, param);

    const data = yield call(response.json.bind(response));
    let newPaymentDetails = {}
    if (response.status === 200) {
      if (action.params.users.paymentDetails) {
        const paramPaymentDetails = {
          method: 'PUT',
          headers: authHeader(),
          body: JSON.stringify(paymentDetails),
        };

        const res = yield call(fetch, `${process.env.REACT_APP_API_URL}/user/${action.params.id}/payment-details`, paramPaymentDetails);
        const d = yield call(res.json.bind(res));
        newPaymentDetails = d
        if (res.status !== 200) {
          yield put(updateUserError(d));
          //return;
        };
      }
      if(action.params.phone.addPhone.length > 0){
        let { addPhone } = action.params.phone;
        yield all(addPhone.map(phone => put(addPhoneRequest(phone))));
      }
      if(action.params.phone.editPhone.length > 0){
        let { editPhone } = action.params.phone;
        yield all(editPhone.map(({ id, ...phone }) => put(updatePhoneRequest({phone, id}))));;
      }
      if(action.params.phone.deletePhone.length > 0){
        let { deletePhone } = action.params.phone;
        yield all(deletePhone.map(phone => put(deletePhoneRequest({id: phone.id}))));
      }
      if(action.params.salary.deleteSalary.length > 0) {
        let { deleteSalary } = action.params.salary;
        yield all(deleteSalary.map(salary => put(deleteSalaryRequest({id: salary.id}))));
      }
      if(action.params.salary.addSalary.length > 0) {
        let { addSalary } = action.params.salary;
        yield all(addSalary.map(salary => put(addSalaryRequest(salary))));
      }
      if(action.params.salary.editSalary.length > 0){
        let { editSalary } = action.params.salary;
        yield all(editSalary.map(({id, ...salary}) => put(updateSalaryRequest({salary, id}))));
      }
      if(action.params.typeSpecialist.deleteTypeSpecialist.length > 0) {
        let { deleteTypeSpecialist } = action.params.typeSpecialist;
        yield all(deleteTypeSpecialist.map(specialization => put(deleteSpecializationRequest({id: specialization.id}))));
      }
      if(action.params.typeSpecialist.addTypeSpecialist.length > 0) {
        let { addTypeSpecialist } = action.params.typeSpecialist;
        yield all(addTypeSpecialist.map(specialization => put(addSpecializationRequest(specialization))));
      }
      if(action.params.typeSpecialist.editTypeSpecialist.length > 0){
        let { editTypeSpecialist } = action.params.typeSpecialist;
        yield all(editTypeSpecialist.map(({id, ...specialization}) => put(updateSpecializationRequest({specialization, id}))));
      }
      if(action.params.department.deleteDepartment.length > 0) {
        let { deleteDepartment } = action.params.department;
        yield all(deleteDepartment.map(department => put(deleteUserDepartmentRequest({id: department.id}))));
      }
      if(action.params.department.addDepartment.length > 0) {
        let { addDepartment } = action.params.department;
        yield all(addDepartment.map(department => put(addUserDepartmentRequest(department))));
      }
      if(action.params.department.editDepartment.length > 0){
        let { editDepartment } = action.params.department;
        yield all(editDepartment.map(({id, ...department}) => put(updateUserDepartmentRequest({department, id}))));
      }
      if(action.params.certification.addCertification.length > 0) {
        let { addCertification } = action.params.certification;
        yield all(addCertification.map(certification => put(addUserCertificationsRequest(certification))));
      }
      if(action.params.certification.deleteCertification.length > 0) {
        let { deleteCertification } = action.params.certification;
        yield all(deleteCertification.map(certification => put(deleteUserCertificationsRequest({id: certification.id}))));
      }
      if(action.params.certification.editCertification.length > 0){
        let { editCertification } = action.params.certification;
        yield all(editCertification.map(({id, ...certification}) => put(updateUserCertificationsRequest({certification, id}))));
      }
      // const [ certification ] = yield all([call(fetchCertification, { filter: { id }})])
      yield put(updateUserSuccess({...{...data, referralUserId: (!!data.referralUser.id && data.referralUser.id) || null}, paymentDetails: newPaymentDetails}));
    } else {
      yield put(updateUserError(data));
    }
  } catch (error) {
    const data = {code: 500, userMessage: 'Internal Server Error'};
    yield put(updateUserError(data));
  }
}

export function* watchUpdateUserRequest() {

  yield takeLatest(UPDATE_USER_TYPES.REQUEST, updateUser);
}
