import { FETCH_EVENTS_TYPES } from '../constants/types';

const initialState = {
    loading: null,
    error: null,
    event: null
};

const events = (state=initialState, action) => {
    switch (action.type) {
        case FETCH_EVENTS_TYPES.REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case FETCH_EVENTS_TYPES.RESULT:
            return {
                ...state,
                event: action.data,
                loading: false,
                error: null,
            };
        case FETCH_EVENTS_TYPES.ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        default:
            return state;
    }
};
export default events;