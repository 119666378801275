import {
    ADD_LEAD_TYPES,
    CLEAR_LEAD_ERROR,
    FETCH_LEADS_TYPES,
    FETCH_LEAD_TYPES,
    UPDATE_LEAD_TYPES
} from '../constants/types';

export function fetchLeadsRequest(params){
    return {
        type: FETCH_LEADS_TYPES.REQUEST,
        params
    }
}

export function fetchLeadsResult(data){
    return {
        type: FETCH_LEADS_TYPES.RESULT,
        data
    }
}

export function fetchLeadsError(error) {
    return{
        type: FETCH_LEADS_TYPES.ERROR,
        error
    }
}

export function fetchLeadRequest(params){
    return {
        type: FETCH_LEAD_TYPES.REQUEST,
        params
    }
}

export function fetchLeadResult(data){
    return {
        type: FETCH_LEAD_TYPES.RESULT,
        data
    }
}

export function fetchLeadError(error) {
    return{
        type: FETCH_LEAD_TYPES.ERROR,
        error
    }
}

export function updateLeadRequest(params){
    return {
        type: UPDATE_LEAD_TYPES.REQUEST,
        params
    }
}

export function updateLeadSuccess(data){
    return {
        type: UPDATE_LEAD_TYPES.SUCCESS,
        data
    }
}

export function updateLeadError(error) {
    return {
        type: UPDATE_LEAD_TYPES.ERROR,
        error
    }
}

export function addLeadRequest(params){
    return {
        type: ADD_LEAD_TYPES.REQUEST,
        params
    }
}

export function addLeadSuccess(data) {
    return {
        type: ADD_LEAD_TYPES.SUCCESS,
        data
    }
}

export function addLeadError(error) {
    return {
        type: ADD_LEAD_TYPES.ERROR,
        error
    }
}

export function clearLeadError() {
    return {
        type: CLEAR_LEAD_ERROR
    }
}