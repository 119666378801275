import { FETCH_EMPLOYEES_TYPES } from "../constants/types";

export function fetchEmployeesRequest(params){
    return {
        type: FETCH_EMPLOYEES_TYPES.REQUEST,
        params
    }
}

export function fetchEmployeesResult(data){
    return {
        type: FETCH_EMPLOYEES_TYPES.RESULT,
        data
    }
}

export function fetchEmployeesError(error) {
    return{
        type: FETCH_EMPLOYEES_TYPES.ERROR,
        error
    }
}