import { FETCH_REDMINE_USERS_TYPES } from '../constants/types';

const initialState = {
    loading: null,
    error: null,
    redmineUser: null
};

const redmineUsers = (state=initialState, action) => {
    switch (action.type) {
        case FETCH_REDMINE_USERS_TYPES.REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case FETCH_REDMINE_USERS_TYPES.RESULT:
            return {
                ...state,
                redmineUser: action.data,
                loading: false,
                error: null,
            };
        case FETCH_REDMINE_USERS_TYPES.ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        default:
            return state;
    }
};
export default redmineUsers;
