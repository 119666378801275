import {call, put, takeEvery} from 'redux-saga/effects';
import {FETCH_ENTITIES_TYPES} from 'constants/types';

import {fetchEntitiesResult, fetchEntitiesError} from 'actions/entity';
import { authHeader } from '../../helpers/authHeader';
import moment from 'moment';
moment.locale('ru');

function isMultiSort(sort) {
    if(sort){
        const {sortName, sortOrder} = sort;
        let sortied = sortName.map((name,i) => {
            return {
                "property": name,
                "order": sortOrder[i]
            }
        });
        return sortied;
    }
}

export function* getEntityList(action) {
    const param = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({
            pagination: action.params.pagination,
            sort: isMultiSort(action.params.sort),
            filter: action.params.filter,
            //fields: action.params.fields
        })
    };
    try{
        const response = yield call(fetch, `${process.env.REACT_APP_API_URL}/business/legal-entity/list`, param);
        const data = yield call(response.json.bind(response));
        if (response.status === 200) {
            yield put(fetchEntitiesResult(data));
        } else {
            yield put(fetchEntitiesError(data));
        }
    } catch (error) {
        const data = {code: 500, userMessage: 'Internal Server Error'};
        yield put(fetchEntitiesError(data));
    }
}

export function* watchLoadEntitiesRequest() {

    yield takeEvery(FETCH_ENTITIES_TYPES.REQUEST, getEntityList);
}
