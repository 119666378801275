import {call, put, takeEvery} from 'redux-saga/effects';
import {DELETE_PHONE_TYPES} from 'constants/types';

import {deletePhoneSuccess, deletePhoneError} from 'actions/phone';
import { authHeader } from '../../helpers/authHeader';

export function* deletePhone(action) {
  //console.log("DELETE PHONE", action);
  const param = {
    method: 'DELETE',
    headers: authHeader()
  };
  try{
    const response = yield call(fetch, `${process.env.REACT_APP_API_URL}/user/phone/${action.params.id}`, param);
    const data = yield call(response.json.bind(response));
    if (response.status === 200) {
      yield put(deletePhoneSuccess(data));
    } else if (response.status === 400) {
      yield put(deletePhoneError(data));
    } else if (response.status === 401){
      yield put(deletePhoneError(data));
    } else if (response.status === 404){
      yield (deletePhoneError(data));
    }
  } catch (error) {
    const data = {code: 500, userMessage: 'Internal Server Error'};
    yield put(deletePhoneError(data));
  }
}

export function* watchDeletePhoneRequest() {
  yield takeEvery(DELETE_PHONE_TYPES.REQUEST, deletePhone);
}

