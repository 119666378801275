import { call, put, takeEvery } from 'redux-saga/effects';
import { FETCH_LEAD_TYPES } from 'constants/types';
import { fetchLeadResult, fetchLeadError } from 'actions/lead';
import { authHeader } from '../../helpers/authHeader';

export function* getLead(action) {
    const param = {
        method: 'GET',
        headers: authHeader(),
    };
    try{
        const response = yield call(fetch, `${process.env.REACT_APP_API_URL}user/${action.params}`, param);
        const data = yield call(response.json.bind(response));
        if (response.status === 200) {
            yield put(fetchLeadResult(data));
        } else {
            yield put(fetchLeadError(data));
        }
    } catch (error) {
        const data = {code: 500, userMessage: 'Internal Server Error'};
        yield put(fetchLeadError(data));
    }
}

export function* watchLoadLeadRequest() {
    yield takeEvery(FETCH_LEAD_TYPES.REQUEST, getLead);
}
