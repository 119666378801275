import {FETCH_REFERRAL_USER_TYPES} from '../constants/types';

const initialState = {
  referralUser: null,
  loading: null,
  error: null
};

const referralUser = (state=initialState, action) => {
  switch (action.type) {
    case FETCH_REFERRAL_USER_TYPES.REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_REFERRAL_USER_TYPES.RESULT:
      return {
        ...state,
        referralUser: action.data,
        loading: false,
        error: null,
      };
    case FETCH_REFERRAL_USER_TYPES.ERROR:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    default:
      return state;
  }
}
export default referralUser;
