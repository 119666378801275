import {
    FETCH_DEPARTMENTS_TYPES,
    FETCH_DEPARTMENT_TYPES,
    ADD_USER_DEPARTMENT_TYPES,
    UPDATE_USER_DEPARTMENT_TYPES,
    DELETE_USER_DEPARTMENT_TYPES,
    ADD_DEPARTMENT_TYPES,
    UPDATE_DEPARTMENT_TYPES,
    DELETE_DEPARTMENT_TYPES,
    CLEAR_DEPARTMENT_ERROR
} from '../constants/types';

export function fetchDepartmentsRequest(params){
    return {
        type: FETCH_DEPARTMENTS_TYPES.REQUEST,
        params
    }
}

export function fetchDepartmentsResult(data, params){
    return {
        type: FETCH_DEPARTMENTS_TYPES.RESULT,
        data,
        params,
    }
}

export function fetchDepartmentsError(error) {
    return{
        type: FETCH_DEPARTMENTS_TYPES.ERROR,
        error
    }
}

export function fetchDepartmentRequest(params){
    return {
        type: FETCH_DEPARTMENT_TYPES.REQUEST,
        params
    }
}

export function fetchDepartmentResult(data){
    return {
        type: FETCH_DEPARTMENT_TYPES.RESULT,
        data
    }
}

export function fetchDepartmentError(error) {
    return{
        type: FETCH_DEPARTMENT_TYPES.ERROR,
        error
    }
}

export function addUserDepartmentRequest(params){
    return {
        type: ADD_USER_DEPARTMENT_TYPES.REQUEST,
        params
    }
}

export function addUserDepartmentSuccess(data){
    return {
        type: ADD_USER_DEPARTMENT_TYPES.SUCCESS,
        data
    }
}

export function addUserDepartmentError(error) {
    return{
        type: ADD_USER_DEPARTMENT_TYPES.ERROR,
        error
    }
}

export function updateUserDepartmentRequest(params) {
    return {
        type: UPDATE_USER_DEPARTMENT_TYPES.REQUEST,
        params
    }
}

export function updateUserDepartmentSuccess(data) {
    return {
        type: UPDATE_USER_DEPARTMENT_TYPES.SUCCESS,
        data
    }
}

export function updateUserDepartmentError(error) {
    return {
        type: UPDATE_USER_DEPARTMENT_TYPES.ERROR,
        error
    }
}

export function deleteUserDepartmentRequest(params) {
    return {
        type: DELETE_USER_DEPARTMENT_TYPES.REQUEST,
        params
    }
}

export function deleteUserDepartmentSuccess(data) {
    return {
        type: DELETE_USER_DEPARTMENT_TYPES.SUCCESS,
        data
    }
}

export function deleteUserDepartmentError(error) {
    return {
        type: DELETE_USER_DEPARTMENT_TYPES.ERROR,
        error
    }
}

export function addDepartmentRequest(params){
    return {
        type: ADD_DEPARTMENT_TYPES.REQUEST,
        params
    }
}

export function addDepartmentSuccess(data){
    return {
        type: ADD_DEPARTMENT_TYPES.SUCCESS,
        data
    }
}

export function addDepartmentError(error) {
    return{
        type: ADD_DEPARTMENT_TYPES.ERROR,
        error
    }
}

export function updateDepartmentRequest(params) {
    return {
        type: UPDATE_DEPARTMENT_TYPES.REQUEST,
        params
    }
}

export function updateDepartmentSuccess(data) {
    return {
        type: UPDATE_DEPARTMENT_TYPES.SUCCESS,
        data
    }
}

export function updateDepartmentError(error) {
    return {
        type: UPDATE_DEPARTMENT_TYPES.ERROR,
        error
    }
}

export function deleteDepartmentRequest(params) {
    return {
        type: DELETE_DEPARTMENT_TYPES.REQUEST,
        params
    }
}

export function deleteDepartmentSuccess(data) {
    return {
        type: DELETE_DEPARTMENT_TYPES.SUCCESS,
        data
    }
}

export function deleteDepartmentError(error) {
    return {
        type: DELETE_DEPARTMENT_TYPES.ERROR,
        error
    }
}

export function clearDepartmentError() {
    return {
        type: CLEAR_DEPARTMENT_ERROR
    }
}
