import { FETCH_DASHBOARD_PROJECTS_MANAGERS_TYPES } from '../constants/types';

const initialState = {
    dashboardProjectsManagers: null,
    loading: null,
    error: null
};

const dashboardProjectsManagers = (state=initialState, action) => {
    switch (action.type) {
        case FETCH_DASHBOARD_PROJECTS_MANAGERS_TYPES.REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case FETCH_DASHBOARD_PROJECTS_MANAGERS_TYPES.RESULT:
            return {
                ...state,
                loading: false,
                error: null,
                dashboardProjectsManagers: action.data
            };
        case FETCH_DASHBOARD_PROJECTS_MANAGERS_TYPES.ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        default:
            return state;
    }
};
export default dashboardProjectsManagers;