import  { employeesData } from './usersData';

const initialState = employeesData();
const user = (state=initialState, action) => {
  switch (action.type) {
    case 'ADD_USER':
      return [action.data, ...state];
    case 'EDIT_USER':
      return [...state, state.map(u => u.id === action.data ? u.editing = true:false)];
    case 'UPDATE_USER':
      return state.map(user => {
        if(user.id === action.data.id){
          user = action.data
        }
        return user;
      });
    case 'DELETE_USER':
      return state.filter(user => user.id !== action.data);
    default:
      return state;
  }
};
export default user;
