import {call, put, takeEvery} from 'redux-saga/effects';
import {UPDATE_LEAD_TYPES} from '../../constants/types';

import {updateLeadSuccess, updateLeadError} from '../../actions/lead';
import { authHeader } from '../../helpers/authHeader';

export function* updateLead(action) {
    const param = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(action.params.lead)
    };
    try{
        const response = yield call(fetch, `${process.env.REACT_APP_API_URL}/user/${action.params.id}`, param);
        const data = yield call(response.json.bind(response));
        if (response.status === 200) {
            yield put(updateLeadSuccess(data));
        } else {
            yield put(updateLeadError(data));
        }
    } catch (error) {
        const data = {code: 500, userMessage: 'Internal Server Error'};
        yield put(updateLeadError(data));
    }
}

export function* watchUpdateLeadRequest() {
    yield takeEvery(UPDATE_LEAD_TYPES.REQUEST, updateLead);
}
