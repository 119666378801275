import { call, put, takeEvery } from 'redux-saga/effects';
import { DELETE_USER_CERTIFICATION_TYPES } from 'constants/certifications/certificationsUser';

import { deleteUserCertificationsSuccess, deleteUserCertificationsError } from 'actions/certification';
import { authHeader } from 'helpers/authHeader';

export function* deleteUserCertification(action) {
    const param = {
        method: 'DELETE',
        headers: authHeader()
    };
    try{
        const response = yield call(fetch, `${process.env.REACT_APP_API_URL}/user/certification/${action.params.id}`, param);
        const data = yield call(response.json.bind(response));
        if (response.status === 200) {
            yield put(deleteUserCertificationsSuccess({...data, id: action.params.id}));
        } else {
            yield put(deleteUserCertificationsError(data));
        }
    } catch (error) {
        const data = {code: 500, userMessage: 'Internal Server Error'};
        yield put(deleteUserCertificationsError(data));
    }
}

export function* watchDeleteUserCertificationRequest() {
    yield takeEvery(DELETE_USER_CERTIFICATION_TYPES.REQUEST, deleteUserCertification);
}
