import {activeColumns, hiddenColumns} from './LeadTableData';

const initialValue = {
    activeColumns: activeColumns,
    hiddenColumns: hiddenColumns
}

const settingLead = (state=initialValue, action) => {
    switch (action.type) {
        case 'CHANGE_COLUMNS_LEAD':
            return { ...state, activeColumns: action.activeColumns, hiddenColumns: action.hiddenColumns};
        case 'CHANGE_COLUMN_ACTIVE_LEAD':
            return { ...state, activeColumns: action.activeColumns};
        case 'CHANGE_COLUMN_HIDDEN_LEAD':
            return { ...state, hiddenColumns: action.hiddenColumns};
        default:
            return state;
    }
}
export default settingLead;