import { call, put, takeEvery } from 'redux-saga/effects';
import { FETCH_MASQUERADE_AUTH_TYPES } from 'constants/masquerade/masquerade';
import { fetchMasqueradeAuthSuccess, fetchMasqueradeAuthError } from 'actions/masquerade/masquerade';
import { authHeader } from 'helpers/authHeader';

export function* masqueradeAuth(action) {
    const param = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(action.params.id)
    };
    try{
        const response = yield call(fetch, `${process.env.REACT_APP_API_URL}user/masquerade/${action.params.id}/auth`, param);
        const data = yield call(response.json.bind(response));
        if(response.status === 200) {
            const flag = true;
            const masquerade = JSON.stringify(flag);
            localStorage.setItem("masquerade", masquerade);
            yield put(fetchMasqueradeAuthSuccess(flag));
            window.location.reload();
        } else {
            console.error("Ошибка авторизации: ", data)
            yield put(fetchMasqueradeAuthError(data));
        }
    } catch (error) {
        const data = {code: 500, userMessage: 'Internal Server Error'};
        yield put(fetchMasqueradeAuthError(data));
    }
}

export function* watchLoadMasqueradeAuthRequest() {
    yield takeEvery(FETCH_MASQUERADE_AUTH_TYPES.REQUEST, masqueradeAuth);
}
