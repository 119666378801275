import { SET_DASHBOARD_PAYROLL_TYPES } from '../constants/types';

const initialState = {
    dashboardPayroll: null,
    loading: null,
    error: null
};

const dashboardPayroll = (state=initialState, action) => {
    switch (action.type) {
        case SET_DASHBOARD_PAYROLL_TYPES.REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case SET_DASHBOARD_PAYROLL_TYPES.RESULT:
            return {
                ...state,
                loading: false,
                error: null,
                dashboardPayroll: action.data
            };
        case SET_DASHBOARD_PAYROLL_TYPES.ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        default:
            return state;
    }
};
export default dashboardPayroll;