import {FETCH_REFERRAL_USER_TYPES} from '../constants/types';

export function fetchReferralUserRequest(params) {
  return {
    type: FETCH_REFERRAL_USER_TYPES.REQUEST,
    params
  }
}

export function fetchReferralUserResult(data) {
  return {
    type: FETCH_REFERRAL_USER_TYPES.RESULT,
    data
  }
}

export function fetchReferralUserError(error){
  return {
    type: FETCH_REFERRAL_USER_TYPES.ERROR,
    error
  }
}
