import { call, put, takeEvery } from 'redux-saga/effects';
import { FETCH_LOGIN_TYPES } from 'constants/types';
import { fetchLoginSuccess, fetchLoginError } from 'actions/login';

export function* login(action) {
    const param = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(action.params.user)
    };
    try{
        const response = yield call(fetch, `${process.env.REACT_APP_API_URL}/auth`, param);
        const data = yield call(response.json.bind(response));
        if(response.status === 200) {
            yield put(fetchLoginSuccess(data));
            const user = JSON.stringify(data);
            localStorage.setItem("user", user);
        } else {
            yield put(fetchLoginError(data));
        }
        /*
        if(action.params.login === 'admin' && action.params.password === '123qweRTY'){
            const data = true;
            yield put(fetchLoginSuccess(data));
            const auth = JSON.stringify(data);
            localStorage.setItem("auth", auth);
        } else {
            const data = "Неверный логин или пароль.";
            yield put(fetchLoginError(data));
        }
         */
    } catch (error) {
        console.log(error);
        const data = {code: 500, userMessage: 'Internal Server Error'};
        yield put(fetchLoginError(data));
    }
}

export function* watchLoadLoginRequest() {
    yield takeEvery(FETCH_LOGIN_TYPES.REQUEST, login);
}
