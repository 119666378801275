import {call, put, takeEvery} from 'redux-saga/effects';
import {FETCH_REFERRAL_USER_TYPES} from 'constants/types';

import {fetchReferralUserResult, fetchReferralUserError} from 'actions/referralUser';
import { authHeader } from '../../helpers/authHeader';

const arg = (page, params) => {
  if(params !== undefined){
    return {
      pagination: {page: page, limit: 100},
      filter: {fullName: params.filter.fullName, "isActive": true}
    }
  } else {
    return {
      pagination: {page: page, limit: 10}
    }
  }
}

export function* fetchRefferalUsers(params) {
  const param = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      pagination: params.pagination,
      //sort: isMultiSort(action.params.sort),
      filter: params.filter,
      fields: ["id", "firstName", "lastName", "patronymic"]
    })
  };
  try{
    const response = yield call(fetch, `${process.env.REACT_APP_API_URL}/user/list`, param);
    const data = yield call(response.json.bind(response));
    if (response.status === 200) {
      return data
    } else {
      yield put(fetchReferralUserResult(data));
    }
  } catch (error) {
    const data = {code: 500, userMessage: 'Internal Server Error'};
    yield put(fetchReferralUserError(data));
  }
}

export function* getRefferalUsersList(action) {
  try{
    const data = yield call(fetchRefferalUsers, arg(1, action.params));
    let {
        result: project,
        pagination: {
            count: countPage,
            // limit: limitPage,
        },
    } = data;
    if (countPage > project.length) {
      /*
      const pageCountProject =  Math.ceil(countPage/limitPage) + 1;
      let pages = Array.from(Array(pageCountProject).keys());
      pages.splice(0, 2);
      const [ ...results] = yield all(pages.map(page => call(fetchProjects, arg(page, action.params))));
      project = results.reduce((res, v) => [ ...res, ...v.result ], project);
       */
      yield put(fetchReferralUserResult(project));
    } else {
      yield put(fetchReferralUserError(project));
    }
  } catch (error) {
    const data = {code: 500, userMessage: 'Internal Server Error'};
    yield put(fetchReferralUserError(data));
  }
}

export function* watchLoadReferralUserRequest() {
  yield takeEvery(FETCH_REFERRAL_USER_TYPES.REQUEST, getRefferalUsersList);
}
