import {FETCH_INCOMES_TYPES} from '../constants/types';

const initialState = {
  loading: null,
  error: null,
  income: null
};

const incomes = (state=initialState, action) => {
  switch (action.type) {
    case FETCH_INCOMES_TYPES.REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_INCOMES_TYPES.RESULT:
      return {
        ...state,
        income: action.data,
        loading: false,
        error: null,
      };
    case FETCH_INCOMES_TYPES.ERROR:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    default:
      return state;
  }
};
export default incomes;
