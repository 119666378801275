import { call, put, takeEvery } from 'redux-saga/effects';
import moment from 'moment';

import {FETCH_CALENDAR_TYPES} from 'constants/types';
import {fetchCalendarResult, fetchCalendarError} from 'actions/calendar';
import { authHeader } from '../../helpers/authHeader';
moment.locale('ru');

export function* getEventList({
    params: {
        filter,
        sort,
        pagination,
    },
}) {
    const {
        start,
        end,
        ...restFilters
    } = filter || {};

    const isCyclicRequest = !pagination || pagination.limit === -1 || !Number.isInteger(pagination.limit) || pagination.limit > 100;

    const result = {
        list: [],
        pagination: {
            page: (isCyclicRequest ? 1 : pagination.page) - 1,
            limit: isCyclicRequest ? 100 : pagination.limit,
            count: isCyclicRequest ? 1000 : pagination.limit,
        },
        error: null,
    };

    do {
        try {
            const response = yield call(fetch, `${process.env.REACT_APP_API_URL}/user/day-off/list`, {
                method: 'POST',
                headers: authHeader(),
                body: JSON.stringify({
                    sort: [{
                        property: sort ? sort.property : 'start',
                        order: sort ? sort.order : 'asc',
                    }],
                    filter: {
                        ...(restFilters || {}),
                        period: start && end ? [start, end] : undefined,
                    },
                    pagination: {
                        page: result.pagination.page + 1,
                        limit: result.pagination.limit,
                    },
                }),
            });
            const data = yield call(response.json.bind(response));
            if (response.status === 200) {
                result.list = [...result.list, ...data.result];
                result.pagination = data.pagination && Number.isInteger(data.pagination.count)
                    ? {
                        ...result.pagination,
                        ...({
                            page: result.pagination.page + 1,
                            count: data.pagination.count,
                        }),
                    }
                    : {
                        ...result.pagination,
                        page: result.pagination.page + 1,
                        count: data.result.length < result.pagination.limit
                            ? result.list.length
                            : result.list.length + 1,
                    };
                continue;
            } else {
                throw response;
            }
        } catch (error) {
            const data = {
                code: error.code || 500,
                userMessage: error.userMessage || 'Internal Server Error',
            };
            result.error = data;
            yield put(fetchCalendarError(data));
            break;
        }
    } while (isCyclicRequest && result.list.length < result.pagination.count && !result.error);

    if (!result.error) {
        yield put(fetchCalendarResult({
            list: result.list,
            pagination: isCyclicRequest
                ? { page: 1, count: result.list.length }
                : result.pagination,
        }));
    }
}

export function* watchLoadCalendarRequest() {
    yield takeEvery(FETCH_CALENDAR_TYPES.REQUEST, getEventList);
}
