import { call, put, takeEvery } from 'redux-saga/effects';
import {ADD_LEAD_TYPES} from 'constants/types';

import {addLeadSuccess, addLeadError} from 'actions/lead';
import { authHeader } from '../../helpers/authHeader';

export function* addLead(action) {
    const param = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(action.params.lead)
    };
    try{
        const response = yield call(fetch, `${process.env.REACT_APP_API_URL}/user`, param);
        const data = yield call(response.json.bind(response));
        if (response.status === 200) {
            window.history.back();
            yield put(addLeadSuccess(data));
        } else {
            yield put(addLeadError(data));
        }
    } catch (error) {
        const data = {code: 500, userMessage: 'Internal Server Error'};
        yield put(addLeadError(data));
    }
}

export function* watchAddLeadRequest() {

    yield takeEvery(ADD_LEAD_TYPES.REQUEST, addLead);
}
