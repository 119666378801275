import {call, put, takeEvery} from 'redux-saga/effects';
import {FETCH_DEPARTMENT_TYPES} from 'constants/types';

import {fetchDepartmentResult, fetchDepartmentError} from 'actions/department';
import {authHeader} from '../../helpers/authHeader';


export function* getDepartment(action) {
    const param = {
        method: 'GET',
        headers: authHeader(),
    };
    try{
        const response = yield call(fetch, `${process.env.REACT_APP_API_URL}/department/${action.params}`, param);
        const data = yield call(response.json.bind(response));
        if (response.status === 200) {
            yield put(fetchDepartmentResult(data));
        } else {
            yield put(fetchDepartmentError(data));
        }
    } catch (error) {
        const data = {code: 500, userMessage: 'Internal Server Error'};
        yield put(fetchDepartmentError(data));
    }
}

export function* watchLoadDepartmentRequest() {
    yield takeEvery(FETCH_DEPARTMENT_TYPES.REQUEST, getDepartment);
}
