import { call, put, takeEvery } from 'redux-saga/effects';
import { FETCH_MASQUERADE_ROLLBACK_TYPES } from 'constants/masquerade/masquerade';
import { fetchMasqueradeRollbackSuccess, fetchMasqueradeRollbackError } from 'actions/masquerade/masquerade';
import { authHeader } from 'helpers/authHeader';

export function* masqueradeRollback(action) {
    const param = {
        method: 'POST',
        headers: authHeader()
    };
    try{
        const response = yield call(fetch, `${process.env.REACT_APP_API_URL}/user/masquerade/rollback`, param);
        const data = yield call(response.json.bind(response));
        if(response.status === 200) {
            console.log("data", data)
            const flag = false;
            const masquerade = JSON.stringify(flag);
            localStorage.setItem("masquerade", masquerade);
            yield put(fetchMasqueradeRollbackSuccess(flag));
            window.location.reload();
        } else {
            console.error("Ошибка log out: ", data)
            yield put(fetchMasqueradeRollbackError(data));
        }
    } catch (error) {
        const data = {code: 500, userMessage: 'Internal Server Error'};
        yield put(fetchMasqueradeRollbackError(data));
    }
}

export function* watchLoadMasqueradeRollbackRequest() {
    yield takeEvery(FETCH_MASQUERADE_ROLLBACK_TYPES.REQUEST, masqueradeRollback);
}
