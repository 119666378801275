import {call, put, takeEvery} from 'redux-saga/effects';
import {FETCH_INCOME_TYPES} from 'constants/types';

import {fetchIncomeResult, fetchIncomeError} from 'actions/income';
import { authHeader } from '../../helpers/authHeader';

export function* getIncome(action) {
    const param = {
        method: 'GET',
        headers: authHeader(),
    };
    try{
        const response = yield call(fetch, `${process.env.REACT_APP_API_URL}/finance/income/${action.params}`, param);
        const data = yield call(response.json.bind(response));
        if (response.status === 200) {
            yield put(fetchIncomeResult(data));
        } else {
            yield put(fetchIncomeError(data));
        }
    } catch (error) {
        const data = {code: 500, userMessage: 'Internal Server Error'};
        yield put(fetchIncomeError(data));
    }
}

export function* watchLoadIncomeRequest() {
    yield takeEvery(FETCH_INCOME_TYPES.REQUEST, getIncome);
}
