import {FETCH_USERS_TYPES} from '../constants/types';

export function fetchUsersRequest(params){
  return {
    type: FETCH_USERS_TYPES.REQUEST,
    params
  }
}

export function fetchUsersResult(data){
  return {
    type: FETCH_USERS_TYPES.RESULT,
    data
  }
}

export function fetchUsersError(error) {
  return{
    type: FETCH_USERS_TYPES.ERROR,
    error
  }
}
