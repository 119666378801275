import {call, put, takeEvery} from 'redux-saga/effects';

import {addEntitySuccess, addEntityError} from 'actions/entity';
import { authHeader } from '../../helpers/authHeader';
import {ADD_ENTITY_TYPES} from "../../constants/types";

export function* addEntity(action) {
    const param = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(action.params.entity)
    };
    try{
        const response = yield call(fetch, `${process.env.REACT_APP_API_URL}/business/legal-entity`, param);
        const data = yield call(response.json.bind(response));
        if (response.status === 200) {
            window.history.back();
            yield put(addEntitySuccess(data));
        } else {
            yield put(addEntityError(data));
        }
    } catch (error) {
        const data = {code: 500, userMessage: 'Internal Server Error'};
        yield put(addEntityError(data));
    }
}

export function* watchAddEntityRequest() {
    yield takeEvery(ADD_ENTITY_TYPES.REQUEST, addEntity);
}
