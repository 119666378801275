import { FETCH_DASHBOARD_USER_PROFIT_TYPES } from '../constants/types';

const initialState = {
    dashboardUserProfit: null,
    loading: null,
    error: null
};

const dashboardUserProfit = (state=initialState, action) => {
    switch (action.type) {
        case FETCH_DASHBOARD_USER_PROFIT_TYPES.REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case FETCH_DASHBOARD_USER_PROFIT_TYPES.RESULT:
            return {
                ...state,
                loading: false,
                error: null,
                dashboardUserProfit: action.data
            };
        case FETCH_DASHBOARD_USER_PROFIT_TYPES.ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        default:
            return state;
    }
};
export default dashboardUserProfit;