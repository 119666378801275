import { put, takeEvery } from 'redux-saga/effects';
import { FETCH_LOGOUT_TYPES } from 'constants/types';
import { fetchLogoutSuccess, fetchLogoutError } from 'actions/logout';

export function* logout(action) {
    if(window.location.reload){
        localStorage.clear();
    }
    try{
        const data = null;
        yield put(fetchLogoutSuccess(data));
    } catch (error) {
        const data = {code: 500, userMessage: 'Internal Server Error'};
        yield put(fetchLogoutError(data));
    }
}

export function* watchLoadLogoutRequest() {
    yield takeEvery(FETCH_LOGOUT_TYPES.REQUEST, logout);
}
