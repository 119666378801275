import {FETCH_USERS_TYPES} from '../constants/types'

const initialState = {
  users: null,
  loading: null,
  error: null
};

const userList = (state=initialState, action) => {
  switch (action.type) {
    case FETCH_USERS_TYPES.REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_USERS_TYPES.RESULT:
      return {
        ...state,
        users: action.data,
        loading: false,
        error: null,
      };
    case FETCH_USERS_TYPES.ERROR:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    default:
      return state;
  }
};
export default userList;
