import { call, put, takeEvery } from 'redux-saga/effects';
import { FETCH_WELCOME_LETTER_TYPES } from 'constants/welcomеletter/welcomeletter';

import { fetchWelcomeLetterResult, fetchWelcomeLetterError } from 'actions/welcomeletter/welcomeletter';
import { authHeader } from 'helpers/authHeader';

export function* getWelcomeLetter(action) {
    const param = {
        method: 'GET',
        headers: authHeader(),
    };
    try{
        const response = yield call(fetch, `${process.env.REACT_APP_API_URL}/user/settings/welcome-letter`, param);
        const data = yield call(response.json.bind(response));
        if (response.status === 200) {
            yield put(fetchWelcomeLetterResult(data));
        } else {
            yield put(fetchWelcomeLetterError(data));
        }
    } catch (error) {
        const data = {code: 500, userMessage: 'Internal Server Error'};
        yield put(fetchWelcomeLetterError(data));
    }
}

export function* watchLoadWelcomeLetterRequest() {
    yield takeEvery(FETCH_WELCOME_LETTER_TYPES.REQUEST, getWelcomeLetter);
}
