import {call, put, takeEvery} from 'redux-saga/effects';
import {DELETE_PAYOUT_TYPES} from 'constants/types';

import {deletePayoutSuccess, deletePayoutError} from 'actions/payout';
import { authHeader } from '../../helpers/authHeader';

export function* deletePayout(action) {
    const param = {
        method: 'DELETE',
        headers: authHeader()
    };
    try{
        const response = yield call(fetch, `${process.env.REACT_APP_API_URL}/finance/expense/${action.params.id}`, param);
        const data = yield call(response.json.bind(response));
        if (response.status === 200) {
            window.history.back();
            yield put(deletePayoutSuccess(data));
        } else {
            yield put(deletePayoutError(data));
        }
    } catch (error) {
        const data = {code: 500, userMessage: 'Internal Server Error'};
        yield put(deletePayoutError(data));
    }
}

export function* watchDeletePayoutRequest() {
    yield takeEvery(DELETE_PAYOUT_TYPES.REQUEST, deletePayout);
}

