import {FETCH_PROJECTS_TYPES, FETCH_PROJECT_TYPES} from '../constants/types';

export function fetchProjectsRequest(params){
    return {
        type: FETCH_PROJECTS_TYPES.REQUEST,
        params
    }
}

export function fetchProjectsResult(data){
    return {
        type: FETCH_PROJECTS_TYPES.RESULT,
        data
    }
}

export function fetchProjectsError(error) {
    return{
        type: FETCH_PROJECTS_TYPES.ERROR,
        error
    }
}

export function fetchProjectRequest(params){
    return {
        type: FETCH_PROJECT_TYPES.REQUEST,
        params
    }
}

export function fetchProjectResult(data){
    return {
        type: FETCH_PROJECT_TYPES.RESULT,
        data
    }
}

export function fetchProjectError(error) {
    return{
        type: FETCH_PROJECT_TYPES.ERROR,
        error
    }
}