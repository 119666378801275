import {
    FETCH_ENTITIES_TYPES,
    FETCH_ENTITY_TYPES,
    ADD_ENTITY_TYPES,
    UPDATE_ENTITY_TYPES,
    CLEAR_ENTITY_ERROR
} from '../constants/types';

export function fetchEntitiesRequest(params){
    return {
        type: FETCH_ENTITIES_TYPES.REQUEST,
        params
    }
}

export function fetchEntitiesResult(data){
    return {
        type: FETCH_ENTITIES_TYPES.RESULT,
        data
    }
}

export function fetchEntitiesError(error) {
    return{
        type: FETCH_ENTITIES_TYPES.ERROR,
        error
    }
}

export function fetchEntityRequest(params){
    return {
        type: FETCH_ENTITY_TYPES.REQUEST,
        params
    }
}

export function fetchEntityResult(data){
    return {
        type: FETCH_ENTITY_TYPES.RESULT,
        data
    }
}

export function fetchEntityError(error) {
    return{
        type: FETCH_ENTITY_TYPES.ERROR,
        error
    }
}

export function updateEntityRequest(params){
    return {
        type: UPDATE_ENTITY_TYPES.REQUEST,
        params
    }
}

export function updateEntitySuccess(data){
    return {
        type: UPDATE_ENTITY_TYPES.SUCCESS,
        data
    }
}

export function updateEntityError(error) {
    return {
        type: UPDATE_ENTITY_TYPES.ERROR,
        error
    }
}

export function addEntityRequest(params){
    return {
        type: ADD_ENTITY_TYPES.REQUEST,
        params
    }
}

export function addEntitySuccess(data) {
    return {
        type: ADD_ENTITY_TYPES.SUCCESS,
        data
    }
}

export function addEntityError(error) {
    return {
        type: ADD_ENTITY_TYPES.ERROR,
        error
    }
}

export function clearEntityError() {
    return {
        type: CLEAR_ENTITY_ERROR
    }
}