import {
    activeColumns,
    hiddenColumns,
    setStorageActive as saveActiveColumns,
    setStorageHidden as saveHiddenColumns
} from './UserTableData';

const initialValue = {
  activeColumns: activeColumns,
  hiddenColumns: hiddenColumns
}

const settingUser = (state=initialValue, action) => {
  switch (action.type) {
    case 'CHANGE_COLUMNS_USER': {
      saveActiveColumns(action.activeColumns.map(({ dataField }) => dataField));
      return { ...state, activeColumns: action.activeColumns, hiddenColumns: action.hiddenColumns};
    }
    case 'CHANGE_COLUMN_ACTIVE_USER': {
      saveActiveColumns(action.activeColumns.map(({ dataField }) => dataField));
      return { ...state, activeColumns: action.activeColumns};
    }
    case 'CHANGE_COLUMN_HIDDEN_USER': {
      saveHiddenColumns(action.hiddenColumns.map(({ dataField }) => dataField));
      return { ...state, hiddenColumns: action.hiddenColumns};
    }
    default:
      return state;
  }
}
export default settingUser;
