import {
    FETCH_INCOMES_TYPES,
    FETCH_INCOME_TYPES,
    UPDATE_INCOME_TYPES,
    ADD_INCOME_TYPES,
    CLEAR_INCOME_ERROR,
    DELETE_INCOME_TYPES
} from '../constants/types';

export function fetchIncomesRequest(params){
    return {
        type: FETCH_INCOMES_TYPES.REQUEST,
        params
    }
}

export function fetchIncomesResult(data){
    return {
        type: FETCH_INCOMES_TYPES.RESULT,
        data
    }
}

export function fetchIncomesError(error) {
    return{
        type: FETCH_INCOMES_TYPES.ERROR,
        error
    }
}

export function fetchIncomeRequest(params){
    return {
        type: FETCH_INCOME_TYPES.REQUEST,
        params
    }
}

export function fetchIncomeResult(data){
    return {
        type: FETCH_INCOME_TYPES.RESULT,
        data
    }
}

export function fetchIncomeError(error) {
    return{
        type: FETCH_INCOME_TYPES.ERROR,
        error
    }
}

export function updateIncomeRequest(params){
    return {
        type: UPDATE_INCOME_TYPES.REQUEST,
        params
    }
}

export function updateIncomeSuccess(data){
    return {
        type: UPDATE_INCOME_TYPES.SUCCESS,
        data
    }
}

export function updateIncomeError(error) {
    return {
        type: UPDATE_INCOME_TYPES.ERROR,
        error
    }
}

export function addIncomeRequest(params){
    return {
        type: ADD_INCOME_TYPES.REQUEST,
        params
    }
}

export function addIncomeSuccess(data) {
    return {
        type: ADD_INCOME_TYPES.SUCCESS,
        data
    }
}

export function addIncomeError(error) {
    return {
        type: ADD_INCOME_TYPES.ERROR,
        error
    }
}

export function clearIncomeError() {
    return {
        type: CLEAR_INCOME_ERROR
    }
}

export function deleteIncomeRequest(params){
    return {
        type: DELETE_INCOME_TYPES.REQUEST,
        params
    }
}

export function deleteIncomeSuccess(data) {
    return {
        type: DELETE_INCOME_TYPES.SUCCESS,
        data
    }
}

export function deleteIncomeError(error) {
    return {
        type: DELETE_INCOME_TYPES.ERROR,
        error
    }
}