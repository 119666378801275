import {call, put, takeEvery} from 'redux-saga/effects';
import {FETCH_PAYOUT_TYPES} from 'constants/types';

import {fetchPayoutResult, fetchPayoutError} from 'actions/payout';
import { authHeader } from '../../helpers/authHeader';

export function* getPayout(action) {
    const param = {
        method: 'GET',
        headers: authHeader(),
    };
    try{
        const response = yield call(fetch, `${process.env.REACT_APP_API_URL}/finance/expense/${action.params}`, param);
        const data = yield call(response.json.bind(response));
        if (response.status === 200) {
            yield put(fetchPayoutResult(data));
        } else {
            yield put(fetchPayoutError(data));
        }
    } catch (error) {
        const data = {code: 500, userMessage: 'Internal Server Error'};
        yield put(fetchPayoutError(data));
    }
}

export function* watchLoadPayoutRequest() {
    yield takeEvery(FETCH_PAYOUT_TYPES.REQUEST, getPayout);
}
