import {FETCH_ACCOUNTS_TYPES} from '../constants/types';

const initialState = {
    loading: null,
    error: null,
    account: null
};

const accounts = (state=initialState, action) => {
    switch (action.type) {
        case FETCH_ACCOUNTS_TYPES.REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case FETCH_ACCOUNTS_TYPES.RESULT:
            return {
                ...state,
                account: action.data,
                loading: false,
                error: null,
            };
        case FETCH_ACCOUNTS_TYPES.ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        default:
            return state;
    }
};
export default accounts;
