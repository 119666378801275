import {call, put, takeEvery} from 'redux-saga/effects';
import {UPDATE_CALENDAR_TYPES} from 'constants/types';
import {updateCalendarResult, updateCalendarError} from 'actions/calendar';
import { authHeader } from '../../helpers/authHeader';
import moment from 'moment';
moment.locale('ru');

export function* updateEvent(action) {
    const param = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify({
            name: action.params.event.name,
            type: action.params.event.type,
            start: action.params.event.start,
            end: action.params.event.end,
            status: action.params.event.status,
            userId: action.params.event.user,
            comment: action.params.event.comment,
        }),
    };
    try{
        const response = yield call(fetch, `${process.env.REACT_APP_API_URL}/user/day-off/${action.params.event.id}`, param);
        const data = yield call(response.json.bind(response));
        if (response.status === 200) {
            yield put(updateCalendarResult(data));
        } else {
            yield put(updateCalendarError(data));
        }
    } catch (error) {
        const data = {code: 500, userMessage: 'Internal Server Error'};
        yield put(updateCalendarError(data));
    }
}

export function* watchUpdateCalendarRequest() {
    yield takeEvery(UPDATE_CALENDAR_TYPES.REQUEST, updateEvent);
}
