import {call, all, put, takeEvery} from 'redux-saga/effects';
import {FETCH_USER_TYPES} from 'constants/types';

import {fetchUserResult, fetchUserError} from 'actions/user';
import { authHeader } from '../../helpers/authHeader';

export function* fetchPhones(id) {
  const param = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      pagination: {page: 1, limit: 100},
      filter: {userId: id}
    })
  };
  try{
    const response = yield call(fetch, `${process.env.REACT_APP_API_URL}/user/phone/list`, param);
    const data = yield call(response.json.bind(response));
    if (response.status === 200) {
      return data.result;
    } else if (response.status === 400) {
      return []
    } else if(response.status === 401) {
      return []
    } else if(response.status === 404){
      return []
    }
  }
  catch (error) {
    return []
  }
}

export function* fetchSpecializations(id){
  const param = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      pagination: {page: 1, limit: 100},
      filter: {userId: id},
      sort: [
        {
          "property": "start",
          "order": "asc"
        }
      ]
    })
  };
  try{
    const response = yield call(fetch, `${process.env.REACT_APP_API_URL}/user/specialization/list`, param);
    const data = yield call(response.json.bind(response));
    if (response.status === 200) {
      return data.result;
    } else if (response.status === 400) {
      return []
    } else if(response.status === 401) {
      return []
    } else if(response.status === 404){
      return []
    }
  }
  catch (error) {
    return []
  }
}

export function* fetchWorkPayment(id){
  const param = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      pagination: {page: 1, limit: 100},
      filter: {userId: id},
      sort: [
        {
          "property": "start",
          "order": "asc"
        }
      ]
    })
  };
  try{
    const response = yield call(fetch, `${process.env.REACT_APP_API_URL}/user/work-payment/list`, param);
    const data = yield call(response.json.bind(response));
    if (response.status === 200) {
      return data.result;
    } else if (response.status === 400) {
      return []
    } else if(response.status === 401) {
      return []
    } else if(response.status === 404){
      return []
    }
  }
  catch (error) {
    return []
  }
}

export function* fetchDepartment(id){
  const param = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      pagination: {page: 1, limit: 100},
      filter: {userId: id},
      sort: [
        {
          "property": "start",
          "order": "asc"
        }
      ]
    })
  };
  try{
    const response = yield call(fetch, `${process.env.REACT_APP_API_URL}/user/department/list`, param);
    const data = yield call(response.json.bind(response));
    if (response.status === 200) {
      return data.result;
    } else if (response.status === 400) {
      return []
    } else if(response.status === 401) {
      return []
    } else if(response.status === 404){
      return []
    }
  }
  catch (error) {
    return []
  }
}

export function* fetchCertification(params){
  const {
    pagination,
    filter,
    // sort,
  } = params;
  const {
    id: userId,
    ...restFilters
  } = filter || {};
  const param = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      pagination: {
        page: pagination ? pagination.page : 1,
        limit: pagination ? pagination.limit : 100,
      },
      sort: [ { property: 'id', order: 'asc'} ],
      filter: {
        ...(restFilters || {}),
        userId: userId ? userId : undefined,
      }
    })
  };
  try{
    const response = yield call(fetch, `${process.env.REACT_APP_API_URL}/user/certification/list`, param);
    const data = yield call(response.json.bind(response));
    if (response.status === 200) {
      return data.result;
    } else {
      return []
    }
  }
  catch (error) {
    return []
  }
}

export function* getUser(action) {
  const param = {
    method: 'GET',
    headers: authHeader(),
  };
  try {
    const response = yield call(fetch, `${process.env.REACT_APP_API_URL}/user/${action.params}`, param);
    const data = yield call(response.json.bind(response));
    if (response.status === 200) {
      const { id } = data;
      const [
        phone,
        salary,
        certification
      ] = yield all([
        call(fetchPhones, id),
        call(fetchWorkPayment, id),
        call(fetchCertification, { filter: { id }})
      ]);
      const { userDepartments, userSpecializations } = data;
      yield put(fetchUserResult({
        ...data,
        phone,
        salary,
        typeSpecialist: userSpecializations,
        department: userDepartments.sort((a, b) => new Date(a.start) - new Date(b.start)),
        certification,
        referralUserId: (!!data.referralUser && !!data.referralUser.id && data.referralUser.id) || null
      }));
    } else if (response.status === 400) {
      yield put(fetchUserError(data));
    } else if(response.status === 401){
      yield put(fetchUserError(data));
    } else if(response.status === 404){
      yield put(fetchUserError(data));
    }
  } catch (error) {
    console.log(error)
    const data = {code: 500, userMessage: 'Internal Server Error'};
    yield put(fetchUserError(data));
  }
}

export function* watchLoadUserRequest() {
  yield takeEvery(FETCH_USER_TYPES.REQUEST, getUser);
}
