import {call, put, takeEvery} from 'redux-saga/effects';
import {FETCH_ACCOUNTS_TYPES} from 'constants/types';

import {fetchAccountsResult, fetchAccountsError} from 'actions/account';
import { authHeader } from '../../helpers/authHeader';
import moment from 'moment';
moment.locale('ru');

export function* getAccountsList(action) {
    const param = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({
            pagination:  {
                "page": 1,
                "limit": 100
            },
            filter: {
              "status": "active"
            },
            //sort: isMultiSort(action.params.sort),
            //filter: {"status": "active"},
            fields: [
                "id", "name"
            ]
        })
    };
    try{
        const response = yield call(fetch, `${process.env.REACT_APP_API_URL}/business/legal-entity/list`, param);
        const data = yield call(response.json.bind(response));
        if (response.status === 200) {
            yield put(fetchAccountsResult(data));
        } else {
            yield put(fetchAccountsError(data));
        }
    } catch (error) {
        const data = {code: 500, userMessage: 'Internal Server Error'};
        yield put(fetchAccountsError(data));
    }
}

export function* watchLoadAccountsRequest() {

    yield takeEvery(FETCH_ACCOUNTS_TYPES.REQUEST, getAccountsList);
}
