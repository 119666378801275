import { all } from 'redux-saga/dist/redux-saga-effects-npm-proxy.esm';
import { watchLoadUsersRequest } from './user/getUsersList';
import { watchLoadUserRequest } from './user/getUser';
import { watchUpdateUserRequest } from './user/updateUser';
import { watchAddUserRequest } from './user/addUser';
import { watchLoadReferralUserRequest } from './user/getRefferalUserList';
import { watchAddPhoneRequest } from './user/addPhone';
import { watchLoadSpecializationsRequest } from './specialization/getSpecializationsList';
import { watchLoadRoleRequest } from './role/getRoleList';
import { watchDeletePhoneRequest } from './phone/deletePhone';
import { watchUpdatePhoneRequest } from './phone/updatePhone';
import { watchLoadSpecializationsLevelRequest } from './specialization/getSpecializationLevelList';
import { watchAddSalaryRequest } from './salary/addSalary';
import { watchAddSpecializationRequest } from './specialization/addSpecialization';
import { watchUpdateSalaryRequest } from './salary/updateSalary';
import { watchDeleteSalaryRequest } from './salary/deleteSalary';
import { watchLoadPaysheetRequest } from './paysheet/getPaysheetList';
import { watchUpdateSpecializationRequest } from './specialization/updateSpecialization';
import { watchDeleteSpecializationRequest } from './specialization/deleteSpecialization';
import { watchLoadDepartmentsRequest } from './department/getDepartmentList';
import { watchAddUserDepartmentRequest } from './department/addUserDepartment';
import { watchUpdateUserDepartmentRequest } from './department/updateUserDepartment';
import { watchDeleteUserDepartmentRequest } from './department/deleteUserDepartment';
import { watchLoadIncomesRequest } from './income/getIncomesList';
import { watchLoadIncomeRequest } from './income/getIncome';
import { watchLoadPayoutsRequest } from './payout/getPayoutList';
import { watchLoadProjectsRequest } from './project/getProjectsList';
import { watchLoadAccountsRequest } from './account/getAccountList';
import { watchUpdateIncomeRequest } from './income/updateIncome';
import { watchAddIncomeRequest } from './income/addIncome';
import { watchLoadPayoutRequest } from './payout/getPayout';
import { watchAddPayoutRequest } from './payout/addPayout';
import { watchUpdatePayoutRequest } from './payout/updatePayout';
import { watchLoadEntitiesRequest } from './entity/getEntityList';
import { watchLoadEntityRequest } from './entity/getEntity';
import { watchUpdateEntityRequest } from './entity/updateEntity';
import { watchAddEntityRequest } from './entity/addEntity';
import { watchLoadEmployeesRequest } from './employee/getEmployeeList';
import { watchLoadClientsRequest } from './client/getClientList';
import { watchLoadManagersRequest } from './manager/getManagerList';
import { watchLoadLeadsRequest } from './lead/getLeadList';
import { watchDeleteIncomeRequest } from './income/deleteIncome';
import { watchDeletePayoutRequest } from './payout/deletePayout';
import { watchAddDepartmentRequest } from './department/addDepartment';
import { watchLoadDepartmentRequest } from './department/getDepartment';
import { watchUpdateDepartmentRequest } from './department/updateDepartment';
import { watchDeleteDepartmentRequest } from './department/deleteDepartment';
import { watchLoadRedmineUsersRequest } from './redmine/getRedmineUserList';
import { watchLoadLeadRequest } from './lead/getLead';
import { watchUpdateLeadRequest } from './lead/updateLead';
import { watchAddLeadRequest } from './lead/addLead';
import { watchLoadLoginRequest } from './auth/login';
import { watchLoadLogoutRequest } from './auth/logout';
import { watchLoadEventsRequest } from './event/getEventList';
import { watchLoadEventRequest } from './event/getEvent';
import { watchLoadCalendarRequest } from './calendar/getEventList';
import { watchAddCalendarRequest } from './calendar/createEvent';
import { watchUpdateCalendarRequest } from './calendar/updateEvent';
import { watchDeleteCalendarRequest } from './calendar/deleteEvent';
import { watchLoadEventCalendarRequest } from './calendar/getEvent';
import { watchLoadProfileRequest } from './profile/getProfile';
import { watchLoadDashboardUsersRequest } from './dashboard/getDashboardUsers';
import { watchLoadDashboardCompanyRequest } from './dashboard/getDashboardCompany';
import { watchLoadDashboardProfitRequest } from './dashboard/getDashboardProfit';
import { watchLoadDashboardUserProfitRequest } from './dashboard/getDashboardUserProfit';
import { watchLoadDashboardProjectsRequest } from './dashboard/getDashboardProjects';
import { watchLoadDashboardProjectsManagersRequest } from './dashboard/getDashboardUsersManagers';
import { watchLoadDashboardDepartmentsRequest } from './dashboard/getDashboardDepartments';
import { watchLoadDashboardPayrollRequest } from './dashboard/getDashboardPayroll';
import { watchLoadBillEntitiesRequest } from './billEntities/getEntityList';
import { watchLoadSetDashboardPayrollRequest } from './dashboard/setDashboardPayroll';
import { watchAddCertificationRequest } from './certification/addCertification';
import { watchUpdateUserCertificationRequest } from './certification/updateCertification';
import { watchDeleteUserCertificationRequest } from './certification/deleteCertification';
import { watchLoadMasqueradeAuthRequest } from './masquerade/auth';
import { watchLoadMasqueradeRollbackRequest } from './masquerade/rollback';
import { watchLoadMasqueradeStatusRequest } from './masquerade/status';
import { watchLoadWelcomeLetterRequest } from './welcomeletter/getWelcomeLetter';
import { watchLoadWelcomeLetterPlaceholdersRequest } from './welcomeletter/getWelcomeLetterPlaceholders';
import { watchSendWelcomeLetterRequest } from './welcomeletter/sendWelcomeLetter';
import { watchUpdateWelcomeLetterErrorRequest } from './welcomeletter/updateWelcomeLetter';

export default function* rootSaga() {
  yield all([
    watchLoadUsersRequest(),
    watchLoadUserRequest(),
    watchUpdateUserRequest(),
    watchAddUserRequest(),
    watchLoadReferralUserRequest(),
    watchAddPhoneRequest(),
    watchLoadSpecializationsRequest(),
    watchLoadRoleRequest(),
    watchDeletePhoneRequest(),
    watchUpdatePhoneRequest(),
    watchLoadSpecializationsLevelRequest(),
    watchAddSalaryRequest(),
    watchAddSpecializationRequest(),
    watchUpdateSalaryRequest(),
    watchDeleteSalaryRequest(),
    watchLoadPaysheetRequest(),
    watchUpdateSpecializationRequest(),
    watchDeleteSpecializationRequest(),
    watchLoadDepartmentsRequest(),
    watchAddUserDepartmentRequest(),
    watchUpdateUserDepartmentRequest(),
    watchDeleteUserDepartmentRequest(),
    watchLoadIncomesRequest(),
    watchLoadIncomeRequest(),
    watchLoadPayoutsRequest(),
    watchLoadProjectsRequest(),
    watchLoadAccountsRequest(),
    watchUpdateIncomeRequest(),
    watchAddIncomeRequest(),
    watchLoadPayoutRequest(),
    watchAddPayoutRequest(),
    watchUpdatePayoutRequest(),
    watchLoadEntitiesRequest(),
    watchLoadEntityRequest(),
    watchUpdateEntityRequest(),
    watchAddEntityRequest(),
    watchLoadEmployeesRequest(),
    watchLoadClientsRequest(),
    watchLoadManagersRequest(),
    watchLoadLeadsRequest(),
    watchDeleteIncomeRequest(),
    watchDeletePayoutRequest(),
    watchAddDepartmentRequest(),
    watchLoadDepartmentRequest(),
    watchUpdateDepartmentRequest(),
    watchDeleteDepartmentRequest(),
    watchLoadRedmineUsersRequest(),
    watchLoadLeadRequest(),
    watchUpdateLeadRequest(),
    watchAddLeadRequest(),
    watchLoadLoginRequest(),
    watchLoadLogoutRequest(),
    watchLoadEventsRequest(),
    watchLoadEventRequest(),
    watchLoadCalendarRequest(),
    watchUpdateCalendarRequest(),
    watchDeleteCalendarRequest(),
    watchAddCalendarRequest(),
    watchLoadEventCalendarRequest(),
    watchLoadProfileRequest(),
    watchLoadDashboardUsersRequest(),
    watchLoadDashboardCompanyRequest(),
    watchLoadDashboardProfitRequest(),
    watchLoadDashboardUserProfitRequest(),
    watchLoadDashboardProjectsRequest(),
    watchLoadDashboardProjectsManagersRequest(),
    watchLoadDashboardDepartmentsRequest(),
    watchLoadDashboardPayrollRequest(),
    watchLoadBillEntitiesRequest(),
    watchLoadSetDashboardPayrollRequest(),
    watchAddCertificationRequest(),
    watchUpdateUserCertificationRequest(),
    watchDeleteUserCertificationRequest(),
    watchLoadMasqueradeAuthRequest(),
    watchLoadMasqueradeRollbackRequest(),
    watchLoadMasqueradeStatusRequest(),
    watchLoadWelcomeLetterRequest(),
    watchLoadWelcomeLetterPlaceholdersRequest(),
    watchSendWelcomeLetterRequest(),
    watchUpdateWelcomeLetterErrorRequest()
]);
}
