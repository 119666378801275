import {
    ADD_USER_CERTIFICATION_TYPES,
    UPDATE_USER_CERTIFICATION_TYPES,
    DELETE_USER_CERTIFICATION_TYPES
} from '../../constants/certifications/certificationsUser';

const initialState = {
    certification: null,
    loading: null,
    error: null
};

const certification = (state=initialState, action) => {
    switch (action.type) {
        case ADD_USER_CERTIFICATION_TYPES.REQUEST:
            return {
                ...state,
                loading: true
            };
        case ADD_USER_CERTIFICATION_TYPES.SUCCESS:
            return {
                ...state,
                loading: false
            };
        case ADD_USER_CERTIFICATION_TYPES.ERROR:
            return  {
                ...state,
                loading: false,
                error: action.error
            };
        case DELETE_USER_CERTIFICATION_TYPES.REQUEST:
            return {
                ...state,
                loading: true
            };
        case DELETE_USER_CERTIFICATION_TYPES.SUCCESS:
            return {
                ...state,
                loading: false
            };
        case DELETE_USER_CERTIFICATION_TYPES.ERROR:
            return {
                ...state,
                error: action.error
            };
        case UPDATE_USER_CERTIFICATION_TYPES.REQUEST:
            return {
                ...state,
                loading: true
            };
        case UPDATE_USER_CERTIFICATION_TYPES.SUCCESS:
            return {
                ...state,
                loading: false
            };
        case UPDATE_USER_CERTIFICATION_TYPES.ERROR:
            return  {
                ...state,
                loading: false,
                error: action.error
            };
        default:
            return state;
    }
}
export default certification;