import {activeColumns, hiddenColumns} from './TransferTableData';

const initialValue = {
    activeColumns: activeColumns,
    hiddenColumns: hiddenColumns
}

const settingTransfer = (state=initialValue, action) => {
    switch (action.type) {
        case 'CHANGE_COLUMNS_TRANSFER':
            return { ...state, activeColumns: action.activeColumns, hiddenColumns: action.hiddenColumns};
        case 'CHANGE_COLUMN_ACTIVE_TRANSFER':
            return { ...state, activeColumns: action.activeColumns};
        case 'CHANGE_COLUMN_HIDDEN_TRANSFER':
            return { ...state, hiddenColumns: action.hiddenColumns};
        default:
            return state;
    }
}
export default settingTransfer;
