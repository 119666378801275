import {call, put, takeEvery} from 'redux-saga/effects';
import {FETCH_BILL_ENTITIES_TYPES} from 'constants/billEntities';

import {fetchBillEntitiesResult, fetchBillEntitiesError} from 'actions/billEntities';
import { authHeader } from '../../helpers/authHeader';
import moment from 'moment';
moment.locale('ru');

function isMultiSort(sort) {
    if(sort){
        const {sortName, sortOrder} = sort;
        let sortied = sortName.map((name,i) => {
            return {
                "property": name,
                "order": sortOrder[i]
            }
        });
        return sortied;
    }
}

export function* getBillEntitiesList(action) {
    const param = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({
            pagination: action.params.pagination,
            sort: isMultiSort(action.params.sort),
            filter: action.params.filter,
            //fields: action.params.fields
        })
    };
    try{
        const response = yield call(fetch, `${process.env.REACT_APP_API_URL}business/bill-entity/list`, param);
        const data = yield call(response.json.bind(response));
        if (response.status === 200) {
            yield put(fetchBillEntitiesResult(data));
        } else {
            yield put(fetchBillEntitiesError(data));
        }
    } catch (error) {
        const data = {code: 500, userMessage: 'Internal Server Error'};
        yield put(fetchBillEntitiesError(data));
    }
}

export function* watchLoadBillEntitiesRequest() {

    yield takeEvery(FETCH_BILL_ENTITIES_TYPES.REQUEST, getBillEntitiesList);
}
