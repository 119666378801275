export const FETCH_WELCOME_LETTER_TYPES = {
    REQUEST: 'FETCH_WELCOME_LETTER_REQUEST',
    RESULT: 'FETCH_WELCOME_LETTER_RESULT',
    ERROR: 'FETCH_WELCOME_LETTER_ERROR',
};

export const FETCH_WELCOME_LETTER_PLACEHOLDERS_TYPES = {
    REQUEST: 'FETCH_WELCOME_LETTER_PLACEHOLDERS_REQUEST',
    RESULT: 'FETCH_WELCOME_LETTER_PLACEHOLDERS_RESULT',
    ERROR: 'FETCH_WELCOME_LETTER_PLACEHOLDERS_ERROR',
};

export const SEND_WELCOME_LETTER_TYPES = {
    REQUEST: 'SEND_WELCOME_LETTER_REQUEST',
    SUCCESS: 'SEND_WELCOME_LETTER_SUCCESS',
    ERROR: 'SEND_WELCOME_LETTER_ERROR'
};

export const UPDATE_WELCOME_LETTER_TYPES = {
    REQUEST: 'UPDATE_WELCOME_LETTER_REQUEST',
    SUCCESS: 'UPDATE_WELCOME_LETTER_SUCCESS',
    ERROR: 'UPDATE_WELCOME_LETTER_ERROR'
};