import {
    FETCH_EVENTS_TYPES,
    FETCH_EVENT_TYPES,
    ADD_EVENT_TYPES,
    UPDATE_EVENT_TYPES,
    CLEAR_EVENT_ERROR
} from '../constants/types';

export function fetchEventsRequest(params){
    return {
        type: FETCH_EVENTS_TYPES.REQUEST,
        params
    }
}

export function fetchEventsResult(data){
    return {
        type: FETCH_EVENTS_TYPES.RESULT,
        data
    }
}

export function fetchEventsError(error) {
    return{
        type: FETCH_EVENTS_TYPES.ERROR,
        error
    }
}

export function fetchEventRequest(params){
    return {
        type: FETCH_EVENT_TYPES.REQUEST,
        params
    }
}

export function fetchEventResult(data){
    return {
        type: FETCH_EVENT_TYPES.RESULT,
        data
    }
}

export function fetchEventError(error) {
    return{
        type: FETCH_EVENT_TYPES.ERROR,
        error
    }
}

export function updateEventRequest(params){
    return {
        type: UPDATE_EVENT_TYPES.REQUEST,
        params
    }
}

export function updateEventSuccess(data){
    return {
        type: UPDATE_EVENT_TYPES.SUCCESS,
        data
    }
}

export function updateEventError(error) {
    return {
        type: UPDATE_EVENT_TYPES.ERROR,
        error
    }
}

export function addEventRequest(params){
    return {
        type: ADD_EVENT_TYPES.REQUEST,
        params
    }
}

export function addEventSuccess(data) {
    return {
        type: ADD_EVENT_TYPES.SUCCESS,
        data
    }
}

export function addEventError(error) {
    return {
        type: ADD_EVENT_TYPES.ERROR,
        error
    }
}

export function clearEventError() {
    return {
        type: CLEAR_EVENT_ERROR
    }
}