import {call, put, takeEvery} from 'redux-saga/effects';
import {FETCH_LEADS_TYPES} from 'constants/types';
import {fetchLeadsResult, fetchLeadsError} from 'actions/lead';
import { authHeader } from '../../helpers/authHeader';
import moment from 'moment';
moment.locale('ru');

// const lastNameAsc = {
//     "property": "lastName",
//     "order": "asc"
// };
//
// const firstNameAsc = {
//     "property": "firstName",
//     "order": "asc"
// };
//
// const patronymicAsc =  {
//     "property": "patronymic",
//     "order": "asc"
// };
//
// const lastNameDesc = {
//     "property": "lastName",
//     "order": "desc"
// };
//
// const firstNameDesc = {
//     "property": "firstName",
//     "order": "desc"
// };
//
// const patronymicDesc =  {
//     "property": "patronymic",
//     "order": "desc"
// }

// function isMultiSort(sort) {
//     if (!sort) {
//         return sortied;
//     }
//     const {sortName, sortOrder} = sort;
//     let sortied = sortName.map((name,i) => {
//         return {
//             "property": name,
//             "order": sortOrder[i]
//         }
//     });
//     const isCheckFioAsc = sortied.some(s => s.property === "fio" && s.order === "asc");
//     const isCheckFioDesc = sortied.some(s => s.property === "fio" && s.order === "desc");
//     if(isCheckFioAsc){
//         let index = sortied.findIndex(s => s.property === "fio" && s.order === "asc");
//         sortied.splice(index, 1, lastNameAsc, firstNameAsc, patronymicAsc);
//         return sortied;
//     } else if(isCheckFioDesc){
//         let index = sortied.findIndex(s => s.property === "fio" && s.order === "desc");
//         sortied.splice(index, 1, lastNameDesc, firstNameDesc, patronymicDesc);
//         return sortied;
//     }
// }

export function* getLeadsList(action) {
    const param = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({
            pagination: action.params.pagination,
            //sort: isMultiSort(action.params.sort),
            filter: {
                "role": ["MANAGER"]
            },
            //fields: action.params.fields
        })
    };
    try{
        const response = yield call(fetch, `${process.env.REACT_APP_API_URL}user/list`, param);
        const data = yield call(response.json.bind(response));
        if (response.status === 200) {
            yield put(fetchLeadsResult(data));
        } else {
            yield put(fetchLeadsError(data));
        }
    } catch (error) {
        const data = {code: 500, userMessage: 'Internal Server Error'};
        yield put(fetchLeadsError(data));
    }
}

export function* watchLoadLeadsRequest() {

    yield takeEvery(FETCH_LEADS_TYPES.REQUEST, getLeadsList);
}
