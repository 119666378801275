import {
    activeColumns,
    hiddenColumns,
    setStorageActive as saveActiveColumns,
    setStorageHidden as saveHiddenColumns,
} from './EventTableData';



const initialValue = {
    activeColumns: activeColumns,
    hiddenColumns: hiddenColumns
}

const settingEvent = (state=initialValue, action) => {
    switch (action.type) {
        case 'CHANGE_COLUMNS_EVENT': {
            saveActiveColumns(action.activeColumns.map(({ dataField }) => dataField));
            return { ...state, activeColumns: action.activeColumns, hiddenColumns: action.hiddenColumns};
        }
        case 'CHANGE_COLUMN_ACTIVE_EVENT': {
            saveActiveColumns(action.activeColumns.map(({ dataField }) => dataField));
            return { ...state, activeColumns: action.activeColumns};
        }
        case 'CHANGE_COLUMN_HIDDEN_EVENT': {
            saveHiddenColumns(action.hiddenColumns.map(({ dataField }) => dataField));
            return { ...state, hiddenColumns: action.hiddenColumns};
        }
        default:
            return state;
    }
}
export default settingEvent;
