import {activeColumns, hiddenColumns} from './EntityTableData';

const initialValue = {
  activeColumns: activeColumns,
  hiddenColumns: hiddenColumns
}

const settingEntity = (state=initialValue, action) => {
  switch (action.type) {
    case 'CHANGE_COLUMNS_ENTITY':
      return { ...state, activeColumns: action.activeColumns, hiddenColumns: action.hiddenColumns};
    case 'CHANGE_COLUMN_ACTIVE_ENTITY':
      return { ...state, activeColumns: action.activeColumns};
    case 'CHANGE_COLUMN_HIDDEN_ENTITY':
      return { ...state, hiddenColumns: action.hiddenColumns};
    default:
      return state;
  }
}
export default settingEntity;
