import {call, put, takeEvery} from 'redux-saga/effects';
import {DELETE_DEPARTMENT_TYPES} from 'constants/types';

import {deleteDepartmentSuccess, deleteDepartmentError} from 'actions/department';
import { authHeader } from '../../helpers/authHeader';

export function* deleteDepartment(action) {
    const param = {
        method: 'DELETE',
        headers: authHeader()
    };
    try{
        const response = yield call(fetch, `${process.env.REACT_APP_API_URL}/department/${action.params.id}`, param);
        const data = yield call(response.json.bind(response));
        if (response.status === 200) {
            window.history.back();
            yield put(deleteDepartmentSuccess(data));
        } else {
            yield put(deleteDepartmentError(data));
        }
    } catch (error) {
        const data = {code: 500, userMessage: 'Internal Server Error'};
        yield put(deleteDepartmentError(data));
    }
}

export function* watchDeleteDepartmentRequest() {
    yield takeEvery(DELETE_DEPARTMENT_TYPES.REQUEST, deleteDepartment);
}