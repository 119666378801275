import {call, put, takeEvery} from 'redux-saga/effects';
import {UPDATE_ENTITY_TYPES} from '../../constants/types';

import {updateEntitySuccess, updateEntityError} from '../../actions/entity';
import { authHeader } from '../../helpers/authHeader';

export function* updateEntity(action) {
    const param = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(action.params.entity)
    };
    try{
        const response = yield call(fetch, `${process.env.REACT_APP_API_URL}/business/legal-entity/${action.params.id}`, param);
        const data = yield call(response.json.bind(response));
        if (response.status === 200) {
            yield put(updateEntitySuccess(data));
        } else {
            yield put(updateEntityError(data));
        }
    } catch (error) {
        const data = {code: 500, userMessage: 'Internal Server Error'};
        yield put(updateEntityError(data));
    }
}

export function* watchUpdateEntityRequest() {
    yield takeEvery(UPDATE_ENTITY_TYPES.REQUEST, updateEntity);
}
