import {
    ADD_LEAD_TYPES,
    CLEAR_LEAD_ERROR,
    FETCH_LEAD_TYPES,
    UPDATE_LEAD_TYPES
} from '../constants/types';

const initialState = {
    lead: null,
    loading: null,
    error: null
};

const lead = (state=initialState, action) => {
    switch (action.type) {
        case FETCH_LEAD_TYPES.REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case FETCH_LEAD_TYPES.RESULT:
            return {
                ...state,
                loading: false,
                error: null,
                lead: action.data
            };
        case FETCH_LEAD_TYPES.ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case UPDATE_LEAD_TYPES.REQUEST:
            return {
                ...state,
                error: null,
                loading: false
            };
        case UPDATE_LEAD_TYPES.SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                lead: action.data
            };
        case UPDATE_LEAD_TYPES.ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case ADD_LEAD_TYPES.REQUEST:
            return {
                ...state,
                loading: true
            };
        case ADD_LEAD_TYPES.SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case ADD_LEAD_TYPES.ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case CLEAR_LEAD_ERROR:
            return {
                ...state,
                error: null
            };
        default:
            return state;
    }
};
export default lead;
