import { INTERNAL_ERROR_CODES } from 'constants/errorCodes';
import { fetchLogoutRequest } from 'actions/logout';


const sessionMiddleware = store => next => async action => {
    const { error } = action;

    if (error && error.internalCode === INTERNAL_ERROR_CODES.INCORRECT_TOKEN) {
        store.dispatch(fetchLogoutRequest());
        next(action);
    } else {
        next(action);
    }
};

export default sessionMiddleware;
