import {call, put, takeEvery} from 'redux-saga/effects';
import {FETCH_ENTITY_TYPES} from 'constants/types';

import {fetchEntityResult, fetchEntityError} from 'actions/entity';
import { authHeader } from '../../helpers/authHeader';

export function* getEntity(action) {
    const param = {
        method: 'GET',
        headers: authHeader(),
    };
    try{
        const response = yield call(fetch, `${process.env.REACT_APP_API_URL}/business/legal-entity/${action.params}`, param);
        const data = yield call(response.json.bind(response));
        if (response.status === 200) {
            yield put(fetchEntityResult(data));
        } else {
            yield put(fetchEntityError(data));
        }
    } catch (error) {
        const data = {code: 500, userMessage: 'Internal Server Error'};
        yield put(fetchEntityError(data));
    }
}

export function* watchLoadEntityRequest() {
    yield takeEvery(FETCH_ENTITY_TYPES.REQUEST, getEntity);
}
