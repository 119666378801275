import {
  addUserInfoField,
  updateUserInfoField,
  deleteUserInfoField,
} from '../helpers/userInfoReducers';

import {
  FETCH_USER_TYPES,
  UPDATE_USER_TYPES,
  ADD_USER_TYPES,
  CLEAR_USER_ERROR,
  CLEAR_USER_PASSWORD,
  UPDATE_USER_DEPARTMENT_TYPES,
  ADD_USER_DEPARTMENT_TYPES,
  DELETE_USER_DEPARTMENT_TYPES,
  ADD_SPECIALIZATION_TYPES,
  UPDATE_SPECIALIZATION_TYPES,
  DELETE_SPECIALIZATION_TYPES,
  UPDATE_SALARY_TYPES,
  DELETE_SALARY_TYPES,
  ADD_SALARY_TYPES,
  CLEAR_NEW_USER,
} from '../constants/types';

import {
  DELETE_USER_CERTIFICATION_TYPES,
  UPDATE_USER_CERTIFICATION_TYPES,
  ADD_USER_CERTIFICATION_TYPES,
} from '../constants/certifications/certificationsUser';

const initialState = {
  user: null,
  loading: null,
  error: null,
  success: null,
  password: null,
  newUserId: null,
  newUserEmail: null,
  newUserPassword: null
};

const userInfo = (state=initialState, action) => {
  switch (action.type) {
    case FETCH_USER_TYPES.REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_USER_TYPES.RESULT:
      return {
        ...state,
        loading: false,
        user: action.data
      };
    case FETCH_USER_TYPES.ERROR:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case UPDATE_USER_TYPES.REQUEST:
      return {
        ...state,
        loading: true,
        success: null
      };
    case UPDATE_USER_TYPES.SUCCESS:
      return {
        ...state,
        loading: false,
        user: {
          ...state.user,
          ...action.data,
        },
        success: 'Данные успешно обновлены'
      };
    case UPDATE_USER_TYPES.ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
        success: null
      };
    case ADD_USER_TYPES.REQUEST:
    {
      return {
        ...state,
        loading: true,
      };
    }
    case ADD_USER_TYPES.SUCCESS:
      console.log(action.data)
      return {
        ...state,
        loading: false,
        newUserId: action.data.id,
        newUserEmail: action.data.email,
        newUserPassword: action.data.password
      };
    case ADD_USER_TYPES.ERROR:
    {
      return {
        ...state,
        loading: false,
        error: action.error
      };
    }
    case CLEAR_USER_ERROR:
      return {
        ...state,
        error: null,
        success: null
      };
    case CLEAR_USER_PASSWORD:
      return {
        ...state,
        user: null,
        password: null
      }
    case UPDATE_USER_DEPARTMENT_TYPES.SUCCESS: {
      const department = action.data;
      const newDepartments = state.user.department.map((dep) => dep.id === department.id ? {
          ...dep,
          ...department,
        } : dep).sort((a, b) => new Date(a.start) - new Date(b.start));
      return {
        ...state,
        user: {
          ...state.user,
          department: newDepartments,
        }
      }
    }
    case ADD_USER_DEPARTMENT_TYPES.SUCCESS: {
      const department = action.data;
      const newDepartments = [...state.user.department, department].sort((a, b) => new Date(a.start) - new Date(b.start));
      return {
        ...state,
        user: {
          ...state.user,
          department: newDepartments
        }
      }
    }
    case DELETE_USER_DEPARTMENT_TYPES.SUCCESS: {
      return deleteUserInfoField(state, 'department', action.data);
    }
    case DELETE_USER_CERTIFICATION_TYPES.SUCCESS: {
      return deleteUserInfoField(state, 'certification', action.data);
    }
    case UPDATE_USER_CERTIFICATION_TYPES.SUCCESS: {
      return updateUserInfoField(state, 'certification', action.data);
    }
    case ADD_USER_CERTIFICATION_TYPES.SUCCESS: {
      return addUserInfoField(state, 'certification', action.data);
    }
    case ADD_SPECIALIZATION_TYPES.SUCCESS: {
      return addUserInfoField(state, 'typeSpecialist', action.data);
    }
    case UPDATE_SPECIALIZATION_TYPES.SUCCESS: {
      return updateUserInfoField(state, 'typeSpecialist', action.data);
    }
    case DELETE_SPECIALIZATION_TYPES.SUCCESS: {
      return deleteUserInfoField(state, 'typeSpecialist', action.data);
    }
    case UPDATE_SALARY_TYPES.SUCCESS: {
      return updateUserInfoField(state, 'salary', action.data);
    }
    case DELETE_SALARY_TYPES.SUCCESS: {
      return deleteUserInfoField(state, 'salary', action.data);
    }
    case ADD_SALARY_TYPES.SUCCESS: {
      return addUserInfoField(state, 'salary', action.data);
    }
    case CLEAR_NEW_USER: {
      return {
        ...state,
        newUserId: null,
        newUserPassword: null,
        newUserEmail: null
      }
    }
    default:
      return state;
  }
}
export default userInfo;
