import {call, put, takeEvery} from 'redux-saga/effects';
import {FETCH_SPECIALIZATION_LEVEL_TYPES} from 'constants/types';
import {fetchSpecializationLevelResult, fetchSpecializationLevelError} from '../../actions/specializationLevel';
import { authHeader } from '../../helpers/authHeader';

export function* getSpecializationsLevelList(action) {
  const param = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      pagination: {
        "page": 1,
        "limit": 100
      },
      sort: [
        {
          "property": "isActive",
          "order": "desc"
        }
      ],
      filter: {
        "isActive": true
      }
    })
  };

  try{
    const response = yield call(fetch, `${process.env.REACT_APP_API_URL}/specialization-level/list`, param);
    const data = yield call(response.json.bind(response));
    if (response.status === 200) {
      yield put(fetchSpecializationLevelResult(data));
    } else if (response.status === 400) {
      yield put(fetchSpecializationLevelError(data));
    } else if(response.status === 401) {
      yield put(fetchSpecializationLevelError(data));
    } else if(response.status === 404){
      yield put(fetchSpecializationLevelError(data));
    }
  } catch (error) {
    const data = {code: 500, userMessage: 'Internal Server Error'};
    yield put(fetchSpecializationLevelError(data));
  }
}

export function* watchLoadSpecializationsLevelRequest() {

  yield takeEvery(FETCH_SPECIALIZATION_LEVEL_TYPES.REQUEST, getSpecializationsLevelList);
}

