import {call, put, takeEvery} from 'redux-saga/effects';
import {DELETE_INCOME_TYPES} from 'constants/types';

import {deleteIncomeSuccess, deleteIncomeError} from 'actions/income';
import { authHeader } from '../../helpers/authHeader';

export function* deleteIncome(action) {
    const param = {
        method: 'DELETE',
        headers: authHeader()
    };
    try{
        const response = yield call(fetch, `${process.env.REACT_APP_API_URL}/finance/income/${action.params.id}`, param);
        const data = yield call(response.json.bind(response));
        if (response.status === 200) {
            window.history.back();
            yield put(deleteIncomeSuccess(data));
        } else {

        }
    } catch (error) {
        const data = {code: 500, userMessage: 'Internal Server Error'};
        yield put(deleteIncomeError(data));
    }
}

export function* watchDeleteIncomeRequest() {
    yield takeEvery(DELETE_INCOME_TYPES.REQUEST, deleteIncome);
}

