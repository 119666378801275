import {
    FETCH_DASHBOARD_USERS_TYPES,
    FETCH_DASHBOARD_COMPANY_TYPES,
    FETCH_DASHBOARD_PROFIT_TYPES,
    FETCH_DASHBOARD_USER_PROFIT_TYPES,
    FETCH_DASHBOARD_PROJECTS_TYPES,
    FETCH_DASHBOARD_PROJECTS_MANAGERS_TYPES,
    FETCH_DASHBOARD_DEPARTMENTS_TYPES,
    FETCH_DASHBOARD_PAYROLL_TYPES,
    SET_DASHBOARD_PAYROLL_TYPES
} from '../constants/types';

export function fetchDashboardUsersRequest(params){
    return {
        type: FETCH_DASHBOARD_USERS_TYPES.REQUEST,
        params
    }
}

export function fetchDashboardUsersResult(data){
    return {
        type: FETCH_DASHBOARD_USERS_TYPES.RESULT,
        data
    }
}

export function fetchDashboardUsersError(error) {
    return{
        type: FETCH_DASHBOARD_USERS_TYPES.ERROR,
        error
    }
}

export function fetchDashboardCompanyRequest(params){
    return {
        type: FETCH_DASHBOARD_COMPANY_TYPES.REQUEST,
        params
    }
}

export function fetchDashboardCompanyResult(data){
    return {
        type: FETCH_DASHBOARD_COMPANY_TYPES.RESULT,
        data
    }
}

export function fetchDashboardCompanyError(error) {
    return{
        type: FETCH_DASHBOARD_COMPANY_TYPES.ERROR,
        error
    }
}

export function fetchDashboardProfitRequest(params){
    return {
        type: FETCH_DASHBOARD_PROFIT_TYPES.REQUEST,
        params
    }
}

export function fetchDashboardProfitResult(data){
    return {
        type: FETCH_DASHBOARD_PROFIT_TYPES.RESULT,
        data
    }
}

export function fetchDashboardProfitError(error) {
    return{
        type: FETCH_DASHBOARD_PROFIT_TYPES.ERROR,
        error
    }
}

export function fetchDashboardUserProfitRequest(params){
    return {
        type: FETCH_DASHBOARD_USER_PROFIT_TYPES.REQUEST,
        params
    }
}

export function fetchDashboardUserProfitResult(data){
    return {
        type: FETCH_DASHBOARD_USER_PROFIT_TYPES.RESULT,
        data
    }
}

export function fetchDashboardUserProfitError(error) {
    return{
        type: FETCH_DASHBOARD_USER_PROFIT_TYPES.ERROR,
        error
    }
}

export function fetchDashboardProjectsRequest(params){
    return {
        type: FETCH_DASHBOARD_PROJECTS_TYPES.REQUEST,
        params
    }
}

export function fetchDashboardProjectsResult(data){
    return {
        type: FETCH_DASHBOARD_PROJECTS_TYPES.RESULT,
        data
    }
}

export function fetchDashboardProjectsError(error) {
    return{
        type: FETCH_DASHBOARD_PROJECTS_TYPES.ERROR,
        error
    }
}

export function fetchDashboardProjectsManagersRequest(params){
    return {
        type: FETCH_DASHBOARD_PROJECTS_MANAGERS_TYPES.REQUEST,
        params
    }
}

export function fetchDashboardProjectsManagersResult(data){
    return {
        type: FETCH_DASHBOARD_PROJECTS_MANAGERS_TYPES.RESULT,
        data
    }
}

export function fetchDashboardProjectsManagersError(error) {
    return{
        type: FETCH_DASHBOARD_PROJECTS_MANAGERS_TYPES.ERROR,
        error
    }
}

export function fetchDashboardDepartmentsRequest(params){
    return {
        type: FETCH_DASHBOARD_DEPARTMENTS_TYPES.REQUEST,
        params
    }
}

export function fetchDashboardDepartmentsResult(data){
    return {
        type: FETCH_DASHBOARD_DEPARTMENTS_TYPES.RESULT,
        data
    }
}

export function fetchDashboardDepartmentsError(error) {
    return{
        type: FETCH_DASHBOARD_DEPARTMENTS_TYPES.ERROR,
        error
    }
}

export function fetchDashboardPayrollRequest(params){
    return {
        type: FETCH_DASHBOARD_PAYROLL_TYPES.REQUEST,
        params
    }
}

export function fetchDashboardPayrollResult(data){
    return {
        type: FETCH_DASHBOARD_PAYROLL_TYPES.RESULT,
        data
    }
}

export function fetchDashboardPayrollError(error) {
    return{
        type: FETCH_DASHBOARD_PAYROLL_TYPES.ERROR,
        error
    }
}

export function setDashboardPayrollRequest(params){
    return {
        type: SET_DASHBOARD_PAYROLL_TYPES.REQUEST,
        params
    }
}

export function setDashboardPayrollResult(data){
    return {
        type: SET_DASHBOARD_PAYROLL_TYPES.RESULT,
        data
    }
}

export function setDashboardPayrollError(error) {
    return{
        type: SET_DASHBOARD_PAYROLL_TYPES.ERROR,
        error
    }
}