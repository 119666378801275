import {call, put, takeEvery} from 'redux-saga/effects';
import {UPDATE_USER_DEPARTMENT_TYPES} from '../../constants/types';

import {updateUserDepartmentSuccess, updateUserDepartmentError} from '../../actions/department';
import { authHeader } from '../../helpers/authHeader';

export function* updateUserDepartment(action) {
    const param = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(action.params.department)
    };
    try{
        const response = yield call(fetch, `${process.env.REACT_APP_API_URL}/user/department/${action.params.id}`, param);
        const data = yield call(response.json.bind(response));
        if (response.status === 200) {
            yield put(updateUserDepartmentSuccess(data));
        } else {

        }
    } catch (error) {
        const data = {code: 500, userMessage: 'Internal Server Error'};
        yield put(updateUserDepartmentError(data));
    }
}

export function* watchUpdateUserDepartmentRequest() {

    yield takeEvery(UPDATE_USER_DEPARTMENT_TYPES.REQUEST, updateUserDepartment);
}
