import {
    ADD_PAYOUT_TYPES,
    CLEAR_PAYOUT_ERROR,
    DELETE_PAYOUT_TYPES,
    FETCH_PAYOUT_TYPES,
    UPDATE_PAYOUT_TYPES
} from '../constants/types';

const initialState = {
    payout: null,
    loading: null,
    error: null
};

const payout = (state=initialState, action) => {
    switch (action.type) {
        case FETCH_PAYOUT_TYPES.REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case FETCH_PAYOUT_TYPES.RESULT:
            return {
                ...state,
                loading: false,
                error: null,
                payout: action.data
            };
        case FETCH_PAYOUT_TYPES.ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case UPDATE_PAYOUT_TYPES.REQUEST:
            return {
                ...state,
                error: null,
                loading: false
            };
        case UPDATE_PAYOUT_TYPES.SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                payout: action.data
            };
        case UPDATE_PAYOUT_TYPES.ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case ADD_PAYOUT_TYPES.REQUEST:
            return {
                ...state,
                loading: true
            };
        case ADD_PAYOUT_TYPES.SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case ADD_PAYOUT_TYPES.ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case CLEAR_PAYOUT_ERROR:
            return {
                ...state,
                error: null
            };
        case DELETE_PAYOUT_TYPES.REQUEST:
            return {
                ...state,
                loading: true
            };
        case DELETE_PAYOUT_TYPES.SUCCESS:
            return {
                ...state,
                loading: false
            };
        case DELETE_PAYOUT_TYPES.ERROR:
            return {
                ...state,
                error: action.error
            };
        default:
            return state;
    }
}
export default payout;