import namor from 'namor'
import nanoid from 'nanoid';
import moment from 'moment';
import momentRu from 'moment/locale/ru'; // eslint-disable-line
moment.locale('ru');

const size = 5;

const newPerson = () => {
  const statusChance = Math.random();
  return {
    id: nanoid(size),
    name: namor.generate({ words: 1, numbers: 0 }),
    surname: namor.generate({ words: 1, numbers: 0 }),
    patronymic: namor.generate({ words: 1, numbers: 0 }),
    email: namor.generate({ words: 1, numbers: 0 }) + '@mail.ru',
    fioDestination: namor.generate({ words: 1, numbers: 0 }),
    password: "123qweRTY",
    numberCard: Math.ceil(Math.random() * 9999999999999999),
    bic: Math.ceil(Math.random() * 999999999),
    paymentAccount: Math.ceil(Math.random() * 99999999999999999999),
    role: statusChance > 0.86
        ? 'Администратор'
        : statusChance > 0.66
            ? 'Надзиратель'
            : statusChance > 0.46
                ? 'Тайм-менеджер'
                : 'Менеджер',
    department: [
      {
        departmentName: statusChance > 0.66
            ? 'Руководство'
            : statusChance > 0.33
                ? 'Проектный офис'
                : 'Отдел контроля качества',
        startDateInput: moment(),
        endDateInput: moment().add(1, 'day'),
      }
    ],
    typeSpecialist: [
      {
        typeSpecialist: statusChance > 0.66
            ? 'Junior'
            : statusChance > 0.33
                ? 'Middle'
                : 'Senior',
        specialization: statusChance > 0.66
            ? 'Разработчик'
            : statusChance > 0.33
                ? 'Дизайнер'
                : 'Не задано',
        startDateInput: moment(),
        endDateInput: moment().add(1, 'day'),
      }
    ],
    salary: [
      {
        typeSalary: statusChance > 0.66
            ? 'Оклад'
                : 'Почасовая',
        salary: Math.ceil(Math.random() * 50000),
        startDateInput: moment(),
        endDateInput: moment().add(1, 'day'),
        longDay: 8,
      }
    ],
    redmineId:  statusChance > 0.66 //
        ? {value: 1, label: 'Иванов Иван Иванович'}
        : {value: 2, label: 'Алексеев Алексей Алексеевич'},
    hubstaffId: statusChance > 0.66
        ? {value: 1, label: 'Петров Петр Петрович'}
        : {value: 2, label: 'Александров Александр Александрович'},
    birthday: moment(),
    personalEmail: namor.generate({ words: 1, numbers: 0 }) + '@mail.com',
    skype: namor.generate({ words: 1, numbers: 0 }),
    country: namor.generate({ words: 1, numbers: 0 }),
    city: namor.generate({ words: 1, numbers: 0 }),
    portxDebug: statusChance > 0.66
        ? 'Не задано'
        : Math.ceil(Math.random() * 50000),
    gradeСhanges:  statusChance > 0.66
        ? 'Junior'
        : statusChance > 0.33
            ? 'Senior'
            : 'Не задано',
    startDateWork:  Math.ceil(Math.random() * 50000),
    comment: namor.generate({ words: 1, numbers: 0 }),
    address: namor.generate({ words: 1, numbers: 0 }),
    phone: [{
      phone: '8-800-' + Math.ceil(Math.random() * 999) + Math.ceil(Math.random() * 99)  + Math.ceil(Math.random() * 99)
    }],
    resume: statusChance > 0.20
        ? true
        : statusChance > 0.30
            ? false
            : statusChance > 0.40
                ? false
                : statusChance > 0.50
                    ? true : false,
    competencies:  statusChance > 0.66
        ? 'React'
        : statusChance > 0.33
            ? 'Vue'
            : 'Angular',
    scheduleWork: namor.generate({ words: 1, numbers: 0 }),
    active: statusChance > 0.20
        ? false
        : statusChance > 0.30
            ? true
            : statusChance > 0.40
                ? false
                : true
                ? true : false,
    signedNda:  statusChance > 0.50
        ? true
        : false,
    endTestPeriod: moment().add(1, 'day'),
    framed:  statusChance > 0.86
        ? 'Не оформлен'
        : statusChance > 0.66
            ? 'ИП Бормотов Иван Сергеевич'
            : statusChance > 0.46
                ? 'ИП Мирошников Иван Витальевич'
                : 'ООО "Цифровая палочка"',
    index: Math.ceil(Math.random() * 999999),
    accessRedmine: statusChance > 0.66
      ? true
      : false,
    accessGitLab: statusChance > 0.66
        ? true
        : false,
    accessDevServers: statusChance > 0.66
        ? true
        : false,
    accessPythonProjects: statusChance > 0.66
        ? true
        : false,
    whoBrought: {value: 1, label: namor.generate({ words: 1, numbers: 0 })},
    additionalConditions: namor.generate({ words: 1, numbers: 0 }),
    editing: false,
  }
};

const employeesData = () => {
  const arr = [];
  for(let i = 0; i <= 30; i++){
    arr.push(newPerson());
  }
  return arr;
};

export { employeesData }
