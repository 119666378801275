import {FETCH_ROLE_TYPES} from '../constants/types';

export function fetchRoleRequest(params){
  return {
    type: FETCH_ROLE_TYPES.REQUEST,
    params
  }
}

export function fetchRoleResult(data){
  return {
    type: FETCH_ROLE_TYPES.RESULT,
    data
  }
}

export function fetchRoleError(error) {
  return {
    type: FETCH_ROLE_TYPES.ERROR,
    error
  }
}
