import { leadSourceData } from './LeadSourceData';

const leadSource = (state=leadSourceData, action) => {
    switch(action.type){
        case 'ADD_LEAD_SOURCE':
            return [action.data, ...state];
        case 'UPDATE_LEAD_SOURCE':
            return state.map(source => {
                if(source.id === action.data.id){
                    source = action.data
                }
                return source;
            });
        case 'DELETE_LEAD_SOURCE':
            return state.filter(source => source.id !== action.data);
        default:
            return state;
    }
};
export default leadSource;