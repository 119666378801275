import {call, put, takeEvery} from 'redux-saga/effects';
import {FETCH_MANAGERS_TYPES} from 'constants/types';

import { fetchManagersResult, fetchManagersError } from 'actions/manager';
import { authHeader } from '../../helpers/authHeader';
import moment from 'moment';
moment.locale('ru');

export function* getManagersList(action) {
    const param = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({
            pagination: {
                "page": 1,
                "limit": 100
            },
            //sort: isMultiSort(action.params.sort),
            filter: action.params.filter,
            fields: ["id", "lastName", "firstName", "patronymic"]
        })
    };
    try{
        const response = yield call(fetch, `${process.env.REACT_APP_API_URL}/user/list`, param);
        const data = yield call(response.json.bind(response));
        if (response.status === 200) {
            yield put(fetchManagersResult(data));
        } else {
            yield put(fetchManagersError(data));
        }
    } catch (error) {
        const data = {code: 500, userMessage: 'Internal Server Error'};
        yield put(fetchManagersError(data));
    }
}

export function* watchLoadManagersRequest() {

    yield takeEvery(FETCH_MANAGERS_TYPES.REQUEST, getManagersList);
}