import { ADD_SALARY_TYPES, UPDATE_SALARY_TYPES, DELETE_SALARY_TYPES } from '../constants/types';

export function addSalaryRequest(params) {
    return {
        type: ADD_SALARY_TYPES.REQUEST,
        params
    }
}

export function addSalarySuccess(data) {
    return {
        type: ADD_SALARY_TYPES.SUCCESS,
        data
    }
}

export function addSalaryError(error) {
    return {
        type: ADD_SALARY_TYPES.ERROR,
        error
    }
}

export function updateSalaryRequest(params) {
    return {
        type: UPDATE_SALARY_TYPES.REQUEST,
        params
    }
}

export function updateSalarySuccess(data) {
    return {
        type: UPDATE_SALARY_TYPES.SUCCESS,
        data
    }
}

export function updateSalaryError(error) {
    return {
        type: UPDATE_SALARY_TYPES.ERROR,
        error
    }
}

export function deleteSalaryRequest(params) {
    return {
        type: DELETE_SALARY_TYPES.REQUEST,
        params
    }
}

export function deleteSalarySuccess(data) {
    return {
        type: DELETE_SALARY_TYPES.SUCCESS,
        data
    }
}

export function deleteSalaryError(error) {
    return {
        type: DELETE_SALARY_TYPES.ERROR,
        error
    }
}

