import {activeColumns, hiddenColumns} from './IncomeTableData';

const initialValue = {
  activeColumns: activeColumns,
  hiddenColumns: hiddenColumns
}

const settingIncome = (state=initialValue, action) => {
  switch (action.type) {
    case 'CHANGE_COLUMNS_INCOME':
      return { ...state, activeColumns: action.activeColumns, hiddenColumns: action.hiddenColumns};
    case 'CHANGE_COLUMN_ACTIVE_INCOME':
      return { ...state, activeColumns: action.activeColumns};
    case 'CHANGE_COLUMN_HIDDEN_INCOME':
      return { ...state, hiddenColumns: action.hiddenColumns};
    default:
      return state;
  }
}
export default settingIncome;
