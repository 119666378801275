import {FETCH_SPECIALIZATION_LEVEL_TYPES} from "../constants/types";

export function fetchSpecializationLevelRequest(params){
  return {
    type: FETCH_SPECIALIZATION_LEVEL_TYPES.REQUEST,
    params
  }
}

export function fetchSpecializationLevelResult(data){
  return {
    type: FETCH_SPECIALIZATION_LEVEL_TYPES.RESULT,
    data
  }
}

export function fetchSpecializationLevelError(error) {
  return{
    type: FETCH_SPECIALIZATION_LEVEL_TYPES.ERROR,
    error
  }
}
