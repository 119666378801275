import {call, put, takeEvery} from 'redux-saga/effects';
import {ADD_SALARY_TYPES} from 'constants/types';

import {addSalarySuccess, addSalaryError} from 'actions/salary';
import { authHeader } from '../../helpers/authHeader';

export function* addSalary(action) {
    const param = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(action.params)
    };
    try{
        const response = yield call(fetch, `${process.env.REACT_APP_API_URL}/user/work-payment`, param);
        const data = yield call(response.json.bind(response));
        if (response.status === 200) {
            yield put(addSalarySuccess(data));
        } else if (response.status === 400) {
            yield put(addSalaryError(data));
        } else if (response.status === 401){
            yield put(addSalaryError(data));
        } else if (response.status === 404){
            yield (addSalaryError(data));
        }
    } catch (error) {
        const data = {code: 500, userMessage: 'Internal Server Error'};
        yield put(addSalaryError(data));
    }
}

export function* watchAddSalaryRequest() {
    yield takeEvery(ADD_SALARY_TYPES.REQUEST, addSalary);
}