import {activeColumns, hiddenColumns} from './PayoutTableData';

const initialValue = {
  activeColumns: activeColumns,
  hiddenColumns: hiddenColumns
}

const settingPayout = (state=initialValue, action) => {
  switch (action.type) {
    case 'CHANGE_COLUMNS_PAYOUT':
      return { ...state, activeColumns: action.activeColumns, hiddenColumns: action.hiddenColumns};
    case 'CHANGE_COLUMN_ACTIVE_PAYOUT':
      return { ...state, activeColumns: action.activeColumns};
    case 'CHANGE_COLUMN_HIDDEN_PAYOUT':
      return { ...state, hiddenColumns: action.hiddenColumns};
    default:
      return state;
  }
}
export default settingPayout;
