import moment from 'moment';
moment.locale('ru');

export const map = (exp, array) => array.map(exp);

export const compose = (f, g) => x => f(g(x));

export const dateFormat = (xs) => moment(xs).format("YYYY-MM-DD");

export const dateFormatRange = (xs) => moment(xs).format("DD.MM.YYYY")

export const dateFormatUtc = (xs) => moment(xs).utc().format('YYYY-MM-DDTHH:mm:ss');

export const first = (array) => array.find((v,i) => i === 0);

export const dateIsNull = (date) => date !== null ? dateFormatRange(date) : null

export const dateFormatEvent = (xs) => moment(xs).format("DD.MM.YYYY HH:mm");

export function prettify(num) {
    if(num !== 0){
        var n = num.toString();
        return n.replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, '$1 ');
    } else if(num === 0) {
        return 0;
    }
}
