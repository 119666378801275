import {call, put, takeEvery, all} from 'redux-saga/effects';
import {FETCH_USERS_TYPES} from 'constants/types';

import {fetchUsersResult, fetchUsersError} from 'actions/userList';
import { authHeader } from '../../helpers/authHeader';
import moment from 'moment';
moment.locale('ru');

const lastNameAsc = {
  "property": "lastName",
  "order": "asc"
};

const firstNameAsc = {
  "property": "firstName",
  "order": "asc"
};

const patronymicAsc =  {
  "property": "patronymic",
  "order": "asc"
};

const lastNameDesc = {
  "property": "lastName",
  "order": "desc"
};

const firstNameDesc = {
  "property": "firstName",
  "order": "desc"
};

const patronymicDesc =  {
  "property": "patronymic",
  "order": "desc"
}

function isSort(sort) {
  if(sort){
    let {sortName, sortOrder} = sort;
    if(sortName && sortOrder){
      if(sortName === 'fio'){
        if(sortOrder === 'asc'){
          return {
            lastNameAsc, firstNameAsc, patronymicAsc
          }
        } else if(sortOrder){
          return {
            lastNameDesc, firstNameDesc, patronymicDesc
          }
        }
      } else {
        return [{
          "property": sortName,
          "order": sortOrder
        }]
      }
    }
  }
}

function isFilter(filter){
  if(filter){
    const { department } = filter;
    const result = {
      ...filter,
      //role: (role && role.length > 0) ? role.map(r => r.value): undefined,
      //department: department ? { id: department } : undefined
      ...department ? {currentDepartmentId: department} : undefined
    }
    delete result.department
    return result;
  }
}

const argPage = (page, params) => {
  return {
    ...params,
    pagination: { page, limit: 100 }
  }
}

export function* getUsers(params) {
  console.log("filter", isFilter(params.filter))
  const param = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      pagination: params.pagination,
      sort: isSort(params.sort), //_.uniqBy(data, 'id')
      filter: isFilter(params.filter),
      fields: params.fields
    })
  };
  try{
    const response = yield call(fetch, `${process.env.REACT_APP_API_URL}/user/list`, param);
    const data = yield call(response.json.bind(response));
    if (response.status === 200) {
      return data
      //yield put(fetchUsersResult(data));
    } else {
      yield put(fetchUsersError(data));
    }
  } catch (error) {
    const data = {code: 500, userMessage: 'Internal Server Error'};
    yield put(fetchUsersError(data));
  }
}

export function* getUsersList(action) {
  try{
    const { pagination: { limit } } = action.params
    if(limit > 100){
      const pageCount = Math.ceil(limit/100) + 1;
      let pages = Array.from(Array(pageCount).keys());
      pages.splice(0, 1);
      const [...users] = yield all(pages.map(page => call(getUsers, argPage(page, action.params))))
      const result = users
          .reduce((res, v) => [...res, v.result], [])
          .flatMap(v => v)
      const data = { result, pagination: { page: 1, limit: result.length, count: result.length } }
      yield put(fetchUsersResult(data))
    } else {
      const data = yield call(getUsers, action.params)
      yield put(fetchUsersResult(data))
    }
  } catch (error) {
    console.error("Error get users", error)
    const data = {code: 500, userMessage: 'Internal Server Error'};
    yield put(fetchUsersError(data));
  }
}

export function* watchLoadUsersRequest() {

  yield takeEvery(FETCH_USERS_TYPES.REQUEST, getUsersList);
}
