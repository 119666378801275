import {call, put, takeEvery} from 'redux-saga/effects';
import {VIEW_CALENDAR_TYPES} from 'constants/types';
import {viewCalendarResult, viewCalendarError} from 'actions/calendar';
import { authHeader } from '../../helpers/authHeader';
import moment from 'moment';
moment.locale('ru');

export function* getEvent({ params: { id } }) {
    const param = {
        method: 'GET',
        headers: authHeader(),
    };
    try {
        const response = yield call(fetch, `${process.env.REACT_APP_API_URL}/user/day-off/${id}`, param);
        const data = yield call(response.json.bind(response));
        if (response.status === 200) {
            yield put(viewCalendarResult(data));
        } else {
            yield put(viewCalendarError(data));
        }
    } catch (error) {
        const data = {code: 500, userMessage: 'Internal Server Error'};
        yield put(viewCalendarError(data));
    }
}

export function* watchLoadEventCalendarRequest() {
    yield takeEvery(VIEW_CALENDAR_TYPES.REQUEST, getEvent);
}
