import {
    FETCH_DEPARTMENTS_TYPES,
} from '../constants/types';

const initialState = {
    department: null,
    error: null,
    loading: null
};

const departments = (state=initialState, action) => {
    switch (action.type) {
        case FETCH_DEPARTMENTS_TYPES.REQUEST:
            return {
                ...state,
                loading: true
            };
        case FETCH_DEPARTMENTS_TYPES.RESULT:
            return {
                ...state,
                loading: false,
                department: action.data,
                params: action.params,
            };
        case FETCH_DEPARTMENTS_TYPES.ERROR:
            return {
                ...state,
                error: action.error
            };
        default:
            return state;
    }
}
export default departments;
