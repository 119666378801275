const COLUMNS = [
  {
    id: 1,
    dataField: "fio",
    title: "ФИО"
  },
  {
    id: 2,
    dataField: "paymentDetails",
    title: "Платежные реквизиты"
  },
  {
    id: 3,
    dataField: "typeSpecialist",
    title: "Уровень"
  },
  {
    id: 4,
    dataField: "specialization",
    title: "Специализация"
  },
  {
    id: 5,
    dataField: "salary",
    title: "Зп/Cтоимость часа"
  },
  {
    id: 6,
    dataField: "department",
    title: "Отдел"
  },
  {
    id: 7,
    dataField: "id",
    title: "ID"
  },
  {
    id: 8,
    dataField: "email",
    title: "Email"
  },
  {
    id: 9,
    dataField: "personalEmail",
    title: "Личный email"
  },
  {
    id: 10,
    dataField: "skype",
    title: "Skype"
  },
  {
    id: 11,
    dataField: "xdebugPort",
    title: "Порт xDebug"
  },
  {
    id: 12,
    dataField: "isActive",
    title: "Активен?"
  },
  {
    id: 13,
    dataField: "isNdaSigned",
    title: "NDA подписан"
  },
  {
    id: 14,
    dataField: "officialEmploymentId",
    title: "Оформлен"
  },
  {
    id: 15,
    dataField: "role",
    title: "Роль"
  },
  {
    id: 16,
    dataField: "redmineId",
    title: "Redmine ID"
  },
  {
    id: 17,
    dataField: "hubstaffId",
    title: "Hubstaff ID"
  },
  {
    id: 18,
    dataField: "dateOfBirth",
    title: "День рождения"
  },
  {
    id: 19,
    dataField: "country",
    title: "Страна"
  },
  {
    id: 20,
    dataField: "gradeСhanges",
    title: "Изменения грейдов"
  },
  {
    id: 21,
    dataField: "startDateWork",
    title: "Дата начала работы"
  },
  {
    id: 22,
    dataField: "comment",
    title: "Комментарии"
  },
  {
    id: 23,
    dataField: "address",
    title: "Адрес"
  },
  {
    id: 24,
    dataField: "phones",
    title: "Телефоны"
  },
  {
    id: 25,
    dataField: "resume",
    title: "Резюме"
  },
  {
    id: 26,
    dataField: "workSchedule",
    title: "График работы"
  },
  {
    id: 27,
    dataField: "endOfProbationPeriod",
    title: "Окончание испытательного срока"
  },
  {
    id: 28,
    dataField: "city",
    title: "Город"
  }
];

const DEFAULT_ACTIVE = ['fio', 'paymentDetails', 'typeSpecialist', 'specialization', 'salary', 'department'];

const STORAGE_VARIABLE = 'USER_TABLE_SETTINGS';

const formatColumns = (columns, active) => {
  const result = active.reduce((result, dataField) => {
    const column = columns.find(c => c.dataField === dataField);
    return column ? { ...result, activeColumns: [...result.activeColumns, column] } : result;
  }, {
    activeColumns: [],
    hiddenColumns: [],
  });

  result.hiddenColumns = columns.filter(c => active.indexOf(c.dataField) === -1);

  return result;
};

const getStorageSettings = (columns, defaultActive) => {
  try {
    const storedActive = JSON.parse(window.localStorage.getItem(STORAGE_VARIABLE));

    if (!Array.isArray(storedActive) || storedActive.length === 0 || storedActive.findIndex(k => typeof k !== 'string') !== -1) {
      throw storedActive;
    }
    return formatColumns(columns, storedActive);
  } catch (e) {
    return formatColumns(columns, defaultActive);
  }
};

export const setStorageActive = active => {
  try {
    if (!Array.isArray(active) || active.findIndex(k => typeof k !== 'string') !== -1) {
      throw active;
    }

    window.localStorage.setItem(STORAGE_VARIABLE, JSON.stringify(active));

    return true;
  } catch (e) {
    return false;
  }
};

export const setStorageHidden = hidden => {
  try {
    if (!Array.isArray(hidden) || hidden.findIndex(k => typeof k !== 'string') !== -1) {
      throw hidden;
    }

    window.localStorage.setItem(
        STORAGE_VARIABLE,
        JSON.stringify(COLUMNS)
            .filter(({ dataField }) => hidden.indexOf(dataField) === -1)
            .map(({ dataField }) => dataField),
    );

    return true;
  } catch (e) {
    return false;
  }
};

export const {
  activeColumns,
  hiddenColumns,
} = getStorageSettings(COLUMNS, DEFAULT_ACTIVE);

