import { financialReportData } from './financialReportDate';

const initialState = financialReportData();

const financialReport = (state=initialState, action) => {
    switch(action.type){
        default:
            return state;
    }
}
export default financialReport;
