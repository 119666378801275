import {call, put, takeEvery} from 'redux-saga/effects';
import {DELETE_CALENDAR_TYPES} from 'constants/types';
import {deleteCalendarResult, deleteCalendarError} from 'actions/calendar';
import { authHeader } from '../../helpers/authHeader';
import moment from 'moment';

moment.locale('ru');

export function* deleteEvent(action) {
    const param = {
        method: 'DELETE',
        headers: authHeader(),
    };
    try{
        const response = yield call(fetch, `${process.env.REACT_APP_API_URL}/user/day-off/${action.params.id}`, param);
        const data = yield call(response.json.bind(response));
        if (response.status === 200) {
            yield put(deleteCalendarResult(action.params));
        } else {
            yield put(deleteCalendarError(data));
        }
    } catch (error) {
        const data = {code: 500, userMessage: 'Internal Server Error'};
        yield put(deleteCalendarError(data));
    }
}

export function* watchDeleteCalendarRequest() {
    yield takeEvery(DELETE_CALENDAR_TYPES.REQUEST, deleteEvent);
}
