import {call, put, takeEvery, all} from 'redux-saga/effects';
import {FETCH_PAYSHEET_TYPES} from 'constants/types';

import {fetchPaysheetResult, fetchPaysheetError} from 'actions/paysheet';
import { authHeader } from '../../helpers/authHeader';
import {dateFormat} from '../../helpers/helpers';
import {fioSortAsc, fioSortDesc} from '../../constants/sortParams';
import moment from 'moment';
moment.locale('ru');

const argSalary = (page, id) => {
    return {
        pagination: { page: page, limit: 50},
        sort: [
            {
                "property": "start",
                "order": "asc"
            }
        ],
        filter: {
            date: dateFormat(moment()),
            userId: id
        }
    }
}

function isSortFio(params){
    if(params){
        if(params.sort.sortOrder[0] === "asc"){
            return fioSortAsc;
        } else if(params.sort.sortOrder[0] === "desc") {
            return fioSortDesc;
        }
        else {
            return fioSortAsc;
        }
    }
}

export function* fetchWorkPayment(params) {
    const param = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({
            pagination: params.pagination,
            sort: params.sort,
            filter: params.filter,
        })
    };
    try{
        const response = yield call(fetch, `${process.env.REACT_APP_API_URL}user/work-payment/list`, param);
        const data = yield call(response.json.bind(response));
        if (response.status === 200) {
            return data;
        } else if (response.status === 400) {
            return {workPayment: [], error: true}
        } else if(response.status === 401) {
            return {workPayment: [], error: true}
        } else if(response.status === 404){
            return {workPayment: [], error: true}
        }
    }
    catch (error) {
        return {workPayment: [], error: true};
    }
}

export function* getPaysheetList(action) {
    const param = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({
            pagination: {
                "page": 1,
                "limit": 100
            },
            filter: {
                "isActive": true,
            },
            sort: isSortFio(action.params) || fioSortAsc
        })
    };
    try{
        const response = yield call(fetch, `${process.env.REACT_APP_API_URL}user/list`, param);
        const data = yield call(response.json.bind(response));
        if (response.status === 200) {
            let ids = data.result.map(user => user.id);

            const workPaymentResult = yield call(fetchWorkPayment, argSalary(1, ids));
            let { result: workPayments, pagination: { count: countWorkPayment, limit: limitWorkPayment }} = workPaymentResult;
            const pageCountWorkPayment = Math.ceil( countWorkPayment/limitWorkPayment) + 1;

            if(countWorkPayment > workPayments.length){
                let pages = Array.from(Array(pageCountWorkPayment).keys());
                pages.splice(0, 2);
                const [...results] = yield all(pages.map(page => call(fetchWorkPayment, argSalary(page, ids))));
                workPayments = results.reduce((res, v) => [ ...res, ...v.result], workPayments);
            }

            data.result = data.result.map(user => ({
                ...user,
                salary: workPayments.filter(v => v.user.id === user.id)
            }));
            yield put(fetchPaysheetResult(data));
        } else if (response.status === 400) {
            yield put(fetchPaysheetError(data));
        } else if(response.status === 401) {
            yield put(fetchPaysheetError(data));
        } else if(response.status === 404){
            yield put(fetchPaysheetError(data));
        }
    } catch (error) {
        const data = {code: 500, userMessage: 'Internal Server Error'};
        yield put(fetchPaysheetError(data));
    }
}

export function* watchLoadPaysheetRequest() {

    yield takeEvery(FETCH_PAYSHEET_TYPES.REQUEST, getPaysheetList);
}
