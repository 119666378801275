import {ADD_PHONE_TYPES, DELETE_PHONE_TYPES, UPDATE_PHONE_TYPES} from '../constants/types';

const initialState = {
  phone: null,
  loading: null,
  error: null
};

const phone = (state=initialState, action) => {
  switch (action.type) {
    case ADD_PHONE_TYPES.REQUEST:
        return {
          ...state,
          loading: true
        };
    case ADD_PHONE_TYPES.SUCCESS:
      return {
        ...state,
        loading: false
      };
    case ADD_PHONE_TYPES.ERROR:
      return  {
        ...state,
        loading: false,
        error: action.error
      };
    case DELETE_PHONE_TYPES.REQUEST:
      return {
        ...state,
        loading: true
      };
    case DELETE_PHONE_TYPES.SUCCESS:
      return {
        ...state,
        loading: false
      };
    case DELETE_PHONE_TYPES.ERROR:
      return {
        ...state,
        error: action.error
      };
    case UPDATE_PHONE_TYPES.REQUEST:
      return {
        ...state,
        loading: true
      };
    case UPDATE_PHONE_TYPES.SUCCESS:
      return {
        ...state,
        loading: false
      };
    case UPDATE_PHONE_TYPES.ERROR:
      return  {
        ...state,
        loading: false,
        error: action.error
      };
    default:
      return state;
  }
}
export default phone;
