import React from 'react';
import {call, put, takeEvery, all} from 'redux-saga/effects';
import { toast } from 'react-toastify';
import {ADD_USER_TYPES} from 'constants/types';
import Message from 'components/Message';

import {addUserSuccess, addUserError} from 'actions/user';
import { authHeader } from '../../helpers/authHeader';
import { METHOD } from 'constants/method';
import { INTERNAL_ERROR_CODES } from 'constants/errorCodes';

const setParam = (method, param) => {
  return {
      method: method,
      headers: authHeader(),
      body: JSON.stringify(param)
}}

export function* addUser(action) {
  const { paymentDetails, ...user } = action.params.user;
  const { phone, salary, typeSpecialist, department, certification } = action.params;
  const param = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(user)
  };

  const errors = [];
    const response = yield call(fetch, `${process.env.REACT_APP_API_URL}/user`, param);
    const data = yield call(response.json.bind(response));

    if (response.status === 200) {
      const userId = data.id;

      if (paymentDetails) {
        const paramPaymentDetails = {
          method: 'PUT',
          headers: authHeader(),
          body: JSON.stringify(paymentDetails),
        };

        const res = yield call(fetch, `${process.env.REACT_APP_API_URL}/user/${data.id}/payment-details`, paramPaymentDetails);
        const d = yield call(res.json.bind(res));

        if (res.status !== 200) {
          errors.push(d);
        }
      }

      if(phone && phone.length > 0){
        const [...res] = yield all(phone.map(p =>
            call(fetch, `${process.env.REACT_APP_API_URL}/user/phone`, setParam(METHOD.POST, {...p, userId}))))
        const errorsPhone = res.filter(r => r.status !== 200)
        const [ ...d ] = yield all(errorsPhone.map(e => call(e.json.bind(e))));

        if (errors) {
          errors.push(...d);
        }
      }
      if(salary && salary.length > 0){
        const [...res] = yield all(salary.map(s =>
            call(fetch, `${process.env.REACT_APP_API_URL}/user/work-payment`, setParam(METHOD.POST, {...s, userId}))));
        const errorsSalary = res.filter(r => r.status !== 200);
        const [ ...ds ] = yield all(errorsSalary.map(e => call(e.json.bind(e))));

        if (errors) {
          errors.push(...ds);
        }
      }
      if (typeSpecialist && typeSpecialist.length > 0){
        const [...res] = yield all(typeSpecialist.map(t =>
            call(fetch, `${process.env.REACT_APP_API_URL}/user/specialization`, setParam(METHOD.POST, {...t, userId, status: (!!t.status && t.status) || 'default'}))))
        const errorsSpecialization = res.filter(r => r.status !== 200)
        const [ ...dsp ] = yield all(errorsSpecialization.map(e => call(e.json.bind(e))))

        if (errors) {
          errors.push(...dsp);
        }
      }
      if (department && department.length > 0){
        const [...res] = yield all(department.map(d =>
            call(fetch, `${process.env.REACT_APP_API_URL}/user/department`, setParam(METHOD.POST, {...d, userId}))))
        const errorsDepartment = res.filter(r => r.status !== 200)
        const [ ...dd ] = yield all(errorsDepartment.map(e => call(e.json.bind(e))))

        if (errors) {
          errors.push(...dd);
        }
      }
      if (certification && certification.length > 0){
        const [...res] = yield all(certification.map(c =>
            call(fetch, `${process.env.REACT_APP_API_URL}/user/certification`, setParam(METHOD.POST, {...c, userId}))))
        const errorsСertification = res.filter(r => r.status !== 200)
        const [ ...dc ] = yield all(errorsСertification.map(e => call(e.json.bind(e))))

        if (errors) {
          errors.push(...dc);
        }
      }

      if (errors.length > 0) {
        const validationErrors = errors.filter(e => e.internalCode === INTERNAL_ERROR_CODES.VALIDATION_FAILED);
        const otherErrors = errors.filter(e => e.internalCode !== INTERNAL_ERROR_CODES.VALIDATION_FAILED);

        if (validationErrors.length > 0) {
          yield put(addUserError({
            ...validationErrors.reduce((result, cur) => ({
              ...result,
              internalCode: INTERNAL_ERROR_CODES.VALIDATION_FAILED,
              errors: [
                ...(result.errors || []),
                ...(cur.errors || []),
              ],
            }), {}),
            userId,
          }));
        } else {
          yield put(addUserError({
            ...otherErrors[0],
            userId,
          }));
          for (let i = 1; i < otherErrors.length; i++) {
            toast.error(<Message error={otherErrors[i]} />);
          }
        }
      }
      //window.history.back();
      yield put(addUserSuccess(data));
    } else {
      yield put(addUserError(data));
    }
}

export function* watchAddUserRequest() {
  yield takeEvery(ADD_USER_TYPES.REQUEST, addUser);
}
/*
if(action.params.phone && action.params.phone.length > 0){
        let { phone } = action.params;
        phone = phone.map(p => {return {...p, userId }});
        yield all(phone.map(phone => put(addPhoneRequest(phone))));
      }
      if(action.params.salary && action.params.salary.length > 0 ) {
        let { salary } = action.params;
        salary = salary.map(s => {return {...s, userId }});
        yield all(salary.map(salary => put(addSalaryRequest(salary))));
      }
      if(action.params.typeSpecialist && action.params.typeSpecialist.length > 0) {
        let { typeSpecialist } = action.params;
        typeSpecialist = typeSpecialist.map(t => {return {...t, userId }});
        yield all(typeSpecialist.map(typeSpecialist => put(addSpecializationRequest(typeSpecialist))));
      }
      if(action.params.department && action.params.department.length > 0){
        let { department } = action.params;
        department = department.map(d => ({...d, userId }))
        yield all(department.map(department => put(addUserDepartmentRequest(department))));
      }
      if(action.params.certification && action.params.certification.length > 0){
        let { certification } = action.params;
        certification = certification.map(d => ({...d, userId }))
        yield all(certification.map(certification => put(addUserCertificationsRequest(certification))));
      }
 */
