import { FETCH_MANAGERS_TYPES } from '../constants/types';

export function fetchManagersRequest(params){
    return {
        type: FETCH_MANAGERS_TYPES.REQUEST,
        params
    }
}

export function fetchManagersResult(data){
    return {
        type: FETCH_MANAGERS_TYPES.RESULT,
        data
    }
}

export function fetchManagersError(error) {
    return{
        type: FETCH_MANAGERS_TYPES.ERROR,
        error
    }
}