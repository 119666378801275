import {ADD_SALARY_TYPES, UPDATE_SALARY_TYPES, DELETE_SALARY_TYPES} from '../constants/types';

const initialState = {
    salary: null,
    loading: null,
    error: null
};

const salary = (state=initialState, action) => {
    switch (action.type) {
        case ADD_SALARY_TYPES.REQUEST:
            return {
                ...state,
                loading: true
            };
        case ADD_SALARY_TYPES.SUCCESS:
            return {
                ...state,
                loading: false
            };
        case ADD_SALARY_TYPES.ERROR:
            return {
                ...state,
                error: action.error
            };
        case UPDATE_SALARY_TYPES.REQUEST:
            return {
                ...state,
                loading: true
            };
        case UPDATE_SALARY_TYPES.SUCCESS:
            return {
                ...state,
                loading: false
            };
        case UPDATE_SALARY_TYPES.ERROR:
            return {
                ...state,
                error: action.error
            };
        case DELETE_SALARY_TYPES.REQUEST:
            return {
                ...state,
                loading: true
            };
        case DELETE_SALARY_TYPES.SUCCESS:
            return {
                ...state,
                loading: false
            };
        case DELETE_SALARY_TYPES.ERROR:
            return {
                ...state,
                error: action.error
            };
        default:
            return state;
    }
}
export default salary;