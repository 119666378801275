import nanoid from 'nanoid';
import moment from 'moment';
moment.locale('ru');

const size = 5;

const newTransfer = () => {
  const statusChance = Math.random();
  return {
    id: nanoid(size),
    fromAccount: statusChance > 0.66
        ? { value: 1, label: 'ООО "Цифровая Палочка"' }
        : statusChance > 0.33
            ? { value: 2, label: 'Бормотов (евро)' }
            : { value: 3, label: 'Бормотов (рубли)' },
    toAccount: statusChance > 0.66
        ? { value: 1, label: 'ООО "Цифровая Палочка"' }
        : statusChance > 0.33
            ? { value: 2, label: 'Cолопов' }
            : { value: 3, label: 'Бормотов (label)' },
    writeOffAmount: Math.random() * 50000,
    accrualAmount: Math.random() * 50000,
    date: moment(),
    comment: statusChance > 0.66
        ? 'Не задано'
        : statusChance > 0.33
            ? 'Бормотов (евро)'
            : 'Бормотов (рубли)',
  }
};

const transfersData = () => {
  const arr = [];
  for(let i = 0; i <= 30; i++){
    arr.push(newTransfer());
  }
  return arr;
};

export { transfersData }
