import { call, put, takeEvery } from 'redux-saga/effects';
import { FETCH_REDMINE_USERS_TYPES } from 'constants/types';
import { fetchRedmineUsersResult, fetchRedmineUsersError } from 'actions/redmineUser';
import { authHeader } from '../../helpers/authHeader';
import moment from 'moment';
moment.locale('ru');

export function* getRedmineUsersList(action) {
    const param = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({
            pagination: {
                "page": 1,
                "limit": 10,
            },
            filter: action.params && action.params.filter,
        })
    };
    try {
        const response = yield call(fetch, `${process.env.REACT_APP_API_URL}/redmine/user/list`, param);
        const data = yield call(response.json.bind(response));
        if (response.status === 200) {
            const {
                result: redmineUser,
            } = data;
            yield put(fetchRedmineUsersResult(redmineUser));
        } else {
            yield put(fetchRedmineUsersError(data));
        }
    } catch (error) {
        const data = {code: 500, userMessage: 'Internal Server Error'};
        yield put(fetchRedmineUsersError(data));
    }
}

export function* watchLoadRedmineUsersRequest() {
    yield takeEvery(FETCH_REDMINE_USERS_TYPES.REQUEST, getRedmineUsersList);
}
