import {all, call, put, takeEvery} from 'redux-saga/effects';
import { SET_DASHBOARD_PAYROLL_TYPES } from 'constants/types';
//import { API_URL } from 'utils';
import { setDashboardPayrollResult, setDashboardPayrollError } from 'actions/dashboard';
import { authHeader } from '../../helpers/authHeader';

export function* dashboardPayroll(params){
    const param = {
        method: 'POST',
        headers: authHeader(),
    };
    try{
        const response = yield call(
            fetch,
            `${process.env.REACT_APP_API_URL}/dashboard/payroll/corrections/${params.year}/${params.month}/${params.id}/apply`,
            param);
        const data = yield call(response.json.bind(response));
        if (response.status === 200) {
            yield put(setDashboardPayrollResult(data));
        } else {
            yield put(setDashboardPayrollError(data));
        }
    } catch (error) {
        const data = {code: 500, userMessage: 'Internal Server Error'};
        yield put(setDashboardPayrollError(data));
    }
}

export function* setDashboardPayroll(action) {
    yield all(action.params.map(v => dashboardPayroll(v)))
}

export function* watchLoadSetDashboardPayrollRequest() {
    yield takeEvery(SET_DASHBOARD_PAYROLL_TYPES.REQUEST, setDashboardPayroll);
}