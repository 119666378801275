import { call, put, takeEvery } from 'redux-saga/effects';
import { FETCH_DASHBOARD_DEPARTMENTS_TYPES } from 'constants/types';
//import { API_URL } from 'utils';
import { fetchDashboardDepartmentsResult, fetchDashboardDepartmentsError } from 'actions/dashboard';
import { authHeader } from '../../helpers/authHeader';

export function* getDashboardDepartments(action) {
    const param = {
        method: 'GET',
        headers: authHeader(),
    };
    try{
        const response = yield call(fetch,
            `${process.env.REACT_APP_API_URL}/dashboard/departments/${action.params.year}/${action.params.month}`,
            param);
        const data = yield call(response.json.bind(response));
        if (response.status === 200) {
            yield put(fetchDashboardDepartmentsResult(data));
        } else {
            yield put(fetchDashboardDepartmentsError(data));
        }
    } catch (error) {
        const data = {code: 500, userMessage: 'Internal Server Error'};
        yield put(fetchDashboardDepartmentsError(data));
    }
}

export function* watchLoadDashboardDepartmentsRequest() {
    yield takeEvery(FETCH_DASHBOARD_DEPARTMENTS_TYPES.REQUEST, getDashboardDepartments);
}
