import {call, put, takeEvery} from 'redux-saga/effects';
import {FETCH_SPECIALIZATIONS_TYPES} from 'constants/types';
import {fetchSpecializationsResult, fetchSpecializationsError} from '../../actions/specialization';
import { authHeader } from '../../helpers/authHeader';

export function* getSpecializationsList() {
  const param = {
    method: 'GET',
    headers: authHeader(),
  };

  try{
    const response = yield call(fetch, `${process.env.REACT_APP_API_URL}/specialization/list`, param);
    const data = yield call(response.json.bind(response));
    if (response.status === 200) {
      yield put(fetchSpecializationsResult(data));
    } else if (response.status === 400) {
      yield put(fetchSpecializationsError(data));
    } else if(response.status === 401) {
      yield put(fetchSpecializationsError(data));
    } else if(response.status === 404){
      yield put(fetchSpecializationsError(data));
    }
  } catch (error) {
    const data = {code: 500, userMessage: 'Internal Server Error'};
    yield put(fetchSpecializationsError(data));
  }
}

export function* watchLoadSpecializationsRequest() {
  yield takeEvery(FETCH_SPECIALIZATIONS_TYPES.REQUEST, getSpecializationsList);
}
