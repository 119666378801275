import {
    ADD_EVENT_TYPES,
    CLEAR_EVENT_ERROR,
    FETCH_EVENT_TYPES,
    UPDATE_EVENT_TYPES
} from '../constants/types';

const initialState = {
    event: null,
    loading: null,
    error: null
};

const entity = (state=initialState, action) => {
    switch (action.type) {
        case FETCH_EVENT_TYPES.REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case FETCH_EVENT_TYPES.RESULT:
            return {
                ...state,
                loading: false,
                error: null,
                event: action.data
            };
        case FETCH_EVENT_TYPES.ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case UPDATE_EVENT_TYPES.REQUEST:
            return {
                ...state,
                error: null,
                loading: false
            };
        case UPDATE_EVENT_TYPES.SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                entity: action.data
            };
        case UPDATE_EVENT_TYPES.ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case ADD_EVENT_TYPES.REQUEST:
            return {
                ...state,
                loading: true
            };
        case ADD_EVENT_TYPES.SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case ADD_EVENT_TYPES.ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case CLEAR_EVENT_ERROR:
            return {
                ...state,
                error: null
            };
        default:
            return state;
    }
};
export default entity;
