import {
    FETCH_CALENDAR_TYPES,
    ADD_CALENDAR_TYPES,
    UPDATE_CALENDAR_TYPES,
    DELETE_CALENDAR_TYPES,
    VIEW_CALENDAR_TYPES,
} from '../constants/types';

export function fetchCalendarRequest(params){
    return {
        type: FETCH_CALENDAR_TYPES.REQUEST,
        params
    }
}

export function fetchCalendarResult(data){
    return {
        type: FETCH_CALENDAR_TYPES.SUCCESS,
        data
    }
}

export function fetchCalendarError(error) {
    return{
        type: FETCH_CALENDAR_TYPES.ERROR,
        error
    }
}

export function addCalendarRequest(params){
    return {
        type: ADD_CALENDAR_TYPES.REQUEST,
        params
    }
}

export function addCalendarResult(data){
    return {
        type: ADD_CALENDAR_TYPES.SUCCESS,
        data
    }
}

export function addCalendarError(error) {
    return{
        type: ADD_CALENDAR_TYPES.ERROR,
        error
    }
}

export function updateCalendarRequest(params){
    return {
        type: UPDATE_CALENDAR_TYPES.REQUEST,
        params
    }
}

export function updateCalendarResult(data){
    return {
        type: UPDATE_CALENDAR_TYPES.SUCCESS,
        data
    }
}

export function updateCalendarError(error) {
    return{
        type: UPDATE_CALENDAR_TYPES.ERROR,
        error
    }
}

export function deleteCalendarRequest(params){
    return {
        type: DELETE_CALENDAR_TYPES.REQUEST,
        params
    }
}

export function deleteCalendarResult(data){
    return {
        type: DELETE_CALENDAR_TYPES.SUCCESS,
        data
    }
}

export function deleteCalendarError(error) {
    return{
        type: DELETE_CALENDAR_TYPES.ERROR,
        error
    }
}

export function viewCalendarRequest(params){
    return {
        type: VIEW_CALENDAR_TYPES.REQUEST,
        params
    }
}

export function viewCalendarResult(data){
    return {
        type: VIEW_CALENDAR_TYPES.SUCCESS,
        data
    }
}

export function viewCalendarError(error) {
    return{
        type: VIEW_CALENDAR_TYPES.ERROR,
        error
    }
}