import {
    FETCH_WELCOME_LETTER_TYPES,
    FETCH_WELCOME_LETTER_PLACEHOLDERS_TYPES,
    SEND_WELCOME_LETTER_TYPES,
    UPDATE_WELCOME_LETTER_TYPES
} from '../../constants/welcomеletter/welcomeletter';

export function fetchWelcomeLetterRequest(params){
    return {
        type: FETCH_WELCOME_LETTER_TYPES.REQUEST,
        params
    }
}

export function fetchWelcomeLetterResult(data){
    return {
        type: FETCH_WELCOME_LETTER_TYPES.RESULT,
        data
    }
}

export function fetchWelcomeLetterError(error) {
    return{
        type: FETCH_WELCOME_LETTER_TYPES.ERROR,
        error
    }
}

export function fetchWelcomeLetterPlaceholdersRequest(params){
    return {
        type: FETCH_WELCOME_LETTER_PLACEHOLDERS_TYPES.REQUEST,
        params
    }
}

export function fetchWelcomeLetterPlaceholdersResult(data){
    return {
        type: FETCH_WELCOME_LETTER_PLACEHOLDERS_TYPES.RESULT,
        data
    }
}

export function fetchWelcomeLetterPlaceholdersError(error) {
    return{
        type: FETCH_WELCOME_LETTER_PLACEHOLDERS_TYPES.ERROR,
        error
    }
}

export function sendWelcomeLetterRequest(params){
    return {
        type: SEND_WELCOME_LETTER_TYPES.REQUEST,
        params
    }
}

export function sendWelcomeLetterResult(data){
    return {
        type: SEND_WELCOME_LETTER_TYPES.SUCCESS,
        data
    }
}

export function sendWelcomeLetterError(error) {
    return {
        type: SEND_WELCOME_LETTER_TYPES.ERROR,
        error
    }
}

export function updateWelcomeLetterRequest(params){
    return {
        type: UPDATE_WELCOME_LETTER_TYPES.REQUEST,
        params
    }
}

export function updateWelcomeLetterSuccess(data){
    return {
        type: UPDATE_WELCOME_LETTER_TYPES.SUCCESS,
        data
    }
}

export function updateWelcomeLetterError(error) {
    return {
        type: UPDATE_WELCOME_LETTER_TYPES.ERROR,
        error
    }
}