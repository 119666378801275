export const FETCH_MASQUERADE_AUTH_TYPES = {
    REQUEST: 'FETCH_MASQUERADE_AUTH_REQUEST',
    SUCCESS: 'FETCH_MASQUERADE_AUTH_SUCCESS',
    ERROR: 'FETCH_MASQUERADE_AUTH_ERROR'
};

export const FETCH_MASQUERADE_ROLLBACK_TYPES = {
    REQUEST: 'FETCH_MASQUERADE_ROLLBACK_REQUEST',
    SUCCESS: 'FETCH_MASQUERADE_ROLLBACK_SUCCESS',
    ERROR: 'FETCH_MASQUERADE_ROLLBACK_ERROR'
};

export const FETCH_MASQUERADE_STATUS_TYPES = {
    REQUEST: 'FETCH_MASQUERADE__STATUS_REQUEST',
    SUCCESS: 'FETCH_MASQUERADE__STATUS_SUCCESS',
    ERROR: 'FETCH_MASQUERADE_STATUS_ERROR'
};