import {call, put, takeEvery} from 'redux-saga/effects';
import {UPDATE_DEPARTMENT_TYPES} from '../../constants/types';

import {updateDepartmentSuccess, updateDepartmentError} from '../../actions/department';
import { authHeader } from '../../helpers/authHeader';

export function* updateDepartment(action) {
    console.log(action)
    const param = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(action.params.department)
    };
    try{
        const response = yield call(fetch, `${process.env.REACT_APP_API_URL}/department/${action.params.id}`, param);
        const data = yield call(response.json.bind(response));
        if (response.status === 200) {
            yield put(updateDepartmentSuccess(data));
        } else {
            yield put(updateDepartmentError(data));
        }
    } catch (error) {
        const data = {code: 500, userMessage: 'Internal Server Error'};
        yield put(updateDepartmentError(data));
    }
}

export function* watchUpdateDepartmentRequest() {

    yield takeEvery(UPDATE_DEPARTMENT_TYPES.REQUEST, updateDepartment);
}
