export const activeColumns = [
  {
    id: 1,
    dataField: "name",
    title: "Название"
  },
  {
    id: 2,
    dataField: "requisite",
    title: "Реквизиты"
  },
];

export const hiddenColumns = [
  {
    id: 3,
    dataField: "id",
    title: "ID"
  },
];
