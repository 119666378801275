import {call, put, takeEvery} from 'redux-saga/effects';
import {FETCH_INCOMES_TYPES} from 'constants/types';

import {fetchIncomesResult, fetchIncomesError} from 'actions/income';
import { authHeader } from '../../helpers/authHeader';
import {dateIsNull} from '../../helpers/helpers';
import moment from 'moment';
moment.locale('ru');

function isMultiSort(sort) {
    if(sort){
        const {sortName, sortOrder} = sort;
        let sortied = sortName.map((name,i) => {
            return {
                "property": name,
                "order": sortOrder[i]
            }
        });
        return sortied;
    }
}

function isFilter(filter) {
    if(filter) {
        const {
            startDate, endDate, startPlannedDate, endPlannedDate, startAccountDate, endAccountDate, status, project,
            amount, accountNumber
        } = filter;
        const result = {
            date: startDate !== null && endDate !== null ? [dateIsNull(startDate), dateIsNull(endDate)] : undefined,
            plannedDate: startPlannedDate !== null && endPlannedDate !== null
                ? [dateIsNull(startPlannedDate), dateIsNull(endPlannedDate)] : undefined,
            accountDate:  startAccountDate !== null && endAccountDate !== null
                ? [dateIsNull(startAccountDate), dateIsNull(endAccountDate)] : undefined,
            status: status !== '' ? status : undefined,
            "project.id": project !== '' ? project : undefined,
            amount: amount !== '' ? amount : undefined,
            accountNumber: accountNumber !== '' ? accountNumber : undefined
        }
        return result;
    }
}

export function* getIncomesList(action) {
    const param = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({
            pagination: action.params.pagination,
            sort: isMultiSort(action.params.sort),
            filter: isFilter(action.params.filter),
            //fields: action.params.fields
        })
    };
    try{
        const response = yield call(fetch, `${process.env.REACT_APP_API_URL}/finance/income/list`, param);
        const data = yield call(response.json.bind(response));
        if (response.status === 200) {
            yield put(fetchIncomesResult(data));
        } else {
            yield put(fetchIncomesError(data));
        }
    } catch (error) {
        const data = {code: 500, userMessage: 'Internal Server Error'};
        yield put(fetchIncomesError(data));
    }
}

export function* watchLoadIncomesRequest() {

    yield takeEvery(FETCH_INCOMES_TYPES.REQUEST, getIncomesList);
}
