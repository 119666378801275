import { combineReducers } from 'redux';
import user from './user';
import entity from './entity';
import incomes from './incomes';
import settingUser from './settingUser';
import settingEntity from './settingEntity';
import settingIncome from './settingIncome';
import userList from './userList';
import competenciesList from './competenciesList';
import userInfo from './userInfo';
import referralUser from './referralUser';
import deposit from './deposit';
import payouts from './payouts';
import phone from './phone';
import transfer from './transfer';
import settingPayout from './settingPayout';
import specialization from './specialization';
import role from './role';
import specializationLevel from './specializationLevel';
import salary from './salary';
import settingTransfer from './settingTransfer';
import paysheet from './paysheet';
import departmentUser from './departmentUser';
import financialReport from './financialReport';
import income from './income';
import projects from './projects';
import accounts from './accounts';
import payout from './payout';
import entities from './entities';
import employees from './employees';
import clients from './clients';
import managers from './managers';
import leads from './leads';
import settingLead from './settingLead';
import settingDepartment from './settingDepartment';
import departments from './departments';
import department from './department';
import leadSource from './leadSource';
import settingLeadSource from './settingLeadSource';
import settingBill from './settingBill';
import redmineUsers from './redmineUsers';
import lead from './lead';
import auth from './auth';
import events from './events';
import event from './event';
import settingEvent from './settingEvent';
import profile from './profile';
import dashboardUsers from './dashboardUsers';
import dashboardCompany from './dashboardCompany';
import dashboardProfit from './dashboardProfit';
import dashboardUserProfit from './dashboardUserProfit';
import dashboardProjects from './dashboardProjects';
import dashboardProjectsManagers from './dashboardProjectsManagers';
import dashboardDepartments from './dashboardDepartments';
import dashboardPayrolls from './dashboardPayrolls';
import dashboardPayroll from './dashboardPayroll';
import billEntities from './billEntities';
import calendar from './calendar';
import certification from './certification/certification';
import masquerade from './masquerade/masquerade';
import welcomeletter from './welcomeletter/welcomeletter';
import placeholder from './welcomeletter/placeholder';

const rootReducer = combineReducers({
  user,
  entity,
  incomes,
  settingUser,
  settingEntity,
  settingIncome,
  userList,
  competenciesList,
  userInfo,
  referralUser,
  deposit,
  payouts,
  phone,
  transfer,
  settingPayout,
  specialization,
  role,
  specializationLevel,
  salary,
  settingTransfer,
  paysheet,
  departmentUser,
  financialReport,
  income,
  projects,
  accounts,
  payout,
  entities,
  employees,
  clients,
  managers,
  leads,
  settingLead,
  settingDepartment,
  departments,
  department,
  leadSource,
  settingLeadSource,
  settingBill,
  redmineUsers,
  lead,
  auth,
  events,
  event,
  settingEvent,
  calendar,
  profile,
  dashboardUsers,
  dashboardCompany,
  dashboardProfit,
  dashboardUserProfit,
  dashboardProjects,
  dashboardProjectsManagers,
  dashboardDepartments,
  dashboardPayrolls,
  dashboardPayroll,
  billEntities,
  certification,
  masquerade,
  welcomeletter,
  placeholder
});
export default rootReducer;
