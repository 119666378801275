import {call, put, takeEvery} from 'redux-saga/effects';
import {DELETE_USER_DEPARTMENT_TYPES} from 'constants/types';

import {deleteUserDepartmentSuccess, deleteUserDepartmentError} from 'actions/department';
import { authHeader } from '../../helpers/authHeader';

export function* deleteUserDepartment(action) {
    //console.log("DELETE DEPARTMENT",action.params);
    const param = {
        method: 'DELETE',
        headers: authHeader()
    };
    try{
        const response = yield call(fetch, `${process.env.REACT_APP_API_URL}/user/department/${action.params.id}`, param);
        const data = yield call(response.json.bind(response));
        if (response.status === 200) {
            yield put(deleteUserDepartmentSuccess({
                ...data,
                id: action.params.id,
            }));
        } else {
            yield put(deleteUserDepartmentError(data));
        }
    } catch (error) {
        const data = {code: 500, userMessage: 'Internal Server Error'};
        yield put(deleteUserDepartmentError(data));
    }
}

export function* watchDeleteUserDepartmentRequest() {
    yield takeEvery(DELETE_USER_DEPARTMENT_TYPES.REQUEST, deleteUserDepartment);
}
