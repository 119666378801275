import {
    FETCH_MASQUERADE_AUTH_TYPES,
    FETCH_MASQUERADE_ROLLBACK_TYPES,
    FETCH_MASQUERADE_STATUS_TYPES
} from '../../constants/masquerade/masquerade';

const initialState = {
    masquerade: null,
    loading: null,
    error: null
};

const masquerade = (state=initialState, action) => {
    switch (action.type) {
        case FETCH_MASQUERADE_AUTH_TYPES.REQUEST:
            return {
                ...state,
                loading: true
            };
        case FETCH_MASQUERADE_AUTH_TYPES.SUCCESS:
            return {
                ...state,
                loading: false,
                masquerade: action.data
            };
        case FETCH_MASQUERADE_AUTH_TYPES.ERROR:
            return  {
                ...state,
                loading: false,
                error: action.error
            };
        case FETCH_MASQUERADE_ROLLBACK_TYPES.REQUEST:
            return {
                ...state,
                loading: true
            };
        case FETCH_MASQUERADE_ROLLBACK_TYPES.SUCCESS:
            return {
                ...state,
                loading: false,
                masquerade: false
            };
        case FETCH_MASQUERADE_ROLLBACK_TYPES.ERROR:
            return  {
                ...state,
                loading: false,
                error: action.error
            };
        case FETCH_MASQUERADE_STATUS_TYPES.REQUEST:
            return {
                ...state,
                loading: true
            };
        case FETCH_MASQUERADE_STATUS_TYPES.SUCCESS:
            return {
                ...state,
                loading: false,
                masquerade: action.data
            };
        case FETCH_MASQUERADE_STATUS_TYPES.ERROR:
            return  {
                ...state,
                loading: false,
                error: action.error
            };
        default:
            return state;
    }
}
export default masquerade;
