import {
  FETCH_USER_TYPES,
  UPDATE_USER_TYPES,
  ADD_USER_TYPES,
  CLEAR_USER_ERROR,
  CLEAR_USER_PASSWORD,
  CLEAR_NEW_USER,
} from '../constants/types';

export function fetchUserRequest(params){
  return {
    type: FETCH_USER_TYPES.REQUEST,
    params
  }
}

export function fetchUserResult(data){
  return {
    type: FETCH_USER_TYPES.RESULT,
    data
  }
}

export function fetchUserError(error) {
  return{
    type: FETCH_USER_TYPES.ERROR,
    error
  }
}

export function updateUserRequest(params){
  return {
    type: UPDATE_USER_TYPES.REQUEST,
    params
  }
}

export function updateUserSuccess(data){
  return {
    type: UPDATE_USER_TYPES.SUCCESS,
    data
  }
}

export function updateUserError(error) {
  return {
    type: FETCH_USER_TYPES.ERROR,
    error
  }
}

export function addUserRequest(params){
  return {
    type: ADD_USER_TYPES.REQUEST,
    params
  }
}

export function addUserSuccess(data) {
  return {
    type: ADD_USER_TYPES.SUCCESS,
    data
  }
}

export function addUserError(error) {
  return {
    type: ADD_USER_TYPES.ERROR,
    error
  }
}

export function clearUserError() {
  console.log("action: clearUserError")
  return {
    type: CLEAR_USER_ERROR
  }
}

export function clearUserPassword() {
  return {
    type: CLEAR_USER_PASSWORD
  }
}

export function clearNewUserId() {
  return {
    type: CLEAR_NEW_USER,
  }
}
