import { FETCH_BILL_ENTITIES_TYPES } from '../constants/billEntities';

export function fetchBillEntitiesRequest(params){
    return {
        type: FETCH_BILL_ENTITIES_TYPES.REQUEST,
        params
    }
}

export function fetchBillEntitiesResult(data){
    return {
        type: FETCH_BILL_ENTITIES_TYPES.RESULT,
        data
    }
}

export function fetchBillEntitiesError(error) {
    return{
        type: FETCH_BILL_ENTITIES_TYPES.ERROR,
        error
    }
}