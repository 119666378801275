import { FETCH_WELCOME_LETTER_PLACEHOLDERS_TYPES } from '../../constants/welcomеletter/welcomeletter';

const initialState = {
    placeholders: null,
    loading: null,
    error: null
};

const placeholder = (state=initialState, action) => {
    switch (action.type) {
        case FETCH_WELCOME_LETTER_PLACEHOLDERS_TYPES.REQUEST:
            return {
                ...state,
                loading: true
            };
        case FETCH_WELCOME_LETTER_PLACEHOLDERS_TYPES.RESULT:
            return {
                ...state,
                loading: false,
                placeholders: action.data
            };
        case FETCH_WELCOME_LETTER_PLACEHOLDERS_TYPES.ERROR:
            return  {
                ...state,
                loading: false,
                error: action.error
            };
        default:
            return state;
    }
}
export default placeholder;