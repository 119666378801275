import {
    ADD_INCOME_TYPES,
    CLEAR_INCOME_ERROR,
    DELETE_INCOME_TYPES,
    FETCH_INCOME_TYPES,
    UPDATE_INCOME_TYPES
} from '../constants/types';

const initialState = {
    income: null,
    loading: null,
    error: null
};

const income = (state=initialState, action) => {
    switch (action.type) {
        case FETCH_INCOME_TYPES.REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case FETCH_INCOME_TYPES.RESULT:
            return {
                ...state,
                loading: false,
                error: null,
                income: action.data
            };
        case FETCH_INCOME_TYPES.ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case UPDATE_INCOME_TYPES.REQUEST:
            return {
                ...state,
                error: null,
                loading: false
            };
        case UPDATE_INCOME_TYPES.SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                income: action.data
            };
        case UPDATE_INCOME_TYPES.ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case ADD_INCOME_TYPES.REQUEST:
            return {
                ...state,
                loading: true
            };
        case ADD_INCOME_TYPES.SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case ADD_INCOME_TYPES.ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case CLEAR_INCOME_ERROR:
            return {
                ...state,
                error: null
            };
        case DELETE_INCOME_TYPES.REQUEST:
            return {
                ...state,
                loading: true
            };
        case DELETE_INCOME_TYPES.SUCCESS:
            return {
                ...state,
                loading: false
            };
        case DELETE_INCOME_TYPES.ERROR:
            return {
                ...state,
                error: action.error
            };
        default:
            return state;
    }
}
export default income;
