import {call, put, takeEvery} from 'redux-saga/effects';
import {FETCH_EVENTS_TYPES} from 'constants/types';

import {fetchEventsResult, fetchEventsError} from 'actions/event';
import { authHeader } from '../../helpers/authHeader';
import { isMultiSort } from 'helpers/sort';
import moment from 'moment';
moment.locale('ru');

export function* getEventList(action) {
    const param = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({
            pagination: action.params.pagination,
            sort: isMultiSort(action.params.sort),
            filter: action.params.filter,
            //fields: action.params.fields
        })
    };
    try{
        const response = yield call(fetch, `${process.env.REACT_APP_API_URL}user/list`, param);
        const data = yield call(response.json.bind(response));
        if (response.status === 200) {
            yield put(fetchEventsResult(data));
        } else {
            yield put(fetchEventsError(data));
        }
    } catch (error) {
        const data = {code: 500, userMessage: 'Internal Server Error'};
        yield put(fetchEventsError(data));
    }
}

export function* watchLoadEventsRequest() {

    yield takeEvery(FETCH_EVENTS_TYPES.REQUEST, getEventList);
}
