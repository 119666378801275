import {call, put, takeEvery} from 'redux-saga/effects';
import {ADD_SPECIALIZATION_TYPES} from 'constants/types';

import {addSpecializationSuccess, addSpecializationError} from 'actions/specialization';
import { authHeader } from '../../helpers/authHeader';

export function* addSpecialization(action) {
    const values = action.params;
    if (!values.status) {
        values.status = "default";
    }
    const param = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(values)
    };
    try{
        const response = yield call(fetch, `${process.env.REACT_APP_API_URL}/user/specialization`, param);
        const data = yield call(response.json.bind(response));
        if (response.status === 200) {
            yield put(addSpecializationSuccess(data));
        } else if (response.status === 400) {
            yield put(addSpecializationError(data));
        } else if (response.status === 401){
            yield put(addSpecializationError(data));
        } else if (response.status === 404){
            yield (addSpecializationError(data));
        }
    } catch (error) {
        const data = {code: 500, userMessage: 'Internal Server Error'};
        yield put(addSpecializationError(data));
    }
}

export function* watchAddSpecializationRequest() {
    yield takeEvery(ADD_SPECIALIZATION_TYPES.REQUEST, addSpecialization);
}