import {
    FETCH_PAYOUTS_TYPES,
    FETCH_PAYOUT_TYPES,
    ADD_PAYOUT_TYPES,
    UPDATE_PAYOUT_TYPES,
    CLEAR_PAYOUT_ERROR,
    DELETE_PAYOUT_TYPES
} from '../constants/types';

export function fetchPayoutsRequest(params){
    return {
        type: FETCH_PAYOUTS_TYPES.REQUEST,
        params
    }
}

export function fetchPayoutsResult(data){
    return {
        type: FETCH_PAYOUTS_TYPES.RESULT,
        data
    }
}

export function fetchPayoutsError(error) {
    return{
        type: FETCH_PAYOUTS_TYPES.ERROR,
        error
    }
}

export function fetchPayoutRequest(params){
    return {
        type: FETCH_PAYOUT_TYPES.REQUEST,
        params
    }
}

export function fetchPayoutResult(data){
    return {
        type: FETCH_PAYOUT_TYPES.RESULT,
        data
    }
}

export function fetchPayoutError(error) {
    return{
        type: FETCH_PAYOUT_TYPES.ERROR,
        error
    }
}

export function updatePayoutRequest(params){
    return {
        type: UPDATE_PAYOUT_TYPES.REQUEST,
        params
    }
}

export function updatePayoutSuccess(data){
    return {
        type: UPDATE_PAYOUT_TYPES.SUCCESS,
        data
    }
}

export function updatePayoutError(error) {
    return {
        type: UPDATE_PAYOUT_TYPES.ERROR,
        error
    }
}

export function addPayoutRequest(params){
    return {
        type: ADD_PAYOUT_TYPES.REQUEST,
        params
    }
}

export function addPayoutSuccess(data) {
    return {
        type: ADD_PAYOUT_TYPES.SUCCESS,
        data
    }
}

export function addPayoutError(error) {
    return {
        type: ADD_PAYOUT_TYPES.ERROR,
        error
    }
}

export function clearPayoutError() {
    return {
        type: CLEAR_PAYOUT_ERROR
    }
}

export function deletePayoutRequest(params){
    return {
        type: DELETE_PAYOUT_TYPES.REQUEST,
        params
    }
}

export function deletePayoutSuccess(data) {
    return {
        type: DELETE_PAYOUT_TYPES.SUCCESS,
        data
    }
}

export function deletePayoutError(error) {
    return {
        type: DELETE_PAYOUT_TYPES.ERROR,
        error
    }
}