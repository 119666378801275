import {
    ADD_USER_DEPARTMENT_TYPES,
    UPDATE_USER_DEPARTMENT_TYPES,
    DELETE_USER_DEPARTMENT_TYPES
} from '../constants/types';

const initialState = {
    department: null,
    error: null,
    loading: null
};

const departmentUser = (state=initialState, action) => {
     switch (action.type) {
         case ADD_USER_DEPARTMENT_TYPES.REQUEST:
             return {
                 ...state,
                 loading: true
             };
         case ADD_USER_DEPARTMENT_TYPES.SUCCESS:
             return {
                 ...state,
                 loading: false
             };
         case ADD_USER_DEPARTMENT_TYPES.ERROR:
             return {
                 ...state,
                 error: action.error
             };
         case UPDATE_USER_DEPARTMENT_TYPES.REQUEST:
             return {
                 ...state,
                 loading: true
             };
         case UPDATE_USER_DEPARTMENT_TYPES.SUCCESS:
             return {
                 ...state,
                 loading: false
             };
         case UPDATE_USER_DEPARTMENT_TYPES.ERROR:
             return {
                 ...state,
                 error: action.error
             };
         case DELETE_USER_DEPARTMENT_TYPES.REQUEST:
             return {
                 ...state,
                 loading: true
             };
         case DELETE_USER_DEPARTMENT_TYPES.SUCCESS:
             return {
                 ...state,
                 loading: false
             };
         case DELETE_USER_DEPARTMENT_TYPES.ERROR:
             return {
                 ...state,
                 error: action.error
             };
        default:
            return state;
    }
}
export default departmentUser;