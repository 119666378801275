import {activeColumns, hiddenColumns} from './DepartmentTableData';

const initialValue = {
    activeColumns: activeColumns,
    hiddenColumns: hiddenColumns
}

const settingDepartment = (state=initialValue, action) => {
    switch (action.type) {
        case 'CHANGE_COLUMNS_DEPARTMENT':
            return { ...state, activeColumns: action.activeColumns, hiddenColumns: action.hiddenColumns};
        case 'CHANGE_COLUMN_ACTIVE_DEPARTMENT':
            return { ...state, activeColumns: action.activeColumns};
        case 'CHANGE_COLUMN_HIDDEN_DEPARTMENT':
            return { ...state, hiddenColumns: action.hiddenColumns};
        default:
            return state;
    }
}
export default settingDepartment;
