import {call, put, takeEvery} from 'redux-saga/effects';
import {ADD_INCOME_TYPES} from 'constants/types';

import {addIncomeSuccess, addIncomeError} from 'actions/income';
import { authHeader } from '../../helpers/authHeader';

export function* addIncome(action) {
    console.log("NEW", action)
    const param = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(action.params.income)
    };
    try{
        const response = yield call(fetch, `${process.env.REACT_APP_API_URL}/finance/income`, param);
        const data = yield call(response.json.bind(response));
        if (response.status === 200) {
            window.history.back();
            yield put(addIncomeSuccess(data));
        } else {
            yield put(addIncomeError(data));
        }
    } catch (error) {
        const data = {code: 500, userMessage: 'Internal Server Error'};
        yield put(addIncomeError(data));
    }
}

export function* watchAddIncomeRequest() {

    yield takeEvery(ADD_INCOME_TYPES.REQUEST, addIncome);
}
