import { FETCH_EMPLOYEES_TYPES } from '../constants/types'

const initialState = {
    employee: null,
    loading: null,
    error: null
};

const employees = (state=initialState, action) => {
    switch (action.type) {
        case FETCH_EMPLOYEES_TYPES.REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case FETCH_EMPLOYEES_TYPES.RESULT:
            return {
                ...state,
                employee: action.data,
                loading: false,
                error: null,
            };
        case FETCH_EMPLOYEES_TYPES.ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        default:
            return state;
    }
};
export default employees;
