import { call, put, takeEvery } from 'redux-saga/effects';
import { FETCH_PROFILE_TYPES } from 'constants/types';
import { fetchProfileResult, fetchProfileError } from 'actions/profile';
import { fetchMasqueradeStatusRequest } from 'actions/masquerade/masquerade';
import { authHeader } from '../../helpers/authHeader';

export function* getProfile(action) {
    const param = {
        method: 'GET',
        headers: authHeader(),
    };
    try {
        const response = yield call(fetch, `${process.env.REACT_APP_API_URL}/user/me`, param);
        //const response = yield call(fetch, `${process.env.REACT_APP_API_URL}user/222`, param);
        //const response = yield call(fetch, `${process.env.REACT_APP_API_URL}user/352`, param);
        // const response = yield call(fetch, `${process.env.REACT_APP_API_URL}user/11`, param);
        // const response = yield call(fetch, `${process.env.REACT_APP_API_URL}user/348`, param);
        //const response = yield call(fetch, `${process.env.REACT_APP_API_URL}user/52`, param);
        //const response = yield call(fetch, `${process.env.REACT_APP_API_URL}user/212`, param);
        //const response = yield call(fetch, `${process.env.REACT_APP_API_URL}user/203`, param);
        //const response = yield call(fetch, `${process.env.REACT_APP_API_URL}user/7`, param);
        //const response = yield call(fetch, `${process.env.REACT_APP_API_URL}user/363`, param);
        //const response = yield call(fetch, `${process.env.REACT_APP_API_URL}user/45`, param);
        //const response = yield call(fetch, `${process.env.REACT_APP_API_URL}user/71`, param);    // was current before me
        const data = yield call(response.json.bind(response));
        if (response.status === 200) {
            yield put(fetchMasqueradeStatusRequest())
            yield put(fetchProfileResult(data));
        } else {
            yield put(fetchProfileError(data));
        }
    } catch (error) {
        const data = { code: 500, userMessage: 'Internal Server Error' };
        yield put(fetchProfileError(data));
    }
}

export function* watchLoadProfileRequest() {
    yield takeEvery(FETCH_PROFILE_TYPES.REQUEST, getProfile);
}
