import { FETCH_MANAGERS_TYPES } from '../constants/types';

const initialState = {
    loading: null,
    error: null,
    manager: null
};

const managers = (state=initialState, action) => {
    switch (action.type) {
        case FETCH_MANAGERS_TYPES.REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case FETCH_MANAGERS_TYPES.RESULT:
            return {
                ...state,
                manager: action.data,
                loading: false,
                error: null,
            };
        case FETCH_MANAGERS_TYPES.ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        default:
            return state;
    }
};
export default managers;