import React from 'react'
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

//const user = JSON.parse(window.localStorage.getItem('user'));

const PrivateRoute = ({component:Component, ...rest}) => {
    const store = useSelector(store => store);
    const user = store.auth.user;
    return(
        <Route
            {...rest}
            render={props =>
                user ? (
                    <Component {...props} />
                ): (
                    <Redirect
                        to='/login'
                    />
                )
            }
        />
    )
};

export default PrivateRoute;