export const activeColumns = [
  {
    id: 1,
    dataField: "client",
    title: "Клиент"
  },
  {
    id: 2,
    dataField: "project",
    title: "Проект"
  },
  {
    id: 3,
    dataField: "date",
    title: "Дата оплаты"
  },
  {
    id: 4,
    dataField: "amount",
    title: "Сумма"
  },
  {
    id: 5,
    dataField: "accountNumber",
    title: "Номер счета"
  },
  {
    id: 6,
    dataField: "account",
    title: "Юр.лицо"
  },
  {
    id: 7,
    dataField: "action",
    title: ""
  },
];
export const hiddenColumns = [
  {
    id: 8,
    dataField: "id",
    title: "ID"
  },
  {
    id: 9,
    dataField: "manager",
    title: "Менеджер"
  },
  {
    id: 10,
    dataField: "status",
    title: "Статус"
  },
  {
    id: 11,
    dataField: "nomination",
    title: "Наименование"
  },
  {
    id: 12,
    dataField: "plannedDate",
    title: "Запланированная дата"
  },
  {
    id: 13,
    dataField: "accountDate",
    title: "Дата выставления счета"
  },
  {
    id: 14,
    dataField: "comment",
    title: "Комментарий"
  }
];
