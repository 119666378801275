import {ADD_ENTITY_TYPES, CLEAR_ENTITY_ERROR, FETCH_ENTITY_TYPES, UPDATE_ENTITY_TYPES} from '../constants/types';

const initialState = {
  entity: null,
  loading: null,
  error: null
};

const entity = (state=initialState, action) => {
  switch (action.type) {
    case FETCH_ENTITY_TYPES.REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_ENTITY_TYPES.RESULT:
      return {
        ...state,
        loading: false,
        error: null,
        entity: action.data
      };
    case FETCH_ENTITY_TYPES.ERROR:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case UPDATE_ENTITY_TYPES.REQUEST:
      return {
        ...state,
        error: null,
        loading: false
      };
    case UPDATE_ENTITY_TYPES.SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        entity: action.data
      };
    case UPDATE_ENTITY_TYPES.ERROR:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case ADD_ENTITY_TYPES.REQUEST:
      return {
        ...state,
        loading: true
      };
    case ADD_ENTITY_TYPES.SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ADD_ENTITY_TYPES.ERROR:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case CLEAR_ENTITY_ERROR:
      return {
        ...state,
        error: null
      };
    default:
      return state;
  }
};
export default entity;
