import { call, put, takeEvery } from 'redux-saga/effects';
import { FETCH_MASQUERADE_STATUS_TYPES } from 'constants/masquerade/masquerade';
import { fetchMasqueradeStatusSuccess, fetchMasqueradeStatusError } from 'actions/masquerade/masquerade';
import { authHeader } from 'helpers/authHeader';

export function* masqueradeStatus(action) {
    const param = {
        method: 'GET',
        headers: authHeader()
    };
    try{
        const response = yield call(fetch, `${process.env.REACT_APP_API_URL}/user/masquerade/status`, param);
        const data = yield call(response.json.bind(response));
        if(response.status === 200) {
            const flag = true;
            const masquerade = JSON.stringify(flag);
            localStorage.setItem("masquerade", masquerade);
            console.log("data", data)
            yield put(fetchMasqueradeStatusSuccess(flag));
        } else {
            console.error("Ошибка авторизации: ", data);
            if(data.code === 403){
                const flag = false;
                const masquerade = JSON.stringify(flag);
                localStorage.setItem("masquerade", masquerade);
                yield put(fetchMasqueradeStatusSuccess(flag));
            }
            yield put(fetchMasqueradeStatusError(data));
        }
    } catch (error) {
        const data = {code: 500, userMessage: 'Internal Server Error'};
        yield put(fetchMasqueradeStatusError(data));
    }
}

export function* watchLoadMasqueradeStatusRequest() {
    yield takeEvery(FETCH_MASQUERADE_STATUS_TYPES.REQUEST, masqueradeStatus);
}
