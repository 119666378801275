import {call, put, takeEvery} from 'redux-saga/effects';
import {DELETE_SALARY_TYPES} from 'constants/types';

import {deleteSalarySuccess, deleteSalaryError} from 'actions/salary';
import { authHeader } from '../../helpers/authHeader';

export function* deleteSalary(action) {
    const param = {
        method: 'DELETE',
        headers: authHeader()
    };
    try{
        const response = yield call(fetch, `${process.env.REACT_APP_API_URL}/user/work-payment/${action.params.id}`, param);
        const data = yield call(response.json.bind(response));
        if (response.status === 200) {
            yield put(deleteSalarySuccess({ ...data, id: action.params.id }));
        } else if (response.status === 400) {
            yield put(deleteSalaryError(data));
        } else if (response.status === 401){
            yield put(deleteSalaryError(data));
        } else if (response.status === 404){
            yield (deleteSalaryError(data));
        }
    } catch (error) {
        const data = {code: 500, userMessage: 'Internal Server Error'};
        yield put(deleteSalaryError(data));
    }
}

export function* watchDeleteSalaryRequest() {
    yield takeEvery(DELETE_SALARY_TYPES.REQUEST, deleteSalary);
}

