import {call, put, takeEvery} from 'redux-saga/effects';
import {FETCH_CLIENTS_TYPES} from 'constants/types';

import {fetchClientsResult, fetchClientsError} from 'actions/client';
import { authHeader } from '../../helpers/authHeader';
import moment from 'moment';
moment.locale('ru');

const arg = (page, params) => {
    if(params !== undefined){
        return {
            pagination: {page: page, limit: 100},
            filter: {name: params.filter.name}
        }
    } else {
        return {
            pagination: {page: page, limit: 10}
        }
    }
}

export function* fetchClients(params) {
    const param = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({
            pagination: params.pagination,
            //sort: isMultiSort(action.params.sort),
            filter: params.filter,
            fields: [
                "id", "name"
            ]
        })
    };
    try{
        const response = yield call(fetch, `${process.env.REACT_APP_API_URL}/business/client/list`, param);
        const data = yield call(response.json.bind(response));
        if (response.status === 200) {
            return data
        } else {
            yield put(fetchClientsError(data));
        }
    } catch (error) {
        const data = {code: 500, userMessage: 'Internal Server Error'};
        yield put(fetchClientsError(data));
    }
}

export function* getClientsList(action) {
    try{
        const data = yield call(fetchClients, arg(1, action.params));
        let {
            result: client,
            pagination: {
                count: countPage
            }
        } = data;
        if (countPage > client.length) {
            /*
            let pages = Array.from(Array(pageCountProject).keys());
            pages.splice(0, 2);
            const [ ...results] = yield all(pages.map(page => call(fetchClients, arg(page, action.params))));
            client = results.reduce((res, v) => [ ...res, ...v.result ], client);
             */
            yield put(fetchClientsResult(client));
        } else {
            yield put(fetchClientsResult(client));
        }
    } catch (error) {
        const data = {code: 500, userMessage: 'Internal Server Error'};
        yield put(fetchClientsError(data));
    }
}

export function* watchLoadClientsRequest() {
    yield takeEvery(FETCH_CLIENTS_TYPES.REQUEST, getClientsList);
}
