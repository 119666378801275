import {call, put, takeEvery} from 'redux-saga/effects';
import { ADD_USER_CERTIFICATION_TYPES } from 'constants/certifications/certificationsUser';

import { addUserCertificationsSuccess, addUserCertificationsError } from 'actions/certification';
import { authHeader } from 'helpers/authHeader';

export function* addCertification(action) {
    const param = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(action.params)
    };
    try{
        const response = yield call(fetch, `${process.env.REACT_APP_API_URL}/user/certification`, param);
        const data = yield call(response.json.bind(response));
        if (response.status === 200) {
            yield put(addUserCertificationsSuccess(data));
        } else {
            yield put(addUserCertificationsError(data));
        }
    } catch (error) {
        const data = {code: 500, userMessage: 'Internal Server Error'};
        yield put(addUserCertificationsError(data));
    }
}

export function* watchAddCertificationRequest() {
    yield takeEvery(ADD_USER_CERTIFICATION_TYPES.REQUEST, addCertification);
}

